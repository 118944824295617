import { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ListGroup,
  Modal,
  Form,
} from "react-bootstrap";
import "./styles.css";

const AnalyticsModal = ({
  questions,
  analytic,
  analytics,
  setAnalytics,
  show,
  onHide,
  newAnalytics,
}) => {
  const [name, setName] = useState("");
  const [available, setAvailable] = useState([]);
  const [selected, setSelected] = useState([]);

  const [maxVal, setMaxVal] = useState(0);
  const [minVal, setMinVal] = useState(0); // Added
  const [analyticType, setAnalyticType] = useState("mean"); // Added
  useEffect(() => {
    // console.log(" analyticType in mount:"+analyticType)
    // console.log(" analytic in mount::"+JSON.stringify(analytic))
    console.log(" analytics in mount::"+JSON.stringify(analytics))
  },[])

  useEffect(() => {
    const avai = [];
    const select = [];

    if (questions && analytic) {
      questions.forEach((question) =>
        question.elements.forEach((e) => {
          let found = false;
          for (let i of analytic.analytic_variables) {
            let name = i;
            let reversed = false;
            if (i[0] === "-") {
              name = i.slice(1);
              reversed = true;
            }
            if (name === e.name) {
              found = true;
              select.push({ ...e, reversed: reversed });
            }
          }
          if (!found) {
            avai.push({ ...e });
          }
        })
      );
    }
    setAvailable(avai);
    setSelected(select);
  }, [show, questions, analytic]);

  useEffect(() => {
    // console.log("analytic changed:")

    if (analytic) {
      setName(analytic.analytic_name);
      setMaxVal(analytic.analytic_total);
      setAnalyticType(analytic.analytic_type||"mean"); 
      setMinVal(analytic.analytic_min||0); 
    }
  }, [analytic]);

  const add = (question) => {
    question.reversed = false;
    setSelected([...selected, question]);
    setAvailable(available.filter((a) => a.name !== question.name));
  };

  const remove = (question) => {
    question.reversed = null;
    setAvailable([...available, question]);
    setSelected(selected.filter((a) => a.name !== question.name));
  };

  const finish = () => {
    const analytic_variables = [];
    for (let select of selected) {
      if (select.reversed) {
        analytic_variables.push("-" + select.name);
        continue;
      }
      analytic_variables.push(select.name);
    }
    // console.log(" newAnalytics in finish()ß:"+JSON.stringify(newAnalytics))
    console.log("analyticType in finish():", analyticType);
    // console.log(" analytic in finish()::"+JSON.stringify(analytic))
    // console.log(" analytics in finish()::"+JSON.stringify(analytics))

    if (newAnalytics) {
      const obj = {};
      obj["analytic_variables"] = analytic_variables;
      obj["analytic_name"] = name;
      obj["analytic_total"] = maxVal;
      obj["analytic_type"] = analyticType;
      obj["analytic_min"] = minVal;
      setAnalytics([...analytics, obj]);
    } else {
      for (let i = 0; i < analytics.length; i++) {
        if (analytics[i].analytic_name === analytic.analytic_name) {
          analytics[i].analytic_name = name;
          analytics[i].analytic_variables = analytic_variables;
          analytics[i].analytic_min = minVal
          analytics[i].analytic_max = maxVal
          analytics[i].analytic_type = analyticType
          setAnalytics(analytics);
        }
      }
    }
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Row>
          <Col md={6} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label>Name of the Analytic Variable</Form.Label>
                <Form.Control
                  value={name}
                  placeholder="Enter a name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col md={6} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label style={{ fontSize: "0.7rem" }}>
                  Max Value of Options, Used for calculating reversed value
                </Form.Label>
                <Form.Control
                  value={maxVal}
                  type="number"
                  placeholder="Enter a Number"
                  onChange={(e) => setMaxVal(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
        <Col md={6} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label>Min Value of Options</Form.Label>
                <Form.Control
                  value={minVal}
                  type="number"
                  placeholder="Enter a Number"
                  onChange={(e) => setMinVal(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>

        <Col md={6} className="text-left">
          <Form>
            <Form.Group className="my-2">
              <Form.Label>Analytic Type</Form.Label>
              <Form.Control
                as="select"
                value={analyticType}
                // onChange={(e) => {
                //   console.log("e.target.value", e.target.value);
                //   return setAnalyticType(e.target.value)
                // }}
              >
                <option value="mean">Mean</option>
                {/* <option value="sum">Sum</option> */}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        </Row>

        <Row className="my-2">
          <Col>Available Questions</Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left">
            <ListGroup className="list">
              {available &&
                available.map((question) => (
                  <ListGroup.Item key={question.name}>
                    <Row>
                      <Col xs={2}>{question.name}</Col>
                      <Col className="d-none d-lg-block text-truncate">
                        {question.title}
                      </Col>
                      <Col
                        xs={10}
                        lg={2}
                        className="d-flex justify-content-end"
                      >
                        <Button
                          size="sm"
                          variant="outline-success"
                          onClick={() => add(question)}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>Selected Questions</Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left">
            <ListGroup className="list">
              {selected.map((question, idx) => (
                <ListGroup.Item key={idx}>
                  <Row>
                    <Col xs={2}>{question.name}</Col>
                    <Col className="d-none d-lg-block text-truncate">
                      {question.title}
                    </Col>
                    <Col xs={2}>
                      <Form.Check
                        inline
                        label="reversed"
                        checked={question.reversed}
                        type="checkbox"
                        onChange={(e) => {
                          for (let i = 0; i < selected.length; i++) {
                            if (selected[i].name === question.name) {
                              const tmp = [...selected];
                              tmp[i].reversed = !selected[i].reversed;
                              setSelected(tmp);
                            }
                          }
                        }}
                      />
                    </Col>
                    <Col xs={10} lg={2} className="d-flex justify-content-end">
                      <Button
                        size="sm"
                        variant="outline-warning"
                        onClick={() => remove(question)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" className="mr-auto" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="outline-success" onClick={finish}>
          {newAnalytics ? "Add Analytic" : "Save Analytics"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AnalyticsModal;
