import { useState } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import axiosInstance from "../../axiosInterceptor";
import checkEmail from "../../services/util/checkEmail";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import CustomAlert from "../ConfirmAlert/CustomAlert";
import {
  IntlProvider,
  FormattedMessage,
  useIntl,
  defineMessage,
} from "react-intl";

const language = navigator.language.split(/[-_]/)[0];

const ForgotPassword = ({ hide }) => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const intl = useIntl();
  const messages = defineMessage({
    cardTitle: {
      id: "ForgotPassword.cardTitle",
      defaultMessage: "Email Sent",
    },
    cardContent: {
      id: "ForgotPassword.cardTitle",
      defaultMessage: "Please check your email to reset your password.",
    },
  });

  const [emailErrorMessage, setEmailErrorMessage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    resetPassword();
  };

  const checkAndSetEmail = (e) => {
    const email = e.target.value;
    if (!checkEmail(email)) {
      setEmailErrorMessage("Invalid Email Format");
      return;
    }
    setEmailErrorMessage(null);
    setEmail(email);
  };

  const resetPassword = async () => {
    setEmailErrorMessage(null);

    setLoading(true);
    try {
      await axiosInstance().post("auth/recover", { email });
      hide();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <IntlProvider language={language}>
              <CustomAlert
                title={intl.formatMessage(messages.cardTitle)}
                onClose={onClose}
                text={intl.formatMessage(messages.cardContent)}
              />
            </IntlProvider>
          );
        },
      });
    } catch (err) {
      console.log(err.response);
      setEmailErrorMessage(err.response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Row>
        <Col>
          <FormattedMessage
            id="ForgotPassword.title"
            defaultMessage="Reset Password"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-left">
          <Form>
            <Form.Group>
              <Form.Label>
                <FormattedMessage
                  id="ForgotPassword.email"
                  defaultMessage="Email address"
                />
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                isInvalid={emailErrorMessage}
                onChange={checkAndSetEmail}
              />
              <Form.Control.Feedback type="invalid">
                {emailErrorMessage}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center my-2 ">
          <ReCAPTCHA
            sitekey="6LcNyPAbAAAAAMvXbdsIOkMvxGATbhG5BvAYLsSj"
            onChange={(e) => {
              setIsVerified(true);
            }}
            onExpired={() => setIsVerified(false)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={handleSubmit}
            className="w-100 action-button"
            disabled={emailErrorMessage || !email || !isVerified}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-3"
              />
            )}
            <FormattedMessage
              id="ForgotPassword.send"
              defaultMessage="Send Email"
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword;
