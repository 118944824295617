import { Container, Row, Col } from "react-bootstrap";
import DOMPurify from "dompurify";

const TitleAndDebrief = ({ survey }) => {
  const getDebrief = (debrief) => {
    const elements = [];
    if (debrief && Object.keys(debrief).length !== 0) {
      elements.push(
        <div
          style={{ textAlign: "left" }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(debrief),
          }}
        />
      );
    } else {
      elements.push(
        <div>
          <Container className="mt-5">
            <Row>
              <Col>
                <p className="h3">
                  This Survey does not have a report available yet
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return elements;
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3>Survey: {survey.name}</h3>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <h5>{survey.description}</h5>
        </Col>
      </Row>
      <Row>
        <Col>{getDebrief(survey.debrief)}</Col>
      </Row>
    </Container>
  );
};

export default TitleAndDebrief;
