import { useState } from 'react';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import axiosInstance from '../../axiosInterceptor';
import checkEmail from '../../services/util/checkEmail';
import passwordValidtor from '../../services/util/passwordValidate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomAlert from '../ConfirmAlert/CustomAlert';
import {
    IntlProvider,
    FormattedMessage,
    useIntl,
    defineMessage
} from 'react-intl';

import './style.css';

const language = navigator.language.split(/[-_]/)[0];

const Register = ({ hide,isDataReqUser=false }) => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(null);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
    const [confirmpasswordErrorMessage, setConfirmPasswordErrorMessage] =
        useState(null);

    const intl = useIntl();
    const messages = defineMessage({
        invalidEmailFormat: {
            id: 'Register.invalidFormat',
            defaultMessage: 'Invalid email format'
        },
        pwNotSame: {
            id: 'Register.pwNotSame',
            defaultMessage: 'Password is not the same'
        },
        checkEmail: {
            id: 'Register.checkEmail',
            defaultMessage: 'Please check your email to verify your email.'
        },
        emailSent: {
            id: 'Register.emailSent',
            defaultMessage: 'Email Sent'
        },
        emailPrompt: {
            id: 'Register.emailPrompt',
            defaultMessage: 'Enter email'
        },
        confirmPrompt: {
            id: 'Register.confirmPrompt',
            defaultMessage: 'Please Confirm Your Password'
        }
    });

    const handleSubmit = (event) => {
        setPasswordErrorMessage(null);
        setConfirmPasswordErrorMessage(null);
        setEmailErrorMessage(null);
        event.preventDefault();
        register();
    };

    const checkAndSetEmail = (e) => {
        const email = e.target.value;
        setPasswordErrorMessage(null);
        setConfirmPasswordErrorMessage(null);

        setEmail(email);

        if (!checkEmail(email)) {
            setEmailErrorMessage(
                intl.formatMessage(messages.invalidEmailFormat)
            );
            return;
        }
        setEmailErrorMessage(null);
    };

    const checkAndSetPassword = (e) => {
        const password = e.target.value;
        const validateRes = passwordValidtor(password);
        setPassword(password);
        setEmailErrorMessage(null);
        setConfirmPasswordErrorMessage(null);
        if (validateRes.length > 0) {
            setPasswordErrorMessage(validateRes);
            return;
        }
        setPasswordErrorMessage(null);
    };

    const checkAndSetConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
        setPasswordErrorMessage(null);
        setEmailErrorMessage(null);
        if (password !== confirmPassword) {
            setConfirmPasswordErrorMessage(
                intl.formatMessage(messages.pwNotSame)
            );
            return;
        }
        setConfirmPasswordErrorMessage(null);
    };

    const register = async () => {
        setLoading(true);
        let user = {
            email,
            password,
        };
        if(isDataReqUser){
            user.isDataReqUser = true
        }
        try {
            await axiosInstance().put('/auth/signup', user);
            if(!isDataReqUser){
                hide()
            }
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <IntlProvider language={language}>
                            <CustomAlert
                                title={intl.formatMessage(messages.emailSent)}
                                onClose={onClose}
                                text={intl.formatMessage(messages.checkEmail)}
                            />
                        </IntlProvider>
                    );
                }
            });
        } catch (err) {
            console.log("register error: "+err)
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setEmailErrorMessage(err.response?.data.message);
        }
        setLoading(false);
    };

    return (
        <>
            <Row>
                <Col style={{ fontSize: '1.5rem' }}>
                    <FormattedMessage
                        id="Register.title"
                        defaultMessage={isDataReqUser? "Register for Data Request" : "Register"}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-left">
                    <Form>
                        <Form.Group className="my-2">
                            <Form.Label>
                                <FormattedMessage
                                    id="Register.email"
                                    defaultMessage="Email address"
                                />
                            </Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                placeholder={intl.formatMessage(
                                    messages.emailPrompt
                                )}
                                isInvalid={emailErrorMessage}
                                onChange={checkAndSetEmail}
                            />
                            <Form.Control.Feedback type="invalid">
                                {emailErrorMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label>
                                <FormattedMessage
                                    id="Register.pw"
                                    defaultMessage="Password"
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                value={password}
                                type="password"
                                placeholder="Password"
                                isInvalid={passwordErrorMessage}
                                onChange={checkAndSetPassword}
                            />
                            {passwordErrorMessage &&
                                passwordErrorMessage.length &&
                                passwordErrorMessage.map((m) => (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        key={m}
                                    >
                                        {m}
                                    </Form.Control.Feedback>
                                ))}
                            <Form.Text className="text-muted">
                                <FormattedMessage
                                    id="Register.requirement"
                                    defaultMessage="Your password must have 8 - 20 characters, at least 1 uppercase
                character and 1 digit"
                                />
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label>
                                <FormattedMessage
                                    id="Register.confirm"
                                    defaultMessage="Confirm Password"
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                value={confirmPassword}
                                type="password"
                                placeholder={intl.formatMessage(
                                    messages.confirmPrompt
                                )}
                                isInvalid={confirmpasswordErrorMessage}
                                onChange={checkAndSetConfirmPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                                {confirmpasswordErrorMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button
                            onClick={handleSubmit}
                            className="w-100 action-button my-2"
                            disabled={
                                emailErrorMessage ||
                                passwordErrorMessage ||
                                confirmpasswordErrorMessage ||
                                !password ||
                                !email ||
                                !confirmPassword
                            }
                        >
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-3"
                                />
                            )}

                            <FormattedMessage
                                id="Register.action"
                                defaultMessage="Register"
                            />
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default Register;
