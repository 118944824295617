import {
  Container,
  Col,
  Row,
  Spinner,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { useState } from "react";
import axiosInstance from "../../axiosInterceptor";
import passwordValidtor from "../../services/util/passwordValidate";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ResetPasswordPage = () => {
  let history = useHistory();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [confirmpasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState(null);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let token = query.get("token");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!token) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Card style={{ width: "25rem" }} className="confirmCard">
              <Card.Body>
                <Card.Title>
                  <FormattedMessage
                    id="resetCard.title"
                    defaultMessage="Error"
                  />
                </Card.Title>
                <Card.Text>
                  <FormattedMessage
                    id="resetCard.content"
                    defaultMessage="Error resetting password. Please try again."
                  />
                </Card.Text>
                <Container fluid>
                  <Row>
                    <Col xs={6} className="text-left">
                      <Button
                        variant="outline-success"
                        className="w-100"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          );
        },
      });
      return;
    }
    reset();
  };

  const reset = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance().post("auth/reset/" + token, {
        password,
      });
      console.log(res.response);
      history.push({
        pathname: "/",
      });
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Card style={{ width: "25rem" }} className="confirmCard">
              <Card.Body>
                <Card.Title>Success</Card.Title>
                <Card.Text>
                  Password successfully reset. Please login to continue.
                </Card.Text>
                <Container fluid>
                  <Row>
                    <Col xs={6} className="text-left">
                      <Button
                        variant="outline-success"
                        className="w-100"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          );
        },
      });
    } catch (err) {
      console.log("here", err);
      setPassword("");
      setConfirmPassword("");
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Card style={{ width: "25rem" }} className="confirmCard">
              <Card.Body>
                <Card.Title>Error</Card.Title>
                <Card.Text>
                  Error resetting password. Please try again.
                </Card.Text>
                <Container fluid>
                  <Row>
                    <Col xs={6} className="text-left">
                      <Button
                        variant="outline-success"
                        className="w-100"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        OK
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          );
        },
      });
    }
    setLoading(false);
  };

  const checkAndSetPassword = (e) => {
    const password = e.target.value;
    const validateRes = passwordValidtor(password);
    setPassword(password);
    if (password === confirmPassword) {
      setConfirmPasswordErrorMessage("");
      return;
    }
    if (validateRes.length > 0) {
      setPasswordErrorMessage(validateRes);
      return;
    }
    setPasswordErrorMessage(null);
  };

  const checkAndSetConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);

    if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage("Password is not the same");
      return;
    }
    setConfirmPasswordErrorMessage(null);
  };
  return (
    <Container className="w-50">
      <Row>
        <Col style={{ fontSize: "1.5rem" }}>Reset Password</Col>
      </Row>
      <Row>
        <Col xs={12} className="text-left">
          <Form>
            <Form.Group className="my-2">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                value={password}
                type="password"
                placeholder="Password"
                isInvalid={passwordErrorMessage}
                onChange={checkAndSetPassword}
              />
              {passwordErrorMessage &&
                passwordErrorMessage.length &&
                passwordErrorMessage.map((m) => (
                  <Form.Control.Feedback type="invalid" key={m}>
                    {m}
                  </Form.Control.Feedback>
                ))}
              <Form.Text className="text-muted">
                Your password must have 8 - 20 characters, at least 1 uppercase
                character and 1 digit
              </Form.Text>
            </Form.Group>
            <Form.Group className="my-2">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                required
                value={confirmPassword}
                type="password"
                placeholder="Please Confirm Your Password"
                isInvalid={confirmpasswordErrorMessage}
                onChange={checkAndSetConfirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {confirmpasswordErrorMessage}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              onClick={handleSubmit}
              className="w-100 action-button my-2"
              disabled={
                passwordErrorMessage ||
                confirmpasswordErrorMessage ||
                !password ||
                !confirmPassword
              }
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-3"
                />
              )}
              Reset Password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordPage;
