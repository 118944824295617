import { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import axiosInstance from "../../../axiosInterceptor";
import LineChart from "./LineChart";
import PieChart from "./PieChart";

import styles from "../Admin.module.css";

const SurveyChart = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [disabled, setDisabled] = useState(false);
  const [externalLabel, setExternalLabel] = useState([]);
  const [allSurveyIds, setAllSurveyIds] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [externalData, setExternalData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const resp = await axiosInstance().get("admin/all-surveys-id");
      setAllSurveyIds(resp.data.surveyIds);
    };
    fetchData();
    getSurveyData("all");
  }, []);
  const randomRGBA = () => {
    var o = Math.round,
      r = Math.random,
      s = 255;

    const rgb =
      "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ",";

    return [rgb + "0.2)", rgb + "1)"];
  };

  const getSurveyData = async (surveyId) => {
    try {
      const resp = await axiosInstance().get(
        "admin/survey-usage?days=90&surveyId=" + surveyId
      );
      const newData = { ...data };
      const results = resp.data.results;

      const dataset = {};
      dataset.fill = true;
      const random = randomRGBA();
      dataset.backgroundColor = random[0];
      dataset.borderColor = random[1];
      setExternalLabel(results.map((r) => r.date));
      newData.labels = results
        .map((r) => r.date)
        .slice(results.length - usageDays);
      dataset.label = surveyId;
      const tmp = { ...externalData };
      tmp[surveyId] = results.map((r) => r.answers);
      setExternalData(tmp);

      dataset.data = results
        .map((r) => r.answers)
        .slice(results.length - usageDays);
      if (!newData.datasets) {
        newData.datasets = [];
      }
      newData.datasets.push(dataset);
      setData(newData);
    } catch (err) {}
  };

  const [usageDays, setUsageDays] = useState(7);

  useEffect(() => {
    const newData = { ...data };
    newData.labels = externalLabel.slice(externalLabel.length - usageDays);
    for (let i = 0; i < newData.datasets.length; i++) {
      newData.datasets[i].data = externalData[newData.datasets[i].label].slice(
        externalData[newData.datasets[i].label].length - usageDays
      );
    }
    setData(newData);
  }, [usageDays]);
  const onSelectedSurvey = async (e) => {
    const surveyId = e.target.value;
    console.log("onSelectedSurvey surveyId: "+surveyId)
    if (surveyId === "" || selectedSurveys.includes(surveyId)) {
      return;
    }
    setDisabled(true);

    setSelectedSurveys([...selectedSurveys, surveyId]);
    await getSurveyData(surveyId);
    setDisabled(false);
  };

  return (
    <Container className={styles.chart + " p-2"}>
      <Row>
        <Col>
          <Form.Group key="1">
            <Form.Label>Select the survey to show</Form.Label>
            <Form.Control
              disabled={disabled}
              as="select"
              onChange={onSelectedSurvey}
            >
              <option key="-1"> </option>
              {allSurveyIds.map((a) => (
                <option value={a.id} key={a.id}>
                  {a.name}
                </option>
              ))}
            </Form.Control>
            {disabled && <p style={{color:"#ff867c",fontWeight:"bold",marginTop:"10px"}}>Retrieving data from DB, please wait......</p>}
          </Form.Group>
          <Form.Group key="2">
            <Form.Label>Range: The Past {usageDays} days</Form.Label>
            <Form.Control
              type="range"
              min={0}
              max={90}
              value={usageDays}
              variant="secondary"
              onChange={(e) => {
                setUsageDays(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs={8}>
          <LineChart data={data} />
        </Col>
        <Col xs={4}>
          <Row>
            <Col className="h6">The last day's data</Col>
          </Row>
          <Row>
            <Col>
              <PieChart data={data} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default SurveyChart;
