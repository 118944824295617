import React from "react";
import { FormattedMessage } from "react-intl";

const SurveyAnalyticsExplain = () => {
  return (
    <>
      <h4>
        <FormattedMessage
          id="AnalyticsDetail.title"
          defaultMessage="Compare your score against others’ scores"
        />
      </h4>
      <div style = {{textAlign:"left"}}>
        <FormattedMessage
          id="AnalyticsDetail.content1"
          defaultMessage="You can compare your score with the average score of all YourMorals
        users that fit into your chosen demographics. Select up to three of the
        following demographics:"
        />

        <ul className="text-left">
          <li>
            <FormattedMessage id="AnalyticsDetail.Age" defaultMessage="Age" />
          </li>
          <li>
            <FormattedMessage
              id="AnalyticsDetail.Education"
              defaultMessage="Education"
            />
          </li>
          <li>
            <FormattedMessage
              id="AnalyticsDetail.Gender"
              defaultMessage="Gender"
            />
          </li>
          <li>
            <FormattedMessage id="AnalyticsDetail.Race" defaultMessage="Race" />
          </li>
          <li>
            <FormattedMessage
              id="AnalyticsDetail.Religiosity"
              defaultMessage="Religiosity"
            />
          </li>
          <li>
            <FormattedMessage
              id="AnalyticsDetail.Political"
              defaultMessage="Political Ideology"
            />
          </li>
        </ul>
      </div>
      <p style = {{textAlign:"left"}}>
        <FormattedMessage
          id="AnalyticsDetail.content2"
          defaultMessage="Use the checkboxes next to your selected demographics to pick certain
        groups, like everyone aged 30-44 or everyone with a graduate degree."
        />
      </p>
      <p style = {{textAlign:"left"}}>
        <FormattedMessage
          id="AnalyticsDetail.content3"
          defaultMessage={`Example: If you select "Gender: Female" and "Religiosity: Very
        Religious", you will see your score compared against the average score
        of all very religious females.`}
        />
      </p>
      <p style = {{textAlign:"left"}}>
        <FormattedMessage
          id="AnalyticsDetail.content4"
          defaultMessage="Tip: Some combinations of demographics have limited data available. For
        better results, select fewer demographics. *The score will be unavailable if some questions are unanswered(missing)."
        />
      </p>
    </>
  );
};

export default SurveyAnalyticsExplain;
