import { Container } from "react-bootstrap";
import HtmlField from "./HtmlField";
import React from "react";

const HtmlSection = React.memo(
  ({ debrief, setDebrief, instruction, setInstruction }) => {
    return (
      <Container className="section my-2 py-2">
        <HtmlField html={debrief} setHtml={setDebrief} label={"Debrief"} />
        <HtmlField
          html={instruction}
          setHtml={setInstruction}
          label={"Instruction"}
        />
      </Container>
    );
  }
);

export default HtmlSection;
