import { Container, Col, Row, Form, Collapse, Button } from "react-bootstrap";
import DOMPurify from "dompurify";
import "./styles.css";
import { useState } from "react";

const HtmlField = ({ label, html, setHtml }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container className="text-left">
      <Row>
        <Col>
          <Form>
            <Form.Group className="my-2">
              <Form.Label>{label}</Form.Label>
              <Form.Control
                value={html}
                as="textarea"
                size="sm"
                rows={8}
                placeholder={`Enter a ${label}`}
                onChange={(e) => setHtml(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {html && (
        <>
          <Row className="justify-content-center">
            <Col xs={3}></Col>
            <Col className="my-2 d-flex justify-content-center" xs={6}>
              <Button onClick={() => setOpen(!open)} variant="outline-info">
                Click to View the Actual Effect
              </Button>
            </Col>
            <Col xs={3}></Col>
          </Row>
          <Collapse in={open}>
            <Row>
              <Col>
                <div
                  className="my-2 px-5 py-4 preview"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(html),
                  }}
                />
              </Col>
            </Row>
          </Collapse>
        </>
      )}
    </Container>
  );
};

export default HtmlField;
