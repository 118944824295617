/* eslint-disable */
import axios from 'axios'
import { Button, Row, Col, Card, Container } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { IntlProvider, FormattedMessage } from 'react-intl'
import { messages, language } from './services/language'

// const prodURL = "http://52.12.252.255:8080/";
const devURL = 'http://localhost:8080/'
const httpsURL = 'https://api.yourmorals.org/'

export default (history = null) => {
  // Change this to switch between dev and prod
  const baseURL = httpsURL
  let headers = {}
  // const user = JSON.parse(localStorage.getItem("user"));

  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const axiosInstance = axios.create({
    baseURL,
    headers,
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      return new Promise((resolve, reject) => {
        resolve(response)
      })
    },
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }

      if (error.response.status === 402) {
        const originalRequest = error.config
        let refreshToken = localStorage.getItem('refreshToken')
        if (
          refreshToken &&
          error.response.status === 402 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          return axios
            .post(`${baseURL}auth/refresh_token`, {
              refreshToken: refreshToken,
            })
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem('accessToken', res.data.accessToken)
                originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`
                return axios(originalRequest)
              }
            })
            .catch((err) => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <IntlProvider
                      locale={language}
                      messages={messages[language]}
                    >
                      <Card
                        style={{
                          width: '25rem',
                          height: '10rem',
                        }}
                        className="confirmCard"
                      >
                        <Card.Body>
                          <Card.Title>
                            <FormattedMessage
                              id="Session.expiredTitle"
                              defaultMessage="Session Expired"
                            />
                          </Card.Title>
                          <Card.Text>
                            <FormattedMessage
                              id="Session.expiredContent"
                              defaultMessage="Session expired. Please Login again."
                            />
                          </Card.Text>
                          <Container fluid>
                            <Row>
                              <Col xs={6} className="text-left">
                                <Button
                                  variant="outline-success"
                                  className="w-100"
                                  onClick={() => {
                                    localStorage.removeItem('user')

                                    if (history) {
                                      history.push('/')
                                    } else {
                                      window.location = '/'
                                    }
                                    onClose()
                                  }}
                                >
                                  OK
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </IntlProvider>
                  )
                },
              })
              localStorage.removeItem('user')
              localStorage.removeItem('accessToken')
              localStorage.removeItem('refreshToken')
            })
        } else {
          return new Promise((resolve, reject) => {
            reject(error)
          })
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    }
  )

  return axiosInstance
}
