import { Container, Col, Row, Button, ProgressBar } from "react-bootstrap";
import React from "react";
import styles from "../Admin.module.css";

const ManageSurveyListItem = ({ survey, selectSurvey }) => {
  const { name, status, percentage, totalParticipants, description, id, type } =
    survey;

  let creator = survey.creator ? survey.creator : "Yourmorals";

  return (
    <Container className={`${styles.surveylist} my-2`}>
      <Row className={`py-2 px-4 ${styles.rowHeight} align-items-center`}>
        <Col
          xs={3}
          className={`${styles.text} ${styles.rowHeight} text-center`}
        >
          {name}
        </Col>
        <Col className={`${styles.text} ${styles.rowHeight} text-muted`}>
          Status: {status}
        </Col>
        {type !== "general" ? (
          <React.Fragment>
            <Col className="d-none d-lg-block">
              <ProgressBar
                now={percentage * 100}
                label={`${parseFloat(percentage * 100).toFixed(0)}%`}
              />
            </Col>
            <Col className="d-lg-none d-xs-block ml-auto">
              {`${parseFloat(percentage * 100).toFixed(0)}% Completed`}
            </Col>
          </React.Fragment>
        ) : (
          <Col className={`${styles.text} ${styles.rowHeight}`}>
            <p>{`${totalParticipants} participants`}</p>
          </Col>
        )}

        <Col
          xs={3}
          className={`${styles.text} ${styles.rowHeight} d-none d-lg-block`}
        >
          {`Created by ${creator}`}
        </Col>

        <Col
          xs={1}
          className={`${styles.rowHeight} align-center d-none d-lg-block pl-0`}
        >
          <Button
            variant="outline-success"
            size="sm"
            className="ml-auto"
            onClick={() => selectSurvey(survey)}
          >
            Detail
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageSurveyListItem;
