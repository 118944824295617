import { Container, Col, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import "./SurveyTable.css";
import { useIntl, defineMessage } from "react-intl";

const SurveyListItem = ({ name, description, completed, id }) => {
  const history = useHistory();
  const intl = useIntl();
  const messages = defineMessage({
    result: {
      id: "ListItem.result",
      defaultMessage: "View Results",
    },
    take: {
      id: "ListItem.take",
      defaultMessage: "Take survey",
    },
  });
  const handler = completed
    ? () => {
        history.push("/result/" + id);
      }
    : () => {
        history.push("/start/" + id);
      };
  return (
    <Container className="survey-list my-2" fluid>
      <Row className="py-2 px-4 align-items-center">
        <Col xs={3} md={3} className="row-height">
          <p className="text-left text"> {name}</p>
        </Col>
        <Col xs={6} md={7} className="px-xs-0 row-height">
          <p className="text text-muted"> {description}</p>
        </Col>
        <Col xs={3} md={2} className="row-height pl-xs-0">
          <Button
            variant={completed ? "outline-info" : "outline-success"}
            size="sm"
            onClick={handler}
          >
            <span className="one-line-button">
              {completed
                ? intl.formatMessage(messages.result)
                : intl.formatMessage(messages.take)}
            </span>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyListItem;
