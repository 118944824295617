import { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// Display the privacy policy page
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid className="w-75 text-left">
        <Row className="px-5">
          <Col>
            <h1 className="text-center">
              <FormattedMessage
                id="Privacy.title"
                defaultMessage="YourMorals Privacy Policy"
              />
            </h1>
            <br />

            <p>
              <FormattedMessage
                id="Privacy.goal"
                defaultMessage="Our goal is to educate the public and also to conduct research on
              moral psychology. We are committed to conducting this research
              ethically and to protecting your privacy. For example, we will
              never give out or sell your email address to anyone for any
              reason."
              />
            </p>
            <br />

            <h3>
              <FormattedMessage
                id="Privacy.collectTitle"
                defaultMessage="What we collect"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.collectContent1"
                defaultMessage="At registration we request that you tell us some information about
              yourself that will help us to analyze your other responses. We ask
              you to provide us with an email address because that is a kind of
              username that people are unlikely to forget. When you participate
              in any of our studies, you provide responses to questionnaires and
              to experiments on moral judgment. Before taking a questionnaire,
              you will be asked to provide consent for us to collect and process
              your responses. You are free to withdraw your consent at any point
              while taking the questionnaire. To withdraw consent, close your
              browser window."
              />
            </p>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.collectContent2"
                defaultMessage="We store all of the collected information on a secure server,
              protected by a firewall. SSL encryption is used to transfer your
              email address to our server. The file containing your email
              address and the files containing your responses to the studies are
              stored separately, in separate databases with different passwords.
              These databases cannot be merged or interpreted by anyone without
              specialized knowledge of our website. The password you chose is
              stored in an encrypted form, which means that even we cannot find
              out what it is. If you forget your password, you will be given the
              opportunity to reset your password, but we can’t send your old
              password to you because it is stored on our server in a form that
              we cannot unencrypt."
              />
            </p>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.collectContent3"
                defaultMessage="If you previously submitted your e-mail address and would like to
              remove it, please contact our"
              />
              <b>
                <FormattedMessage
                  id="Privacy.collectContent4"
                  defaultMessage="webmaster (at) yourmorals (dot) org"
                />
              </b>
              .
            </p>
            <br />

            <h3>
              <FormattedMessage
                id="Privacy.doWithInfoTitle"
                defaultMessage="What we will do with your information"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.doWithInfoContent1"
                defaultMessage="To analyze the results of the various studies conducted on
              YourMorals, we create a variety of merged files which combine the
              information you gave us at registration with the information you
              give us on each study you complete. These files of information do
              NOT contain your email or password, so these merged files are
              essentially anonymous. Some of these files will contain IP address
              information in order to identify usage patterns by country/state
              and to prevent duplicate submissions, however most IP addresses
              are not fixed and are traceable only to the organization which
              provides you with internet access. As a practical matter, it is
              generally impossible to identify a user with an IP address without
              the cooperation of an internet service provider, which usually is
              not given without a subpoena."
              />
            </p>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.doWithInfoContent2"
                defaultMessage="These merged files are then sent out to the members of the
              research consortium that runs YourMorals. At times we might send
              these files out to other researchers, as part of normal scientific
              courtesy, if they think that the data in these files could help
              them to answer scientific questions. But we repeat that these
              files will not contain any information that could lead anyone to
              identify the people who provided the data, and we will always
              strip out IP address information from any file that is sent out
              beyond our small group of researchers."
              />
            </p>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.doWithInfoContent3"
                defaultMessage="We will carefully guard your privacy. We plan to keep these merged
              data files for many years. We might place some of this anonymous
              numerical data into a “data archive” of the sort that some
              scientists are trying to create to make it easy for scientists to
              check each other’s work. We will write up scientific articles that
              report the results of our studies by reporting the averages and
              other statistics computed across many people’s data. In the rare
              cases that we report a quote from text that a participant typed
              into a text box, we will be certain that such quotes do not reveal
              the identity of the source."
              />
            </p>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.doWithInfoContent4"
                defaultMessage="You can request all your data to be completely deleted from our
              servers. You can make this request at any point under the My
              Profile section, after you are logged in to the website."
              />
            </p>

            <h3>
              <FormattedMessage
                id="Privacy.webRelatedTitle"
                defaultMessage="Web-related information we collect automatically"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.webRelatedContent1"
                defaultMessage="Like almost all Web sites, we automatically collect information on
              all requests for pages from our web server. We collect the IP
              address of your Internet connection (which generally does not
              identify you unless your Internet service provider has assigned
              you a static IP address), the type of browser you used, what you
              requested, and what was sent. This helps us understand usage of
              the web site and allows us to produce aggregate statistics on
              usage. We may also use common 3rd party services from companies
              like Google and Facebook to provide aggregated statistics on usage
              and the ability for our users to share information through social
              media. These companies may collect data on your use of our site as
              a result, but none of that information will be linked to any
              personally identifying information that we collect. If (and only
              if) you choose to log in using your social media account (e.g.,
              Facebook), we may link data from your profile to your
              YourMorals.org account."
              />
            </p>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.webRelatedContent2"
                defaultMessage="We do not use cookies."
              />
            </p>

            <h3>
              <FormattedMessage
                id="Privacy.securityTitle"
                defaultMessage="Security of the data we collect"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.securityContent"
                defaultMessage="We take reasonable technical, administrative, and physical
              precautions to keep your information secure. For example, we store
              your responses in a password-protected database located in a
              secure data center. Email address information is stored separately
              from your responses and requires a separate password to access.
              When you are logging in or registering or participating in
              studies, your data is sent via SSL encryption which prevents other
              people from intercepting the data and identifying you."
              />
            </p>

            <h3>
              <FormattedMessage
                id="Privacy.dataRightTitle"
                defaultMessage="Your data protection rights"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.dataRightContent1"
                defaultMessage="We would like to make sure you are fully aware of your data
              protection rights. Every user is entitled to the following:"
              />
            </p>
            <ul className="privacy-body">
              <li>
                <FormattedMessage
                  id="Privacy.dataRightContent2"
                  defaultMessage="The right to access – You have the right to request
                copies of your personal data."
                />
              </li>
              <li>
                <FormattedMessage
                  id="Privacy.dataRightContent3"
                  defaultMessage="The right to rectification – You have the right to
                request that we correct any information you believe is
                inaccurate. You also have the right to request that we complete
                the information you believe is incomplete."
                />
              </li>
              <li>
                <FormattedMessage
                  id="Privacy.dataRightContent4"
                  defaultMessage="The right to erasure – You have the right to request that
                we erase your personal data, under certain conditions."
                />
              </li>
              <li>
                <FormattedMessage
                  id="Privacy.dataRightContent5"
                  defaultMessage="The right to restrict processing – You have the right to
                request that we restrict the processing of your personal data,
                under certain conditions."
                />
              </li>
              <li>
                <FormattedMessage
                  id="Privacy.dataRightContent6"
                  defaultMessage="The right to object to processing – You have the right to
                object to our processing of your personal data, under certain
                conditions."
                />
              </li>
              <li>
                <FormattedMessage
                  id="Privacy.dataRightContent7"
                  defaultMessage="The right to data portability – You have the right to
                request that we transfer the data that we have collected about
                you to another organization, or directly to you, under certain
                conditions."
                />
              </li>
            </ul>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.dataRightContent8"
                defaultMessage="If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us at
              our email <b>webmaster (at) yourmorals (dot) org</b>."
              />
            </p>

            <h3>
              <FormattedMessage
                id="Privacy.changesTitle"
                defaultMessage="Changes"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.changesContent"
                defaultMessage="We may change this Privacy Statement in the future. If we do, we
              will post any changes to this page promptly. This statement is
              effective as of May 18, 2021."
              />
            </p>

            <h3>
              <FormattedMessage
                id="Privacy.contactTitle"
                defaultMessage="How to contact us"
              />
            </h3>
            <p className="privacy-body">
              <FormattedMessage
                id="Privacy.contactContent1"
                defaultMessage="If you have questions about our privacy policy or the data we hold
              about you, or you would like to exercise one of your data
              protection rights, please do not hesitate to contact us by sending
              an email to "
              />
              <b>
                <FormattedMessage
                  id="Privacy.contactContent2"
                  defaultMessage="webmaster (at) yourmorals (dot) org"
                />
              </b>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(PrivacyPolicy);
