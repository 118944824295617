import {
  Container,
  Col,
  Row,
  Button,
  ProgressBar,
  Form,
} from "react-bootstrap";
import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";
import "./styles.css";

const BasicInfo = React.memo(
  ({
    name,
    setName,
    description,
    setDescription,
    totalParticipants,
    setTotalParticipants,
  }) => {
    useEffect(() => {}, []);

    const setNumber = (e) => {
      const re = /^[0-9\b]+$/;
    };

    return (
      <Container className="section my-2 py-2">
        <Row>
          <Col xs={12} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label style={{color:"red"}}>Name*</Form.Label>
                <Form.Control
                  value={name}
                  placeholder="Enter a name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label style={{color:"red"}}>Description*</Form.Label>
                <Form.Control
                  value={description}
                  as="textarea"
                  size="sm"
                  rows={3}
                  placeholder="Enter a description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label>Total Participant</Form.Label>
                <Form.Control
                  type="number"
                  value={totalParticipants}
                  placeholder="10000"
                  onChange={(e) => setTotalParticipants(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default BasicInfo;
