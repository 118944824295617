import { Card } from "react-bootstrap";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const CustomConfirm = ({ onClose, title, text, children }) => {
  return (
    <Card style={{ width: "25rem", height: "10rem" }} className="confirmCard">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        {children}
      </Card.Body>
    </Card>
  );
};

export default CustomConfirm;
