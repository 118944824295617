import { Pie } from "react-chartjs-2";
import React from "react";

const PieChart = React.memo(({ data }) => {
  const options = {};
  const procData = {
    labels: [],
    datasets: [
      {
        label: "data of last day",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };
  for (let i = 0; i < data.datasets.length; i++) {
    const dset = data.datasets[i];
    procData.labels.push(dset.label);
    procData.datasets[0].data.push(dset.data[dset.data.length - 1]);
    procData.datasets[0].backgroundColor.push(dset.backgroundColor);
    procData.datasets[0].borderColor.push(dset.borderColor);
  }
  return (
    <>
      <Pie data={procData} options={options} redraw={true}/>
    </>
  );
});

export default PieChart;
