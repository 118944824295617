import React from 'react'
import './styles/home.css'
import { Col, Container, Row, Modal } from 'react-bootstrap'
import { withRouter, Link, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { PropTypes } from 'prop-types'
import AuthPanel from './components/Auth/AuthPanel'

class Home extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showRegisterComponent: false,
      showResetComponent: false,
      show: false,
      name: 'login',
    }
  }

  loadRegisterComponent = () => {
    this.setState({
      showRegisterComponent: true,
    })
  }

  handleShow = (name) => {
    this.setState({ show: true, name: name })
  }
  handleHide = (name) => {
    this.setState({ show: false, name: name })
  }

  setLogin = () => {
    this.setState({
      showRegisterComponent: false,
      showResetComponent: false,
    })
  }

  loadResetPassword = () => {
    this.setState({
      showResetComponent: true,
    })
  }

  render() {
    return (
      <Container fluid className="w-100">
        <Row className="mt-5 mx-2 mb-4">
          <Col md={9} xs={12} sm={12} style={{ textAlign: 'left' }}>
            <h2 style={{ fontWeight: 650 }}>
              <FormattedMessage
                id="Home.welcomeTitle"
                defaultMessage="Welcome to YourMorals.org!"
              />
            </h2>

            <p className="welcomeText1">
              <FormattedMessage
                id="Home.welcomeText1"
                defaultMessage="Welcome to YourMorals.org, where you can learn about your own
              morality, ethics, and/or values, moral values, ethical behaviors,
              personality, and political preferences while also contributing to
              scientific research. We are a group of professors, researchers,
              and graduate students in social psychology."
              />
            </p>
          </Col>
          <Col md={3} xs={12} sm={12}>
            <Row className="my-4">
              <Col className="d-flex justify-content-center">
                <p onClick={() => this.handleShow('login')} className="p1">
                  <FormattedMessage
                    id="Home.signIn"
                    defaultMessage="Sign in to my account"
                  />
                </p>
              </Col>
            </Row>
            <Row className="my-4">
              <Col className="d-flex justify-content-center">
                <p onClick={() => this.handleShow('signup')} className="p1">
                  <FormattedMessage
                    id="Home.register"
                    defaultMessage="Register a new account"
                  />
                </p>
              </Col>
            </Row>

            <Modal
              show={this.state.show}
              onHide={this.handleHide}
              className="authPanel"
            >
              <Modal.Body className="text-center">
                <AuthPanel
                  name={this.state.name}
                  hide={this.handleHide}
                  setTab={this.props.setTab}
                />
              </Modal.Body>
            </Modal>
          </Col>
        </Row>

        <Row className="goals">
          <Col md={5}>
            <img
              src={process.env.PUBLIC_URL + 'images/newGoal.png'}
              className="goalsImg"
              alt="hostlity"
            />
          </Col>
          <Col md={7} className="goalsImgText">
            <h1 className="goalsHeading">
              <FormattedMessage
                id="Home.ourGoalsTitle"
                defaultMessage="Our Goals"
              />
            </h1>
            <FormattedMessage
              id="Home.ourGoalsContent"
              defaultMessage={`Our goal is to understand the way our "moral minds" work. Why do
            people disagree so passionately about what is right? Why, in
            particular, is there such hostility and incomprehension between
            members of different political parties? By filling out a few of our
            surveys, you'll help us answer those questions. We, in return, will
            give you an immediate report and visual feedback on how you scored
            on each study, quiz, or survey. We'll show you how your responses
            compare to others, and we'll tell you what that might say about you.`}
            />
          </Col>
        </Row>

        <Row className="RegisterText">
          <Col sm={12} xs={12} md={5} className="joinUs">
            <h1 className="goalsHeading" style={{ color: 'black' }}>
              <FormattedMessage
                id="Home.exploreTitle"
                defaultMessage="Explore!"
              />
            </h1>
            <p>
              <FormattedMessage
                id="Home.exploreContent"
                defaultMessage={`So please join us and register, then begin exploring your
              morality. Registration takes just 2 minutes, and it will allow us
              to hold your scores for you so that you may return at any time and
              continue to build up your "morality profile." This site is a
              non-profit academic venture, with no fees or advertising. We will
              guard your `}
              />
              <Link to="/privacy">
                <FormattedMessage
                  id="Home.exploreContentPrivacy"
                  defaultMessage="privacy"
                />
              </Link>
              <FormattedMessage
                id="Home.exploreContentCarefully"
                defaultMessage=" carefully."
              />
            </p>
          </Col>

          <Col sm={12} xs={12} md={6}>
            <img
              alt="yourmorals"
              style={{ width: '80%' }}
              src={process.env.PUBLIC_URL + 'images/yourmorals.png'}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(Home)
