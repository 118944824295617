import { Container, Row, Col } from 'react-bootstrap'
import ResetPasswordPopUp from './ResetPasswordPopUp'
import DeleteDataPopUp from './DeleteDataPopUp'
import { FormattedMessage } from 'react-intl'

const AccountSettings = ({ email, logout, history }) => {
  return (
    <Container className="text-left p-4">
      <Row className="align-items-center">
        <Col sm={8}>
          <p className="h5">
            <FormattedMessage
              id="Account.resetPasswordTitle"
              defaultMessage="Reset Password"
            />
          </p>
          <p className="text-muted">
            <FormattedMessage
              id="Account.resetPassword"
              defaultMessage="Reset Your Password with your current passowrd"
            />
          </p>
        </Col>
        <Col>
          <ResetPasswordPopUp />
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col sm={8}>
          <p className="h5">
            <FormattedMessage
              id="Account.deleteData"
              defaultMessage="Delete My Data"
            />
          </p>
          <p className="text-muted">
            <FormattedMessage
              id="Account.deleteDataContent1"
              defaultMessage="Only your answered survey and demographic data will be deleted"
            />
          </p>
        </Col>

        <Col>
          <DeleteDataPopUp
            email={email}
            logout={logout}
            history={history}
            type="without-credentials"
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm={8}>
          <p className="h5">
            <FormattedMessage
              id="Account.deleteAccountTitle"
              defaultMessage="Delete My Account"
            />
          </p>
          <p className="text-muted">
            <FormattedMessage
              id="Account.deleteAccountContent"
              defaultMessage="By deleting your account, all of your surveys, account information
            and demographic data will be deleted"
            />
          </p>
        </Col>

        <Col>
          <DeleteDataPopUp
            email={email}
            logout={logout}
            history={history}
            type="with-credentials"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default AccountSettings
