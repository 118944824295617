import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axiosInstance from "../../../axiosInterceptor";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import UserInfo from "./UserInfo";
import ConfirmCancelAlert from "../../../components/ConfirmAlert/ConfirmCancelAlert";
import styles from "../Admin.module.css";
import AdminTable from "./AdminTable"

const ManageUser = () => {
  const [email, setEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [allRequests,setAllRequests] =  useState(null);
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [signal, setSignal] = useState(null);
  const [cellClicked, setCellClicked] = useState(null);

  const changRole = async (action) => {
    try {
      const resp = await axiosInstance().post(`admin/${action}/${user._id}`);
      setUser(resp.data.user);
    } catch (err) {}
  };

  const updateUser = async () => {
    try {
      const userResult = await axiosInstance().get(
        "admin/user/" + encodeURIComponent(email)
      );
      if (userResult.data.user) {
        setUser(userResult.data.user)
        // console.log("updateUser user: "+JSON.stringify(userResult.data.user))
        // console.log("updateUser user.requestAllow: "+JSON.stringify(userResult.data.user.requestAllow))
      }
      if (userResult.data.profile) {
        // console.log("userResult.data.profile"+JSON.stringify(userResult.data.profile))
        setProfile(userResult.data.profile);
      }
      // if(userResult.data.user.requestAllow){
        const pendingRequests= await axiosInstance().get(
          "admin/getAllRequests/" + encodeURIComponent(email)
        );
        // console.log("getAllRequests:"+ JSON.stringify(pendingRequests.data.data))
        setAllRequests(pendingRequests.data.data)
      // }
    }
    catch(e){
      console.log("updateUser() error:"+e)
    }
  }

  //Allow user to send request data
  const allowSendRequest = async (action,e) => {
    try {
      const resp = await axiosInstance().post(`admin/allowSendRequest/${user.email}`);
      updateUser()

    } catch (err) {
      console.log("allowSendRequest() error:"+err)
    }
  };

  //Reject user's request
  const manageRequest = async (action,requestId) => {
    try {
      // const api = action === "allow"? 
      const resp = await axiosInstance().post(`admin/manageRequest/${requestId}/${action}`);
      // console.log("manage request:"+JSON.stringify(resp.status == 200))
      updateUser()
    }
    catch(e){
      console.log("manageRequest() error:"+e)
    }

  }

  //promote and demote actions
  const action = async (action,e) => {
    console.log("action:"+e.target.innerHTML )
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmCancelAlert
            // title={`${action.toUpperCase()} the user?`}
            title = {e.target.innerHTML}
            onClose={() => {
              onClose();
            }}
            onConfirm={() => 
            {
              changRole(action)
              setSignal((preS)=>!preS)
              // updateUser()
            }
            }
            text="Click Ok to confirm"
          />
        );
      },
    });
  };

  const searchUser = async () => {
    setError(null);
    if (!email) {
      console.log("!email")
      return;
    }
    try {
      updateUser()
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        console.log("404 not found")
        setProfile(null)
        setUser(null)
        setEmail(null)
        setAllRequests(null)
        setError(err.response.data.message);
        return;
      }
      setError("An error occurred");
    }
  };
  
  function changeEmail(props) {
    console.log("email changed")
    setEmail(props)
    setCellClicked(prev=>!prev)
  }

  // useEffect(()=>{
  //   searchUser()
  // },[cellClicked])
  // useEffect(()=>{
  //   console.log("useEffect allRequests: "+JSON.stringify(allRequests))
  // },[allRequests])

  return (
    <Container className={styles.chart + "px-2"}>

      <AdminTable signal={signal} mEmail={changeEmail} />

      <Row className="mt-2">
        <Col>
          <p className="h4">Manage User</p>
        </Col>
      </Row>
      <Row className="mx-2">
        <Col className="text-left">
          <p className="h6">Start by searching a email</p>
        </Col>
      </Row>
      <Row className="mx-2">
        <Col md={9}>
          <Form.Group className="w-100">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email ||""}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Button size="md" onClick={searchUser}>
            Search
          </Button>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      <Row  className="mx-1">
        <Col>
          <UserInfo profile={profile} user={user} allRequests={allRequests} action={action} allowSendRequest={allowSendRequest} manageRequest={manageRequest} />
        </Col>
      </Row>
    </Container>
  );
};

export default ManageUser;
