import { useState, useEffect } from "react";
import axiosInstance from "../../axiosInterceptor";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import PersonalityResults from "../Participant/PersonalityResults";
import SurveyTableSection from "../../components/SurveyTableSection/SurveyTableSection";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

import "./SurveyPage.css";

const SurveyPage = ({ card, email, dataReqPermit = false }) => {
  const [age, setAge] = useState(true);
  const [MFQResult, setMFQResult] = useState(null);
  const [MFQMean, setMFQMean] = useState(null);
  const [completedSurveys, setCompletedSurveys] = useState([]);
  const [todoSurveys, setTodoSurveys] = useState([]);
  const [incompletePreSurveys, setIncompletePreSurveys] = useState([]);
  // const [experiments, setExperiments] = useState([]);
  const intl = useIntl();
  const messages = defineMessage({
    finish: {
      id: "Survey.finish",
      defaultMessage: dataReqPermit? " ":"You need to finish the following survey to continue",
    },
    completed: {
      id: "Survey.completed",
      defaultMessage: "Completed Surveys",
    },
    new: {
      id: "Survey.newSurveys",
      defaultMessage: "New Surveys",
    },
  });

  useEffect(() => {
    const fetchMFQResult = async () => {
      try {
        const res = await axiosInstance().get("auth/mfq");
        if (res.data) {
          setMFQResult(res.data.result);
          setMFQMean(res.data.mean);
        }
      } catch (err) {}
    };
    const fetchCompleted = async () => {
      try {
        const res = await axiosInstance().get("auth/completed-surveys");
        if (res.data.completed_surveys) {
          setCompletedSurveys(res.data.completed_surveys);
        }
      } catch (err) {}
    };
    const fetchTodo = async () => {
      try {
        const res = await axiosInstance().get("auth/todo-surveys");
        if (res.data.todo) {
          setTodoSurveys(res.data.todo);
        }
      } catch (err) {}
    };
    const fetchIncomplete = async () => {
      try {
        let res = await axiosInstance().get("auth/incomplete-pre-surveys");
        if (res.data.incompleted) {
          //set required suvrvey to MDQ1 and mfq only
          res.data.incompleted = res.data.incompleted.filter((ele)=>{
            return (ele.id === "MDQ1" || ele.id === "mfq")
          })
          if (res.data.incompleted.length > 1) {//number of req survey -1 
            for (let i = 0; i < res.data.incompleted.length; i++) {
              if (res.data.incompleted[i].id === "MDQ1") {
                setIncompletePreSurveys([res.data.incompleted[i]]);
              }
            }
          } else {
            setIncompletePreSurveys(res.data.incompleted);
          }
          return res.data.incompleted.length;
        }
      } catch (err) {
        return -1;
      }
    };

    const fetchAge = async () => {
      try {
        const res = await axiosInstance().get("user/age");
        if (res.data) {
          setAge(res.data.over_eighteen);
        }
      } catch (err) {}
    };

    // const fetchExperiment = async () => {
    //   try {
    //     const res = await axiosInstance().get("auth/experiment");

    //     if (res.data) {
    //       setExperiments(res.data.experiments);
    //     }
    //   } catch (err) {}
    // };

    const start = async () => {
      try {
        const result = await fetchIncomplete();
        fetchAge();
        if (result === 0) {
          // fetchAge();
          fetchMFQResult();
          fetchCompleted();
          fetchTodo();
          // fetchExperiment();
        }
      } catch (err) {
        console.log(err)
      }
    };
    start();
  }, []);

  const finishedPage = () => {
    if (!age) {
      return (
        <Row style={{ height: "100vh" }}>
          <Col>
            <p className="display-4">
              <FormattedMessage
                id="Survey.eligible"
                defaultMessage="You must be at least 18 to be eligible for yourmorals.org"
              />
            </p>
          </Col>
        </Row>
      );
    }
    if (
      (!MFQMean || !MFQResult || !completedSurveys || !todoSurveys) &&
      incompletePreSurveys.length === 0
    ) {
      return (
        <Row className="spinner">
          <Col>
            <Spinner animation="border" variant="success" />
          </Col>
        </Row>
      );
    } else if (incompletePreSurveys && incompletePreSurveys.length > 0) {
      return (
        <>
          <Row>
            <Col>
              <SurveyTableSection
                title={intl.formatMessage(messages.finish)}
                surveys={incompletePreSurveys}
              />
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col>
              <PersonalityResults
                participantResult={MFQResult}
                participantMean={MFQMean}
                email={email}
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col xs={12}>
              <SurveyTableSection
                title={intl.formatMessage(messages.new)}
                surveys={todoSurveys}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <SurveyTableSection
                title={intl.formatMessage(messages.completed)}
                surveys={completedSurveys}
              />
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col xs={12}>
              <SurveyTableSection
                title="Experiments for You"
                surveys={experiments}
              />
            </Col>
          </Row> */}
        </>
      );
    }
  };

  return (
    <Container fluid className="mt-4">
      {finishedPage()}
    </Container>
  );
};

export default SurveyPage;
