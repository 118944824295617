import { useState } from "react";
import { ListGroup, Form, Col, Row, Container } from "react-bootstrap";

import "./ListAnimation.css";

const Item = ({ removeItem, item, setSelectedProperty, selectedProperty }) => {
  const [isFadingOut, setIsFadingOut] = useState(false);
  const fadeOut = (cb) => {
    setIsFadingOut(true);
  };
  const handleRemoveItem = () => {
    removeItem();
    setIsFadingOut(false);
  };

  const onChecBoxChanged = (e, v, name) => {
    const properties = [...selectedProperty];
    properties.forEach((p) => {
      if (p.name === name) {
        p.target = v;
      }
    });
    setSelectedProperty(properties);
  };

  const inputField = () => {
    return (
      <Col className="ml-5 text-left">
        {item.value.map((v, idx) => (
          <Form.Check
            inline
            key={idx}
            type="radio"
            label={v}
            id={`inline--1`}
            name={item.name}
            checked={v === item.target}
            onChange={(e) => onChecBoxChanged(e, v, item.name)}
          ></Form.Check>
        ))}
      </Col>
    );
  };

  return (
    <ListGroup.Item className={isFadingOut ? "item-fadeout" : "item"}>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col sm={2}>{item.label}</Col>
          {inputField()}
          <Col sm={1}>
            <span
              onClick={() => fadeOut(setTimeout(() => handleRemoveItem(), 300))}
              style={{ fontSize: "16px" }}
              className="ml-auto"
            >
              &#10006;
            </span>
          </Col>
        </Row>
      </Container>
    </ListGroup.Item>
  );
};

const PropertyCheckList = ({ selectedProperty, setSelectedProperty }) => {
  return (
    <div>
      <ListGroup>
        {selectedProperty.map((item, idx) => {
          return (
            <Item
              key={idx}
              item={item}
              selectedProperty={selectedProperty}
              setSelectedProperty={setSelectedProperty}
              removeItem={() =>
                setSelectedProperty(
                  selectedProperty.filter(
                    (itemInner) => itemInner.name !== item.name
                  )
                )
              }
            ></Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default PropertyCheckList;
