import { Container, Col, Row, Button, ListGroup } from "react-bootstrap";
import React, { useState } from "react";
import AnalyticsModal from "./AnalyticsModal";
import AnalyticsTab from "./AnalyticsTab";
import "../styles.css";

const Analytics = React.memo(({ pages, analytics, setAnalytics }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Container className="my-4 section">
      <Row className="mb-4 pt-4">
        <Col className="d-flex justify-content-center">
          <Button onClick={() => setModalShow(true)} variant="outline-primary">
            Click to Add an Analytic
          </Button>
        </Col>
      </Row>
      <AnalyticsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        newAnalytics={true}
        analytics={analytics}
        questions={pages}
        setAnalytics={setAnalytics}
      />
      <ListGroup>
        {analytics.map((a, i) => (
          <ListGroup.Item
            key={i}
            style={{ border: "0px solid black", borderRadius: "15px" }}
          >
            <Row className="my-2">
              <Col>
                <AnalyticsTab
                  analytic={a}
                  questions={pages}
                  analytics={analytics}
                  setAnalytics={setAnalytics}
                />
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
});

export default Analytics;
