import { useState, useEffect, useCallback,React } from 'react';
import { Container, Row, Col, Tab, Nav, Spinner } from 'react-bootstrap';
import axiosInstance from '../../axiosInterceptor';
import ReportTable from './BugReports/ReportTable';
import DownloadData from './DownloadData/DownloadData';
import ManageSurveyList from './ManageSurveyListItem/ManageSurveyList';
import SurveyChart from './Usage/SurveyChart';
import UserChart from './Usage/UserChart';
import ManageUser from './User/ManageUser';
import INCASChart from './IncasStatus';

import styles from './Admin.module.css';

const AdminPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [allSurveys, setAllSurveys] = useState([]);
    // const key = useState("allSurveys")[0]
    const [update, setUpdate] = useState(1);
    const [loading, setLoading] = useState(false);

    const login_user = localStorage.getItem('user')
    let superAdmin = false;
    if(login_user){
      const login_email = JSON.parse(login_user).email
      console.log("login_user email:"+login_email)
      superAdmin =
        login_email === 'mdehghan@usc.edu' ||
        login_email === 'l1998118@gmail.com' ||
        login_email === 'hejabi@usc.edu' ||
        login_email === 'adevnani@usc.edu'
          ? true
          : false
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const resp = await axiosInstance().get('admin/surveys');
                if (resp.data.all_surveys) {
                    setAllSurveys(resp.data.all_surveys);
                }
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        };
        fetchData();
    }, [update]);

    if (loading) {
        return (
            <Container
                style={{ width: '80vw', height: '30vh' }}
                className={
                    styles.backgroundSection +
                    ' p-2 my-3 justify-content-center'
                }
            >
                <Row className="mt-5">
                    <Col>
                        <Spinner animation="border" variant="info" size="lg" />
                        <p className="h5 mt-3">Loading</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container style={{ width: '80vw' }}>
            <Tab.Container defaultActiveKey={'allSurveys'}>
                <Row>
                    <Col>
                        <Nav
                            className={
                                styles.backgroundSection +
                                ' p-2 my-3 justify-content-center'
                            }
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="allSurveys">
                                    Manage Surveys
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="usage">
                                    Manage Usage
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="users">
                                    Manage Users
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="bugReports">
                                    Bug Reports
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="download">
                                    Download Data
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="incas">INCAS Data</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Tab.Content>
                    <Tab.Pane eventKey="allSurveys">
                        <ManageSurveyList
                            role={'admin'}
                            allSurveys={allSurveys}
                            setAllSurveys={setAllSurveys}
                            update={update}
                            setUpdate={setUpdate}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="usage">
                        <Container
                            className={styles.backgroundSection + ' px-3 py-3'}
                        >
                            <Row>
                                <Col>
                                    <UserChart />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <SurveyChart />
                                </Col>
                            </Row>
                        </Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="users">
                        <Container
                            className={styles.backgroundSection + ' px-3 py-3'}
                        >
                            {superAdmin&&<ManageUser />}
                        </Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="bugReports">
                        <ReportTable />
                    </Tab.Pane>
                    <Tab.Pane eventKey="download">
                        <DownloadData allSurveys={allSurveys} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="incas">
                        <INCASChart />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Container>
    );
};

export default AdminPage;
