import { Container, Col, Row, Form } from "react-bootstrap";
import "./styles.css";
import React from "react";

const Agreement = React.memo(
  ({
    userAgreement,
    setUserAgreement,
    userAgreementDescription,
    setUserAgreementDescription,
  }) => {
    return (
      <Container className="section my-2 py-2">
        <Row>
          <Col xs={12} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label>User Agreement</Form.Label>
                <Form.Control
                  value={userAgreement}
                  as="textarea"
                  size="sm"
                  rows={4}
                  placeholder="Enter a user agreement"
                  onChange={(e) => setUserAgreement(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left">
            <Form>
              <Form.Group className="my-2">
                <Form.Label>Description of User Agreement</Form.Label>
                <Form.Control
                  as="textarea"
                  size="sm"
                  rows={4}
                  value={userAgreementDescription}
                  placeholder="Enter a user agreement description"
                  onChange={(e) => setUserAgreementDescription(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default Agreement;
