import { useCallback } from "react";
import { Card } from "./Card";
import update from "immutability-helper";
import { Container, Row, Col } from "react-bootstrap";

const DndList = ({ cards, setCards }) => {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );
  return (
    <Container style={{ width: "50vw" }}>
      {cards.map((card, i) => (
        <Row key={card.id ? card.id : i} className="my-2">
          <Col>
            <Card
              key={card.id}
              index={i}
              id={card.id}
              text={card.text ? card.text : card}
              moveCard={moveCard}
            />
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default DndList;
