// import * as SurveyCreator from "survey-creator-react";
import { SurveyCreator,SurveyCreatorComponent } from "survey-creator-react";
import React from "react";
import "survey-creator-core/survey-creator-core.css";
import "survey-core/defaultV2.min.css";

import { Container, Button, Modal, Col, Row, Collapse } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./styles.css";

const SurveyCreatorComponentCustom = React.memo(({ pages, setPages }) => {
  const [show, setShow] = useState(false);
  let creator;
  // useEffect(() => {
    const creatorOptions = {
      showSurveyTitle: "never",
      haveCommercialLicense: true,
      questionTypes: [
        "text",
        "checkbox",
        "radiogroup",
        "dropdown",
        "html",
        "matrix",
        "matrixdropdown",
        "rating",
        "image",
      ],
    };
    // creator = new SurveyCreator.SurveyCreator("creatorElement", creatorOptions);
    creator = new SurveyCreator(creatorOptions);
    // creator.isAutoSave = true;
    // creator.showToolbox = true;

    // creator.toolbox.itemsValue = [creator.toolbox.itemsValue[0]];
    // creator.rightContainerActiveItem("toolbox");

  useEffect(() => {
    creator.saveSurveyFunc = (saveNo, callback) => {
      setPages(creator.JSON.pages);
      console.log("survey save, creator.JSON.pages:"+creator.JSON.pages);
      callback(saveNo, true);
    };
    creator.JSON = { pages: pages };
  });
  
  return (
    <Container className="section my-2">
      <Button
        variant="outline-primary"
        className="my-3"
        onClick={() => setShow(true)}
      >
        Click to open Survey Creator
      </Button>
      <Collapse in={show}>
        {/* <Modal
        show={show}
        dialogClassName="my-modal"
        onHide={() => {
          creator.JSON = { pages: pages };
          console.log("saved");
          setShow(false);
        }}
        centered
      >
        <Modal.Body> */}
        <Container className="text-left">
          <Row className="p-0">
            <Col className="m-0 w-100">
              {/* <div id="creatorElement" className="m-0 w-100" /> */}
              <SurveyCreatorComponent creator={creator} />
            </Col>
          </Row>
        </Container>
      </Collapse>
      {/* </Modal.Body>
      </Modal> */}
    </Container>
  );
});

export default SurveyCreatorComponentCustom;
