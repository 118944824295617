/* eslint-disable */

import React from "react";
import "../../styles/about.css";
import { withRouter } from "react-router-dom";
import "../../styles/profile.css";
import { Container, Row, Col } from "react-bootstrap";
import AccountSettings from "./AccoutSettings";
import ProfileTable from "./ProfileTable";

/* Participant's profile */
class MyProfile extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <AccountSettings
            email={this.props.email}
            history={this.props.history}
            logout={this.props.logout}
          />
        </Row>
        <Row>
          <Col>
            <hr className="mt-2 mb-3" />
            <ProfileTable
              history={this.props.history}
              email={this.props.email}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(MyProfile);
