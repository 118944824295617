import React from "react";
import "./styles/about.css";
import "./styles/link.css";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiLinkExternal, BiLink } from "react-icons/bi";
import { FormattedMessage } from "react-intl";

const Links = () => (
  <Container fluid>
    <Row className="links linkBackground">
      <Col>
        <p className="h5 text-light" style={{ fontSize: "1.5rem" }}>
          <FormattedMessage
            id="Links.title"
            defaultMessage="Links and Resources"
          />
        </p>
      </Col>
    </Row>
    <Row className="links linkBackground text-center">
      <Col md={4} className="text-left px-4 my-2">
        <p className="linkHeading text-muted">
          <FormattedMessage
            id="Links.otherProjects"
            defaultMessage="Other Projects We Work On:"
          />
        </p>
        <a href="http://blog.yourmorals.org/" target="_blank" rel="noreferrer">
          Yourmorals Blog <BiLinkExternal />
        </a>
        <br />
        <a href="https://map.yourmorals.org/" target="_blank" rel="noreferrer">
          MapYourMorals <BiLinkExternal />
        </a>
        <br />
        <a
          href="https://www.ethicalsystems.org/"
          target="_blank"
          rel="noreferrer"
        >
          EthicalSystems.Org <BiLinkExternal />
        </a>
        <br />
        <a href="http://www.psychwiki.com" target="_blank" rel="noreferrer">
          PsychWiki.com <BiLinkExternal />
        </a>
        <br />
        <a
          href="https://constructivedialogue.org/"
          target="_blank"
          rel="noreferrer"
        >
          Constructive Dialogue Institute <BiLinkExternal />
        </a>
        <br />

        <Link className="linkHeading text-info" to="/privacy">
          <FormattedMessage
            id="Links.ymprivacyPolicy"
            defaultMessage="YourMorals Privacy Policy"
          />
        </Link>
      </Col>

      <Col md={5} className="text-left px-4 my-2">
        <p className="linkHeading text-muted">
          <FormattedMessage
            id="Links.press"
            defaultMessage="Press about YourMorals.org:"
          />
        </p>
        <a
          href="http://www.nytimes.com/2007/09/18/science/18mora.html?pagewanted=all"
          target="_blank"
          rel="noreferrer"
        >
          Is Do Unto Others Written into our Genes? - NY Times <BiLink />
        </a>
        <br />
        <a
          href="http://www.nature.com/nature/journal/v447/n7146/full/447768a.html"
          target="_blank"
          rel="noreferrer"
        >
          Moral psychology: The depths of disgust - Nature <BiLink />
        </a>
        <br />
      </Col>

      <Col md={3} className="text-left px-4 my-2">
        <p className="linkHeading text-muted">
          <FormattedMessage
            id="Links.collaborator"
            defaultMessage="Collaborator Websites:"
          />
        </p>
        <a href="http://morteza-dehghani.net/" target="_blank" rel="noreferrer">
          Morteza Dehghani <BiLink />
        </a>
        <br />
        <a
          href="http://socialecology.uci.edu/faculty/phditto/"
          target="_blank"
          rel="noreferrer"
        >
          Pete Ditto <BiLink />
        </a>
        <br />
        <a
          href="https://eccles.utah.edu/team/jesse-graham/"
          target="_blank"
          rel="noreferrer"
        >
          Jesse Graham <BiLink />
        </a>
        <br />
        <a
          href="http://www.jonathanhaidt.com/"
          target="_blank"
          rel="noreferrer"
        >
          Jon Haidt <BiLink />
        </a>
        <br />
        <a href="https://mohammadatari.com/" target="_blank" rel="noreferrer">
          Mohammad Atari <BiLink />
        </a>
        <br />
        <a href="http://www.senakoleva.com/" target="_blank" rel="noreferrer">
          Sena Koleva <BiLink />
        </a>
        <br />
      </Col>
    </Row>
  </Container>
);

export default Links;
