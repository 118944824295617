import { useState,useEffect } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import axiosInstance from "../../axiosInterceptor";
import checkEmail from "../../services/util/checkEmail";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

import "./style.css";

const Login = ({ hide, setTab }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  let history = useHistory();
  const intl = useIntl();
  const messages = defineMessage({
    invalidEmailFormat: {
      id: "Login.invalidFormat",
      defaultMessage: "Invalid email format",
    },
    pwTooShort: {
      id: "Login.pwTooShort",
      defaultMessage: "Password is too short",
    },
    errLogin: {
      id: "Login.errLogin",
      defaultMessage: "error logging in",
    },
    invalidEmailPW: {
      id: "Login.invalidEmailPW",
      defaultMessage: "Invalid password or email",
    },
    emailPrompt: {
      id: "Login.emailPrompt",
      defaultMessage: "Enter email",
    },
    pwPrompt: {
      id: "Login.pwPrompt",
      defaultMessage: "Enter Password",
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setPasswordErrorMessage(null);
    setEmailErrorMessage(null);
    login();
  };

  const checkAndSetEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setPasswordErrorMessage(null);
    if (!checkEmail(email)) {
      setEmailErrorMessage(intl.formatMessage(messages.invalidEmailFormat));
      return;
    }
    setEmailErrorMessage(null);
  };

  const checkAndSetPassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setEmailErrorMessage(null);

    if (password.length < 3) {
      setPasswordErrorMessage(intl.formatMessage(messages.pwTooShort));
      return;
    }
    setPasswordErrorMessage(null);
  };

  const login = async () => {
    const user = {
      email,
      password,
    };
    setLoading(true);
    setPassword("");
    try {
      const res = await axiosInstance().post("auth/login", user);
      const newUserObj = Object.assign({}, res.data);
      newUserObj.email = email;
      localStorage.setItem("user", JSON.stringify(newUserObj));
      const { token, refreshToken } = res.data;

      localStorage.setItem("accessToken", token);
      localStorage.setItem("refreshToken", refreshToken);
      hide();
      if (res.data.role === "participant") setTab(email, "participant");
      else if (res.data.role === "experimenter") {
        setTab(email, "experimenter");
      } else if (res.data.role === "admin") setTab(email, "admin");
      history.push("/");
      // redirect
    } catch (err) {
      console.log(err);
      setEmail("");
      setPassword("");
      if (!err.response || !err.response.data) {
        setEmailErrorMessage(intl.formatMessage(messages.errLogin));
      } else {
        setEmailErrorMessage(err.response.data.message);
      }
      setPasswordErrorMessage(intl.formatMessage(messages.invalidEmailPW));
    }
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      setPasswordErrorMessage(null); 
      setEmailErrorMessage(null);
    };
}, []);

  return (
    <div>
      <Row>
        <Col style={{ fontSize: "1.5rem" }}>
          <FormattedMessage id="Login.title" defaultMessage="Login" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-left">
          <Form>
            <Form.Group className="my-2">
              <Form.Label>
                <FormattedMessage
                  id="Login.email"
                  defaultMessage="Email address"
                />
              </Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Enter email"
                isInvalid={emailErrorMessage}
                onChange={checkAndSetEmail}
              />
              <Form.Control.Feedback type="invalid">
                {emailErrorMessage}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="my-2">
              <Form.Label>
                <FormattedMessage id="Login.pw" defaultMessage="Password" />
              </Form.Label>
              <Form.Control
                required
                value={password}
                type="password"
                placeholder={intl.formatMessage(messages.pwPrompt)}
                isInvalid={passwordErrorMessage}
                onChange={checkAndSetPassword}
              />
              <Form.Control.Feedback type="invalid">
                {passwordErrorMessage}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              onClick={handleSubmit}
              className="w-100 action-button my-2"
              disabled={
                passwordErrorMessage || emailErrorMessage || !password || !email
              }
            >
              {/* {loading && (
                <div>loading */}
                {/* <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-3"
                /> */}
                {/* </div> */}
              {/* )} */}
              <FormattedMessage id="Login.action" defaultMessage="Login" />
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
