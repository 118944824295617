import { Bar } from 'react-chartjs-2';
import React from 'react';

const BarChart = React.memo(({ data }) => {
    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };
    return (
        <>
            <Bar data={data} options={options} />
        </>
    );
});

export default BarChart;
