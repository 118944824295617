import { Container, Col, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import axiosInstance from "../../axiosInterceptor";
import RedirectAlert from "../../components/ConfirmAlert/RedirectAlert";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

const VerifyEmailPage = () => {
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const intl = useIntl();

  const messages = defineMessage({
    alertTitle: {
      id: "Verify.alertTitle",
      defaultMessage: "Email Confirmed!",
    },
    alertContent: {
      id: "Verify.alertContent",
      defaultMessage:
        "You email has been confirmed, please login again on the home page, click OK to redirect",
    },
    alertFailedTitle: {
      id: "Verify.alertFailedTitle",
      defaultMessage: "Email Verification Failed",
    },
    alertFailedContent: {
      id: "Verify.alertFailedContent",
      defaultMessage:
        "YWe couldn't verify your email, please use forgot password to resend a email",
    },
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let id = query.get("token");
        if (!id) {
          throw new Error("Invalid URL");
        }
        const res = await axiosInstance().post("auth/verify/", { token: id });

        if (res.status === 201) {
          setLoading(false);
          confirmAlert({
            customUI: ({ onClose }) => {
              function sleep(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms));
              }
              sleep(5000).then(() => {
                onClose();
                if (history) {
                  history.push("/");
                } else {
                  window.location = "/";
                }
              });
              return (
                <RedirectAlert
                  title={intl.formatMessage(messages.alertTitle)}
                  onClose={() => {
                    onClose();
                    history.push("/");
                  }}
                  text={intl.formatMessage(messages.alertContent)}
                />
              );
            },
          });
        } else {
          throw new Error("failed to verify email");
        }
      } catch (err) {
        console.log(err.message);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <RedirectAlert
                title={intl.formatMessage(messages.alertFailedTitle)}
                onClose={() => {
                  onClose();
                  history.push("/");
                }}
                text={intl.formatMessage(messages.alertFailedContent)}
              />
            );
          },
        });
      }
    };
    fetchData();
  }, [query, history]);

  return (
    <Container>
      {loading && (
        <Row className="mt-5">
          <Col sm={12}>
            <Spinner animation="border" size="xl" />
          </Col>
          <Col sm={12} className="mt-2">
            <p className="h4">
              <FormattedMessage
                id="Verify.loading"
                defaultMessage="Verifying Your Email"
              />
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default withRouter(VerifyEmailPage);
