import { useState } from "react";
import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";
import passwordValidtor from "../../services/util/passwordValidate";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

import sleep from "../../services/util/sleep";
import axiosInstance from "../../axiosInterceptor";

const ResetPasswordPopUp = (props) => {
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [show, setShow] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [responseVariant, setResponseVariant] = useState("danger");
  const [responseMessage, setResponseMessage] = useState(null);
  const handleClose = () => setShow(false);

  const intl = useIntl();
  const messages = defineMessage({
    resetMessage: {
      id: "ResetPW.successMessage",
      defaultMessage: "Password Reset Successfully",
    },
    wrongPSMessage: {
      id: "ResetPW.wrongPW",
      defaultMessage: "The old password is incorrect; try again",
    },
    errorReset: {
      id: "ResetPW.error",
      defaultMessage: "Error resetting the password",
    },
    currentPasswordPlaceholder: {
      id: "ResetPW.currentPWPrompt",
      defaultMessage: "Enter your current password",
    },
    newPasswordPlaceholder: {
      id: "ResetPW.newPWPrompt",
      defaultMessage: "Enter your new password",
    },
    confirmPasswordPlaceholder: {
      id: "ResetPW.confirmPWPrompt",
      defaultMessage: "Confirm your new password",
    },
  });

  const handleShow = () => {
    setShow(true);
    setResponseMessage(null);
    setCurrentPassword(null);
    setNewPassword(null);
    setConfirmPassword(null);
  };

  const checkCurrentPassword = (pw) => {
    setCurrentPassword(pw);
    setCurrentPasswordError(false);
  };

  const checkPassword = (pw) => {
    setNewPassword(pw);
    if (passwordValidtor(pw)) {
      setNewPasswordError(false);
    } else {
      setNewPasswordError(true);
    }
  };

  const checkConfirmPassword = (pw) => {
    setConfirmPassword(pw);

    if (pw !== newPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  const handleSubmit = async () => {
    setResponseMessage(null);

    if (currentPassword === null || currentPassword === "") {
      setCurrentPasswordError(true);
      return;
    } else {
      setCurrentPasswordError(false);
    }
    if (newPassword === null || newPassword === "") {
      setNewPasswordError(true);
      return;
    }
    if (confirmPassword === null || confirmPassword === "") {
      setPasswordMatch(true);
      return;
    }
    setShowSpinner(true);
    try {
      const res = await axiosInstance(props.history).post(
        "auth/reset_with_password",
        {
          newPassword: newPassword,
          oldPassword: currentPassword,
        }
      );

      if (res.status === 201) {
        setResponseVariant("success");
        setResponseMessage(intl.formatMessage(messages.resetMessage));
        await sleep(1000);
        setShow(false);
      }
    } catch (err) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.status === 401
      ) {
        setResponseMessage(intl.formatMessage(messages.wrongPSMessage));
      } else {
        setResponseMessage(messages.errorReset);
      }
      setResponseVariant("danger");
    }
    setShowSpinner(false);
  };

  return (
    <>
      <Button variant="outline-success" onClick={handleShow} className="w-100">
        Reset Password
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title className="text-success">
              <FormattedMessage
                id="ResetPW.title"
                defaultMessage="Reset your password"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="mb-3">
                <FormattedMessage
                  id="ResetPW.currentPW"
                  defaultMessage="Please enter your current password"
                />
              </Form.Label>
              <Form.Control
                type="password"
                placeholder={intl.formatMessage(
                  messages.currentPasswordPlaceholder
                )}
                onChange={(e) => checkCurrentPassword(e.target.value)}
                isInvalid={currentPasswordError}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="mb-3">
                <FormattedMessage
                  id="ResetPW.newPW"
                  defaultMessage="Please enter your new password"
                />
              </Form.Label>
              <Form.Control
                type="password"
                placeholder={intl.formatMessage(
                  messages.newPasswordPlaceholder
                )}
                onChange={(e) => checkPassword(e.target.value)}
                isInvalid={newPasswordError}
              />
              <Form.Text id="passwordHelpBlock" muted>
                <FormattedMessage
                  id="ResetPW.PWrequirement"
                  defaultMessage="Your password must be 8-20 characters long and contain letters
                and numbers, including one uppercase letter."
                />
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="mb-3">
                <FormattedMessage
                  id="ResetPW.confirmPW"
                  defaultMessage="Please confirm your new password"
                />
              </Form.Label>
              <Form.Control
                type="password"
                placeholder={intl.formatMessage(
                  messages.confirmPasswordPlaceholder
                )}
                onChange={(e) => checkConfirmPassword(e.target.value)}
                isInvalid={passwordMatch}
              />
              <Form.Control.Feedback type="invalid">
                Your password does not match.
                <FormattedMessage
                  id="ResetPW.PWNotMatch"
                  defaultMessage="Your password does not match."
                />
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                <FormattedMessage
                  id="ResetPW.PWNotSame"
                  defaultMessage="The confirmed password should be the same."
                />
              </Form.Text>
            </Form.Group>
            {responseMessage && (
              <Alert variant={responseVariant}>{responseMessage}</Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-dark"
              onClick={handleClose}
              className="mr-auto"
            >
              <FormattedMessage id="ResetPW.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="outline-success"
              onClick={handleSubmit}
              disabled={passwordMatch || newPasswordError || showSpinner}
            >
              {showSpinner && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              <FormattedMessage
                id="ResetPW.action"
                defaultMessage="Reset my password"
              />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ResetPasswordPopUp;
