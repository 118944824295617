import { useEffect } from "react";
import { Container, Col, Row, Button, Card } from "react-bootstrap";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

import "./BugReport.css";

const UploadImage = ({ file, setFile, description, maxImg }) => {

  function uploadSingleFile(e) {
    setFile([...file, e.target.files[0]]);
  }
  useEffect(()=>{
    console.log("file length:"+file.length);
  },[file])

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
    console.log(s);
  }

  return (
    <Container>
      <Row>
        <Col>
          <label className="custom-file-upload">
            <input
              type="file"
              accept="image/*,application/pdf"
              // disabled={file.length === 5}
              disabled={file.length === {maxImg}}
              className="form-control"
              onChange={uploadSingleFile}
            />
            <FormattedMessage
              id="Report.upload"
              // defaultMessage=" Upload at most 3 images"
              defaultMessage = {description}
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container className="form-group preview">
            <Row>
              {file.length > 0 &&
                file.map((item, index) => {
                  return maxImg !== 1 ? (
                    <Col xs={4} className="mx-2 my-2">
                      <Card className="survey-list-card">
                        <Card.Img
                          variant="top"
                          src={URL.createObjectURL(item)}
                        />
                        <Card.Footer className="btn-pos">
                          <Button
                            variant="outline-info"
                            onClick={() => deleteFile(index)}
                          >
                            <FormattedMessage
                              id="Report.remove"
                              defaultMessage="Remove"
                            />
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ) : (
                    <Button
                      variant="outline-info"
                      onClick={() => deleteFile(index)}
                    >
                      {" "}
                      <FormattedMessage
                        id="Report.remove"
                        defaultMessage="Remove"
                      />
                    </Button>
                  );
                })}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadImage;
