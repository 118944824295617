import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const labels = ['users'];


const BarChart = React.memo(({ data }) => {

    let total = data.length;

    // console.time("graph")
   let numAccept = data.filter(user=>user.consentAccepted === true).length;
//    let numAnswerReq = data.filter(user=>user.completed_surveys.length === 3).length;
//    let numAnswerOpt = data.filter(user=>user.completed_surveys.length === 5).length;
   let numDelete = data.filter(user=>user.deletedAccount).length;
   let numFrUser = data.filter(user=>user.country === "FR").length;
//    console.log("delete acc: "+numDelete)
//    console.timeEnd("graph")

   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Incas Users Statistic',
      },
    },
  };

    const data_chart = {
        labels,
        datasets: [
          {
            label: 'Accept consent',
            data: labels.map(() => numAccept),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: 'Without consent',
            data: labels.map(() => total-numAccept),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Delete Account',
            data: labels.map(() => numDelete),
            borderColor: 'rgb(154, 153, 171)',
            backgroundColor: 'rgba(154, 153, 171, 0.5)',
          },
        //   {
        //     label: 'Required survey Completed',
        //     data: labels.map(() => numAnswerReq),
        //     borderColor: 'rgb(13, 62, 235)',
        //     backgroundColor: 'rgba(13, 62, 235, 0.5)',
        //   },
        //   {
        //     label: 'All survey Completed',
        //     data: labels.map(() => numAnswerOpt),
        //     borderColor: 'rgb(75, 192, 192)',
        //     backgroundColor: 'rgba(75, 192, 192, 0.5)',
        //   },
          {
            label: 'IP from France',
            data: labels.map(() => numFrUser),
            borderColor: 'rgb(74, 140, 243)',
            backgroundColor: 'rgba(74, 140, 243, 0.5)',
          },
          {
            label: 'Total Users',
            data: labels.map(() => total),
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
          },
          {
            label: 'Users in Fall 2022 ',
            data: labels.map(() => total? total-754 : 0),
            borderColor: 'rgb(25, 192, 122)',
            backgroundColor: 'rgba(75, 192, 122, 0.5)',
          },
        ],
      };
    //   console.log("horbar data:"+JSON.stringify(data))
    return (
        <>
            <Bar data={data_chart} options={options}  redraw={true} />
        </>
    );
});

export default BarChart;
