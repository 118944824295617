import { useState } from 'react';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import { FormattedMessage } from 'react-intl';

import './style.css';

const AuthPanel = ({ name, hide, setTab }) => {
    const [panel, setPanel] = useState(name ? name : 'login');

    const displayPanel = () => {
        if (panel === 'login') {
            return <Login hide={hide} setTab={setTab}/>;
        } else if (panel === 'signup') {
            return <Register hide={hide} />;
        } else if (panel === 'forgotPassword') {
            return <ForgotPassword hide={hide} />;
        } else {
            return <Spinner></Spinner>;
        }
    };

    const getButton = () => {
        if (panel === 'login') {
            return (
                <Button
                    className="w-100 switch-button"
                    onClick={() => setPanel('signup')}
                >
                    <FormattedMessage
                        id="Auth.register"
                        defaultMessage="Register"
                    />
                </Button>
            );
        } else if (panel === 'signup') {
            return (
                <Button
                    className="w-100 switch-button"
                    onClick={() => setPanel('login')}
                >
                    <FormattedMessage id="Auth.login" defaultMessage="Login" />
                </Button>
            );
        } else if (panel === 'forgotPassword') {
            return (
                <Button
                    className="w-100 switch-button"
                    onClick={() => setPanel('login')}
                >
                    <FormattedMessage
                        id="Auth.back"
                        defaultMessage="Back to login"
                    />
                </Button>
            );
        } else {
            return <Spinner></Spinner>;
        }
    };

    return (
        <Container>
            {
                <div className='translate'>
                    {displayPanel()}
                </div>
                
            }
            {panel !== 'forgotPassword' && (
                <Row className="mt-1">
                    <Col>
                        <p
                            className="forgot"
                            onClick={() => setPanel('forgotPassword')}
                        >
                            <FormattedMessage
                                id="Auth.forgotPassword"
                                defaultMessage="Forgot Password?"
                            />
                        </p>
                    </Col>
                </Row>
            )}
            <Row className="mt-1">
                <Col>{getButton()}</Col>
            </Row>
        </Container>
    );
};

export default AuthPanel;
