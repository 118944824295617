import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ProgressBar,
  Modal,
} from "react-bootstrap";

import { confirmAlert } from 'react-confirm-alert'; // Import
import { useHistory } from "react-router";
import axiosInstance from "../../../axiosInterceptor";

const ManageSurveyModal = ({
  show,
  onHide,
  survey,
  update,
  setUpdate,
  role,
}) => {
  const { status, name, description, percentage, id, type } = survey;
  let history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState(null);
  const [modalContent, setModalContent] = useState({
    title: "this is the title",
    description: "this is the description",
  });

  const surveyAction = async (action) => {
    if (action === "download") {
      try {
        axiosInstance().post("admin/downloadExp", {
          surveysRequired: [survey.id],
        });
        confirmAlert({
          title: 'Got it!',
          message: 'We\'ll send an email with download link soon!',
          buttons: [
            {
              label: 'Yes'
            }
          ]
        });
        setUpdate(Math.random());
      } catch (err) {}
      handleConfirmClose();
      onHide();
      return;
    }

    try {
      let url = "admin/survey-action/" + id;
      if (role === "experimenter") {
        url = "admin/experimenter/survey-action/" + id;
      }
      await axiosInstance().post(url, {
        action: action,
      });
      setUpdate(Math.random());
    } catch (err) {}
    handleConfirmClose();
    onHide();
  };
  useEffect(() => {
    if (status === "created" || status === "stopped") {
      setAction("launch");
    }

    if (survey.status === "launched") {
      setAction("stop");
    }
  }, [status]);
  const handleConfirmClose = () => setShowConfirm(false);
  const handleConfirmShow = () => setShowConfirm(true);

  const getButton = () => {
    if (status === "created" || status === "stopped") {
      return (
        <Button
          className="w-50"
          variant="outline-success"
          onClick={() => {
            setAction("launch");
            setModalContent({
              title: `Please confirm your action to launch the survey`,
              description: `You can choose to start or stop a survey after you made this change`,
            });
            handleConfirmShow();
          }}
        >
          Start
        </Button>
      );
    } else if (status === "launched") {
      return (
        <Button
          className="w-50"
          variant="outline-danger"
          onClick={() => {
            setAction("stop");
            setModalContent({
              title: `Please confirm your action to stop the survey`,
              description: `You can choose to start or stop a survey after you made this change`,
            });
            handleConfirmShow();
          }}
        >
          Stop
        </Button>
      );
    } else {
      return (
        <Button className="w-50" disabled variant="outline-warning">
          Completed
        </Button>
      );
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={showConfirm}
        onHide={handleConfirmClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent.description}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => surveyAction(action)}>
            Understood
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Body>
          <Container fluid className="mx-2 p-1 text-left">
            <Row>
              <Col>
                <p className="h5">Survey Info</p>
              </Col>
            </Row>
            <Row>
              <Col className="mt-1 h6 mb-0">
                <p>Name:</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{name}</p>
              </Col>
            </Row>
            <Row>
              <Col className="mt-1 h6 mb-0">
                <p>Description:</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{description}</p>
              </Col>
            </Row>
            {survey.type !== "general" ? (
              <React.Fragment>
                <Row>
                  <Col className="mt-1 h6 mb-0">
                    <p>Progress:</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <ProgressBar
                      now={percentage * 100}
                      label={`${parseFloat(percentage * 100).toFixed(0)}%`}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Row>
                <Col>{`Total Participants: ${survey.totalParticipants}`}</Col>
              </Row>
            )}

            <Row>
              <Col className="mt-4 h6 mb-0">
                <p>Actions:</p>
              </Col>
            </Row>

            <Row>
              <Col xs={6} className="d-flex justify-content-center">
                {getButton()}
              </Col>
              <Col xs={6} className="d-flex justify-content-center">
                <Button
                  className="w-50"
                  variant="outline-danger"
                  onClick={() => history.push("/edit/" + id, { type: type })}
                >
                  Edit
                </Button>
              </Col>
              {role === "experimenter"  &&
              (<Col xs={6} className="d-flex justify-content-center pt-2">
                <Button
                    className="w-50"
                    variant="outline-info"
                    onClick={() => surveyAction("download")}
                  >
                  Download
                </Button>
              </Col>)}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ManageSurveyModal;
