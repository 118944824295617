import passwordValidator from "password-validator";

const schema = new passwordValidator();
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(20) // Maximum length 20
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .digits(1) // Must have at least 1 digits
  .has()
  .not()
  .spaces(); // Should not have spaces

const passwordValidtor = (password) => {
  const res = schema.validate(password, { list: true });
  const stringRes = res.map((r) => {
    if (r === "min") {
      return "Must be at least 8 characters";
    }
    if (r === "max") {
      return "Must have no more than 20 characters";
    }
    if (r === "uppercase") {
      return "Must contain at least 1 uppercase character";
    }
    if (r === "digits") {
      return "Must contain at least 1 digit";
    }
    return r;
  });
  return stringRes;
};

export default passwordValidtor;
