import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import axiosInstance from "../../../axiosInterceptor";
import ReportDetailModal from "./ReportDetailModal";
import ReportEntry from "./ReportEntry";

import styles from "../Admin.module.css";

const ReportTable = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const fetchReportData = useCallback(async () => {
    try {
      const reports = await axiosInstance().get("report/all-reports");
      const r = reports.data.reports.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setReports(r);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchReportData();
  }, []);

  if (setReports.length === 0 && !loading) {
    return <Container>No Bug Reports</Container>;
  }
  return (
    <Container className={styles.backgroundSection + " py-2"}>
      {reports.map((r) => (
        <ReportEntry
          id={r._id}
          key={r._id}
          email={r.email}
          time={new Date(r.createdAt)}
          setId={setSelectedId}
          setModalShow={setModalShow}
          resolved={r.resolved}
          fetchReportData={fetchReportData}
        />
      ))}
      <ReportDetailModal
        id={selectedId}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Container>
  );
};

export default ReportTable;
