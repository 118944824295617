import { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import axiosInstance from '../../../axiosInterceptor';
import LineChart from '../Usage/LineChart';
import PieChart from '../Usage/PieChart';

import styles from '../Admin.module.css';

const DetailChart = () => {
    const [data, setData] = useState({
        labels: [],
        datasets: []
    });

    const [disabled, setDisabled] = useState(false);
    const [externalLabel, setExternalLabel] = useState([]);
    const [allSurveyIds, setAllSurveyIds] = useState([]);
    const [selectedSurveys, setSelectedSurveys] = useState([]);
    const [externalData, setExternalData] = useState({});

    useEffect(() => {
        setAllSurveyIds([
            { id: 'mfq', name: 'mfq' },
            { id: 'incas', name: 'incas' },
            { id: 'incas_demo', name: 'incas_demo' },
            { id: 'media_diet', name: 'media_diet' },
            { id: 'elaborate', name: 'elaborate' },
            { id: 'SVS57Fr', name: 'SVS57Fr' }
        ]);
        getSurveyData('mfq');
    }, []);
    const randomRGBA = () => {
        var o = Math.round,
            r = Math.random,
            s = 255;

        const rgb =
            'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',';

        return [rgb + '0.2)', rgb + '1)'];
    };

    const getSurveyData = async (surveyId) => {
        try {
            const resp = await axiosInstance().get(
                'admin/incas-survey-usage?days=90&surveyId=' +
                    surveyId +
                    '&researchType=incas'
            );
            const newData = { ...data };
            const results = resp.data.results;
            console.log("results:"+JSON.stringify(results))

            const dataset = {};
            dataset.fill = true;
            const random = randomRGBA();
            dataset.backgroundColor = random[0];
            dataset.borderColor = random[1];
            setExternalLabel(results.map((r) => r.date));
            newData.labels = results
                .map((r) => r.date)
                .slice(results.length - usageDays);
            dataset.label = surveyId;
            const tmp = { ...externalData };
            tmp[surveyId] = results.map((r) => r.answers);
            setExternalData(tmp);

            dataset.data = results
                .map((r) => r.answers)
                .slice(results.length - usageDays);
            if (!newData.datasets) {
                newData.datasets = [];
            }
            newData.datasets.push(dataset);
            setData(newData);
        } catch (err) {}
    };

    const [usageDays, setUsageDays] = useState(7);

    useEffect(() => {
        const newData = { ...data };
        let interval = externalLabel.length - usageDays;
        let diff = Math.floor((Date.parse(externalLabel[externalLabel.length-1]) - Date.parse(externalLabel[0])) / 86400000)
        if(interval>=0){
            newData.labels = externalLabel.slice(interval);
        }else{
            newData.labels = [...externalLabel]
            // newData.data = {...externalLabel}
        }
        for (let i = 0; i < newData.datasets.length; i++) {
            newData.datasets[i].data = externalData[
                newData.datasets[i].label
            ].slice(interval>=0 ? externalData[newData.datasets[i].label].length - usageDays: 0);
        }
        setData(newData);
    }, [usageDays]);
    const onSelectedSurvey = async (e) => {
        const surveyId = e.target.value;
        if (surveyId === '' || selectedSurveys.includes(surveyId)) {
            return;
        }
        setDisabled(true);

        setSelectedSurveys([...selectedSurveys, surveyId]);
        await getSurveyData(surveyId);
        setDisabled(false);
    };

    return (
        <Container className={' p-2'}>
            <Row>
                <Col>
                    <Form.Group key="1">
                        <Form.Label>Select the survey to show</Form.Label>
                        <Form.Control
                            disabled={disabled}
                            as="select"
                            onChange={onSelectedSurvey}
                        >
                            {/* <option key="-1"> </option> */}
                            {allSurveyIds.map((a) => (
                                <option value={a.id} key={a.id}>
                                    {a.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group key="2">
                        <Form.Label>
                            Range: The Past {usageDays} days
                        </Form.Label>
                        <Form.Control
                            type="range"
                            min={0}
                            max={90}
                            value={usageDays}
                            variant="secondary"
                            onChange={(e) => {
                                setUsageDays(e.target.value);
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs={8}>
                    <LineChart data={data} />
                </Col>
                <Col xs={4}>
                    <Row>
                        <Col className="h6">The last day's data</Col>
                    </Row>
                    <Row>
                        <Col>
                            <PieChart data={data} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default DetailChart;
