import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import axiosInstance from '../../axiosInterceptor';
import UploadImage from './UploadImage';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl, defineMessage } from 'react-intl';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FAQ from './FAQ';

const BugReportPage = () => {
    const [email, setEmail] = useState(null);
    const [selectedOption, setSelectedOption] = useState("bug");
    const [title, setTitle] = useState(null);
    const [titleErrorMessage, setTitleErrorMessage] = useState(null);
    const [emailErrorMessage, setEmailErrorMessage] = useState(null);
    const [isVerified, setIsVerifed] = useState(false);
    const [descriptionErrorMessage, setDescriptionErrorMessage] =
        useState(null);
    const [file, setFile] = useState([]);
    let history = useHistory();
    const intl = useIntl();
    const messages = defineMessage({
        invalidTitleFormat: {
            id: 'Report.invalidTitleFormat',
            defaultMessage: 'Missing Title'
        },
        invalidEmailFormat: {
            id: 'Report.invalidEmailFormat',
            defaultMessage: 'Invalid Email Format'
        },
        desTooShort: {
            id: 'Report.desTooShort',
            defaultMessage: 'Description too short'
        }
    });
    const labelOptions = [
        {value:'bug',label:"Bug"},
        {value:'enhancement',label:"Enhancement"},
        {value:'survey-suggestion',label:"Survey Suggestion"}
        ]

    const keyValue = (input) => Object.entries(input).forEach(([key,value]) => {
        console.log(key,value)
      })
    const animatedComponents = makeAnimated();

    useEffect(() => {
        console.log(" useEffect file:");
        // keyValue(file)
    }, [file]);

    const [description, setDescription] = useState('');
    const onReCAPTCHAVerified = () => {
        setIsVerifed(true);
    };
    const setAndCheckTitle = (e) => {
        setTitleErrorMessage(null);
        setTitle(e.target.value);
        if (e.target.value.length<1) {
            setTitleErrorMessage(
                intl.formatMessage(messages.invalidTitleFormat)
            );
        }
    };

    const setAndCheckEmail = (e) => {
        setEmailErrorMessage(null);
        setEmail(e.target.value);
        if (!EmailValidator.validate(e.target.value)) {
            setEmailErrorMessage(
                intl.formatMessage(messages.invalidEmailFormat)
            );
        }
    };

    const setAndCheckDescription = (e) => {
        setDescriptionErrorMessage(null);
        setDescription(e.target.value);
        if (description.length < 50) {
            setDescriptionErrorMessage(
                intl.formatMessage(messages.desTooShort)
            );
        }
    };

    const handleLabel = (option) => {
        setSelectedOption(option.value);
        // console.log("label:"+JSON.stringify(option))
      };

    const onSubmit = async () => {
        // console.log("selectedOption:"+JSON.stringify(selectedOption))
        setTitleErrorMessage('')
        setEmailErrorMessage('');
        setDescriptionErrorMessage('');

        if (!EmailValidator.validate(email)) {
            setEmailErrorMessage(
                intl.formatMessage(messages.invalidEmailFormat)
            );
            return;
        }
        if (description.length < 50) {
            setDescriptionErrorMessage(
                intl.formatMessage(messages.desTooShort)
            );
            return;
        }
        if (title.length<3) {
            setTitleErrorMessage(
                intl.formatMessage(messages.invalidTitleFormat)
            );
            return
        }
        const urls = [];
        try {
            for (let f of file) {
                const uploadConfig = await axiosInstance().post(
                    'report/upload',
                    {
                        name: f.name,
                        type: f.type
                    }
                );
                // console.log("uploadConfig:"+JSON.stringify(uploadConfig));
                // console.log(keyValue(f));
                urls.push(uploadConfig.data.key);
                await axios.put(uploadConfig.data.url, f, {
                    headers: {
                        'Content-Type': f.type
                    }
                });
            }
            await axiosInstance().post('report/add-report', {
                title:title,
                email: email,
                label:selectedOption,
                description: description,
                urls: urls
            });
        } catch (err) {
            console.log(err);
        } finally {
            setTitle('');
            setEmail('');
            setSelectedOption('')
            setDescription('');
            setFile([]);
            setIsVerifed(false);
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Card style={{ width: '25rem' }} className="confirmCard">
                        <Card.Body>
                             <Card.Title>
                             Thank you
                                {/* <FormattedMessage
                                    id="Report.thankyouTitle"
                                    defaultMessage="Thank you"
                                /> */}
                            </Card.Title>
                           <Card.Text>
                           We have received your feedback, thank you very much
                                {/* <FormattedMessage
                                    id="Report.thankyouContent"
                                    defaultMessage="We have received your feedback, thank you very much"
                                /> */}
                            </Card.Text>  
                            <Container fluid>
                                <Row>
                                    <Col xs={6} className="text-left">
                                        <Button
                                            variant="outline-success"
                                            className="w-100"
                                            onClick={() => {
                                                if (history) {
                                                    history.push('/');
                                                } else {
                                                    window.location = '/';
                                                }
                                                onClose();
                                            }}
                                        >
                                            OK
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                );
            }
        });
        function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
        sleep(3000).then(() => {
            if (history) {
                history.push('/');
            } else {
                window.location = '/';
            }
        });
    };
    return (
        <Container>
            <Row>
                <Col>
                    <h4 className="h4">
                        <FormattedMessage
                            id="Report.title"
                            defaultMessage="Bug Report Page"
                        />
                    </h4>
                </Col>
            </Row>
            <FAQ/>
            <Row>
                <Col>
                    <Form>
                        <Form.Group
                            controlId="formBasicEmail"
                            className="text-left mt-2"
                        >
                            <Form.Label>
                                <FormattedMessage
                                    id="Report.emailPrompt"
                                    defaultMessage="Please enter your email below to confirm:"
                                />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={email||""}
                                isInvalid={emailErrorMessage}
                                placeholder="Enter your email here"
                                onChange={setAndCheckEmail}
                            />
                            <Form.Control.Feedback type="invalid">
                                {emailErrorMessage}
                            </Form.Control.Feedback>

                            <Form.Label>
                                <FormattedMessage
                                    id="Report.emailPrompt"
                                    defaultMessage="Please select type of report:"
                                />
                            </Form.Label>
                            <Select
                                // closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[labelOptions[0]]}
                                // isMulti
                                // value = {selectedOption||labelOptions[0]}
                                options={labelOptions}
                                onChange={handleLabel}
                            />
                            


                            <Form.Label>
                                <FormattedMessage
                                    id="Report.titlePrompt"
                                    defaultMessage="Please enter the report title below to confirm:"
                                />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={title||""}
                                isInvalid={titleErrorMessage}
                                placeholder="Enter the title here"
                                onChange={setAndCheckTitle}
                            />
                            <Form.Control.Feedback type="invalid">
                                {titleErrorMessage}
                            </Form.Control.Feedback>

                            <Form.Label className="mt-3">
                                <FormattedMessage
                                    id="Report.desPrompt"
                                    defaultMessage="Please describe the bug you found: (no less than 50 characters)"
                                />
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                isInvalid={descriptionErrorMessage}
                                placeholder="1: Environment(your browser, operating system, device)&#10;2: Steps to reproduce the bug&#10;3: Resulting and expected behaviour&#10;4: Screenshot if applicable
                                "
                                onChange={setAndCheckDescription}
                            />
                            <Form.Control.Feedback type="invalid">
                                {descriptionErrorMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <UploadImage file={file} setFile={setFile} description={"Upload at most 3 images"} maxImg={5} />
                </Col>
            </Row>
            <Row>
                <Col
                    xs={{ span: 6, offset: 3 }}
                    className="d-flex justify-content-center"
                >
                    <ReCAPTCHA
                        sitekey="6LcNyPAbAAAAAMvXbdsIOkMvxGATbhG5BvAYLsSj"
                        onChange={onReCAPTCHAVerified}
                    />
                </Col>
            </Row>
            <Row className="mt-4 mb-4">
                <Col>
                    <Button
                        variant="outline-success"
                        size="lg"
                        disabled={
                            !isVerified ||
                            !email ||
                            !title ||
                            !description ||
                            emailErrorMessage ||
                            descriptionErrorMessage
                        }
                        onClick={onSubmit}
                    >
                        <FormattedMessage
                            id="Report.action"
                            defaultMessage="Submit bug report"
                        />
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default BugReportPage;
