import { Button, Modal, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import axiosInstance from "../../axiosInterceptor";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

const DeleteDataPopUp = ({ email, logout, history, type }) => {
  const [show, setShow] = useState(false);
  const [emailNotMatch, setEmailNotMatch] = useState(false);
  const [disable, setDisable] = useState(true);
  const [config, setConfig] = useState({});
  const route =
    type === "with-credentials"
      ? "user/delete-data-credentials"
      : "user/delete-data";

  const intl = useIntl();
  const messages = defineMessage({
    title1: {
      id: "Delete.title1",
      defaultMessage: "Delete your data and your credentials",
    },
    title2: {
      id: "Delete.title2",
      defaultMessage: "Delete your data",
    },
    confirm1: {
      id: "Delete.confirm1",
      defaultMessage:
        "By confirming, your account data and responses to surveys will be permanently deleted",
    },
    confirm2: {
      id: "Delete.confirm2",
      defaultMessage:
        "By confirming, your responses to surveys will be permanently deleted",
    },
    buttonText1: {
      id: "Delete.buttonText1",
      defaultMessage: "Delete my data and account",
    },
    buttonText2: {
      id: "Delete.buttonText2",
      defaultMessage: "Delete only my data",
    },
    emailPlaceHolder: {
      id: "Delete.emailPlaceholder",
      defaultMessage: "Enter your email here",
    },
  });

  useEffect(() => {
    if (type === "with-credentials") {
      setConfig({
        title: intl.formatMessage(messages.title1),
        confirm: intl.formatMessage(messages.confirm1),
        buttonText: intl.formatMessage(messages.buttonText1),
      });
    } else {
      setConfig({
        title: intl.formatMessage(messages.title2),
        confirm: intl.formatMessage(messages.confirm2),
        buttonText: intl.formatMessage(messages.buttonText2),
      });
    }
  }, [type]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setDisable(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "with-credentials") {
      axiosInstance(history)
        .delete(route)
        .then((res) => {
          setShow(false);
          logout();
          history.push({
            pathname: "/",
          });
        })
        .catch((err) => {
          console.log("error");
        });
    } else {
      axiosInstance(history)
        .delete(route)
        .then((res) => {
          setShow(false);
          history.push({
            pathname: "/",
          });
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };

  const handleInput = (e) => {
    if (e.target.value.toLowerCase() !== email.toLowerCase()) {
      setEmailNotMatch(true);
    } else {
      setEmailNotMatch(false);
      setDisable(false);
    }
  };

  return (
    <>
      <Button variant="outline-danger" onClick={handleShow} className="w-100">
        {config.buttonText}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">{config.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                <FormattedMessage
                  id="Delete.emailPrompt"
                  defaultMessage="Please enter your email below to confirm:"
                />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={intl.formatMessage(messages.emailPlaceHolder)}
                onChange={handleInput}
                isInvalid={emailNotMatch}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="Delete.emailWrong"
                  defaultMessage="Your email does not match."
                />
              </Form.Control.Feedback>
              <Form.Text className="text-muted">{config.confirm}</Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-dark"
              onClick={handleClose}
              className="mr-auto"
            >
              <FormattedMessage id="Delete.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="outline-danger"
              onClick={handleSubmit}
              disabled={disable}
            >
              <FormattedMessage
                id="Delete.action"
                defaultMessage="Understand and delete my data"
              />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteDataPopUp;
