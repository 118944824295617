import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import axiosInstance from "../../axiosInterceptor";
import ReportTable from "./BugReports/ReportTable";
import DownloadData from "./DownloadData/DownloadData";
import ManageSurveyList from "./ManageSurveyListItem/ManageSurveyList";
import { useHistory } from "react-router";

const Experimenter = () => {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [surveys, setSurveys] = useState([]);
  const [update, setUpdate] = useState(1);

  const fetchData = useCallback(async () => {
    try {
      const resp = await axiosInstance().get("auth/created-surveys");
      if (resp.data.created_surveys) {
        setSurveys(resp.data.created_surveys);
        // console.log("resp.data:"+JSON.stringify(resp.data));
        // console.log("resp.data.created_surveys:"+resp.data.created_surveys);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [update]);

  return (
    <Container>
      <Row></Row>
      <Row>
        <Col xs={6} className="d-grid">
          <Button
            variant="outline-success"
            onClick={() => history.push("/create", { type: "general" })}
          >
            Create Survey
          </Button>
        </Col>
        <Col xs={6} className="d-grid">
          <Button
            variant="outline-primary"
            onClick={() => history.push("/create", { type: "experiment" })}
          >
            Create Experiment
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ManageSurveyList
            role={"experimenter"}
            allSurveys={surveys}
            setAllSurveys={setSurveys}
            update={update}
            setUpdate={setUpdate}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Experimenter;
