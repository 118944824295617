import { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import axiosInstance from '../../../axiosInterceptor';
import LineChart from './LineChart';
import styles from '../Admin.module.css';

let externalData = null;

const UserChart = () => {
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: '# of New Users',
                data: [],
                fill: true,
                backgroundColor: 'rgb(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132)'
            }
        ]
    });

    const [userUsageDays, setUserUsageDays] = useState(7);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setDisable(true);
            if (!externalData) {
                const resp = await axiosInstance().get(
                    'admin/user-usage/' + 90
                );
                externalData = resp.data.results;
            }

            setData((data) => {
                const newData = { ...data };
                newData.labels = externalData
                    .map((r) => r.date)
                    .slice(Math.max(externalData.length - userUsageDays, 0));
                newData.datasets[0].data = externalData
                    .map((r) => r.users)
                    .slice(Math.max(externalData.length - userUsageDays, 0));
                return newData;
            });
            setDisable(false);
        };
        fetchData();
    }, [userUsageDays]);

    return (
        <Container className={styles.chart + ' p-2'}>
            <Row>
                <Col>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Range: The Past {userUsageDays} days
                            </Form.Label>
                            <Form.Control
                                disabled={disable}
                                type="range"
                                min={0}
                                max={90}
                                value={userUsageDays}
                                variant="secondary"
                                onChange={(e) => {
                                    setUserUsageDays(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <LineChart data={data} />
                </Col>
            </Row>
        </Container>
    );
};

export default UserChart;
