import { useState, useEffect, useRef } from "react";
import axiosInstance from "../../axiosInterceptor";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams, withRouter } from "react-router-dom";
import TitleAndDebrief from "./TitleAndDebrief";
import ResultAnalytics from "./ResultAnalytics";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const SurveyResult = () => {
  const debriefRef = useRef();
  const chartRef = useRef();

  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [analyticsResponse, setAnalyticsResponse] = useState(null);
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);

  useEffect(() => {
    const getSurvey = async () => {
      try {
        const res = await axiosInstance().get("survey/" + id);
        setSurvey(res.data.survey);
        console.log("survey/id", res.data.survey);
      } catch (err) {
        console.log(err);
      }
    };

    const getAnalyticsQuestions = async () => {
      try {
        const res = await axiosInstance().get(
          "survey/analyticsResponses/" + id
        );
        setMax(res.data.max);
        setMin(res.data.min);
        setAnalyticsResponse(res.data.response[0]);
        console.log({res})
      } catch (err) {}
    };
    getSurvey();
    getAnalyticsQuestions();
  }, [id]);

  const exportToPDF = async () => {
    const input = chartRef.current;
    const canvas = await html2canvas(input, { scrollY: -window.scrollY });
    const imgWidth = 500;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const imgData = canvas.toDataURL("img/png");

    const pdf = new jsPDF("p", "pt", "a4");
    debriefRef.current.style.fontSize = "11px";
    debriefRef.current.style.width = "500px";

    pdf.html(debriefRef.current, {
      callback: function (pdf) {
        debriefRef.current.style.fontSize = "";
        debriefRef.current.style.width = "";
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 40, 70, imgWidth, imgHeight, "center");
        pdf.save("Results.pdf");
      },
      x: 20,
      y: 10,
    });
  };

  return (
    <div className="col-md-8 mx-auto">
      {survey && (
        <Container fluid className="mt-5">
          <Row>
            <Col ref={debriefRef}>
              <TitleAndDebrief survey={survey} />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }}>
              {analyticsResponse && (
                <ResultAnalytics
                  max={max}
                  min={min}
                  response={analyticsResponse}
                  id={id}
                  domRef={chartRef}
                  exportToPDF={exportToPDF}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default withRouter(SurveyResult);
