import * as React from "react";
import { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInterceptor";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const AdminTable = ({mEmail, signal}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getAdminExpUsers();
  }, []);

  useEffect(() => {
    getAdminExpUsers();
  }, [signal]);

  const getAdminExpUsers = async () => {
    try {
      const resp = await axiosInstance().get("admin/getAdminExoUsers");
      //   console.log(resp.data)
      //   console.log(JSON.stringify(resp))
      //   for(let x of resp.data){
      //     console.log("getAdminExpUsers:"+x.email)
      //   }
      // role:resp.data.role,
      // email:resp.data.email,
      // name:resp.data.name
      const newRows = { ...resp.data };
      // console.log(JSON.stringify(newRows))
      setRows(resp.data);
    } catch (err) {
      console.log("getAdminExpUsers error!!");
    }
  };

  // const deleteUser = (id) => {
  //       console.log("id"+id)
  //       setRows((prevRows) => {
  //         console.log("prevRows"+prevRows)
  //         prevRows.filter((row) => row.id !== id);
  //       });
  //     }

  const handleClick = (event, cellValues) => {
    let newdata = rows.filter((row) => row._id !== cellValues);
    // console.log(newdata);
    setRows(newdata);
  };

  const columns = [
    {
      field: "email",
      headerName: "Email",
      type: "string",
      minWidth: 210,
      flex: 4,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 70,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      tyepe: "date",
      minWidth: 120,
      flex: 3,
    },

    // { field: 'id', headerName: 'ID', width: 70 },
    // { field: 'lastName', headerName: 'Last name', width: 130 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // }
    // ,
    // {
    //     field: "Operation",
    //     renderCell: (cellValues) => {
    //       return (
    //         <Button
    //           variant="contained"
    //           color="primary"
    //         //   disabled='true'
    //           onClick={(event) => {
    //             handleClick(event, cellValues.id);
    //           }}
    //         // onClick={deleteUser(cellValues.id)}
    //         >
    //           Demote
    //         </Button>
    //       );
    //     }
    //   },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      marginY="20px"
    >
      <div style={{ height: 400, width: "90%" }}>
        <DataGrid
          rows={rows}
          rowHeight={45}
          // getRowHeight={() => "auto"}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          getRowId={(row) => row._id}
          onCellClick={ 
            row => {
              mEmail(row.row.email)
              console.log(row.row.email)
            }
          }
          components={{ Toolbar: GridToolbar }}
        />
      </div>
    </Grid>
  );
};

export default AdminTable;
