import { useState, useEffect } from "react";
import axiosInstance from "../../axiosInterceptor";
import { Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const UserAgreement = ({ surveyId, startSurvey }) => {
  const [userAgreement, setUserAgreement] = useState("");
  const [userAgreementDescription, setUserAgreementDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance().get(
          "survey/useragreement/" + surveyId
        );
        setUserAgreement(response.data.userAgreement);
        setUserAgreementDescription(response.data.userAgreementDescription);
      } catch (err) {}
    };
    fetchData();
  }, [surveyId]);
  return (
    <div
      className="mt-3"
      style={{
        textAlign: "center",
        margin: "auto",
        width: "90%",
        border: "1px solid black",
      }}
    >
      <div
        style={{
          display: "inline-block",
          width: "100%",
          padding: "10px",
          background: "rgb(153, 0, 0)",
        }}
      >
        <h4 style={{ display: "inline-block", float: "left", color: "white" }}>
          <FormattedMessage
            id="UserAgreement.school"
            defaultMessage="University of Southern California"
          />
        </h4>
        <h4 style={{ display: "inline-block", float: "right", color: "white" }}>
          <FormattedMessage
            id="UserAgreement.department"
            defaultMessage="Department of Psychology"
          />
        </h4>
      </div>
      <div style={{ padding: "20px" }}>
        <h3>
          <FormattedMessage
            id="UserAgreement.agree"
            defaultMessage="Please agree to the user agreement before proceeding."
          />
        </h3>
        <p>{userAgreementDescription}</p>

        <p>
          <Link className="linkHeading" to="/privacy">
            <FormattedMessage
              id="UserAgreement.read"
              defaultMessage="Read our full privacy policy here."
            />
          </Link>
        </p>

        <h5>
          <FormattedMessage
            id="UserAgreement.title"
            defaultMessage="User Agreement"
          />
        </h5>

        <Row>
          <textarea
            style={{ margin: "auto", padding: "5px" }}
            rows="8"
            cols="100"
            readOnly
            value={userAgreement}
          ></textarea>
        </Row>
        <br />
        <Button
          style={{ backgroundColor: "rgb(153, 0, 0)" }}
          onClick={startSurvey}
        >
          <FormattedMessage id="UserAgreement.action" defaultMessage="Agree" />
        </Button>
      </div>
    </div>
  );
};

export default UserAgreement;
