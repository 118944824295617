import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ManageSurveyListItem from "./ManageSurveyListItem";
import ManageSurveyModal from "./ManageSurveyModal";
import styles from "../Admin.module.css";

const ManageSurveyList = ({
  allSurveys,
  setAllSurveys,
  update,
  setUpdate,
  role,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  useEffect(() => {
    setIsModalOpen(true);
  }, [selectedSurvey]);

  return (
    <Container className={styles.backgroundSection + " py-2"}>
      {allSurveys.map((survey, idx) => (
        <ManageSurveyListItem
          key={idx}
          survey={survey}
          selectSurvey={(survey) => {
            // setIsModalOpen(true);
            setSelectedSurvey(survey);
          }}
        />
      ))}
      {selectedSurvey && (
        <ManageSurveyModal
          role={role}
          show={isModalOpen}
          update={update}
          setUpdate={setUpdate}
          onHide={() => setIsModalOpen(false)}
          survey={selectedSurvey}
        />
      )}
    </Container>
  );
};

export default ManageSurveyList;
