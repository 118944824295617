import {
  Container,
  Col,
  Row,
  Button,
  ProgressBar,
  Modal,
  Form,
} from "react-bootstrap";
import { useState } from "react";
import AnalyticsModal from "./AnalyticsModal";

const AnalyticsTab = ({ analytic, questions, analytics, setAnalytics }) => {
  const [modalShow, setModalShow] = useState(false);
  // console.log("analytic in AnalyticsTab::" + JSON.stringify(analytic))

  const getEquation = () => {
    let res = `${analytic.analytic_name} = `;
    let vars = [];
    analytic.analytic_variables.forEach((v) => {
      if (v[0] === "-") {
        vars.push(`(${v})`);
        return;
      }
      vars.push(`${v}`);
    });
    res += `mean( ${vars.join(" + ")} )`;
    return res;
  };

  const handleRemove = () => {
    const newAnalytics = analytics.filter((a) => a !== analytic);
    setAnalytics(newAnalytics);
  };

  return (
    <Container fluid className="text-left">
      <Row>
        <Col xs={8}>{getEquation()}</Col>
        <Col xs={4} >
          <Button
            variant="outline-success mr-auto mr-md-1"
            onClick={() => setModalShow(true)}
          >
            Modify
          </Button>
          <Button
            variant="outline-danger  mt-2 mt-md-0 "
            onClick={handleRemove}
          >
            Remove
          </Button>
        </Col>
      </Row>
      <AnalyticsModal
        show={modalShow}
        questions={questions}
        onHide={() => setModalShow(false)}
        analytic={analytic}
        newAnalytics={false}
        analytics={analytics}
        setAnalytics={setAnalytics}
      />
    </Container>
  );
};

export default AnalyticsTab;
