import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
    return (
      <div>
      <Typography variant="h6" align='left' gutterBottom={true}>FQA</Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="button">What should I do if the blank page shows?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
               Please turn off the broswer translation function if it's on.
             </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="button">"502 invalid" pops up in the country select question</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              Please refesh the page and try agagin.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
