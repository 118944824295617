// import messages_de from "./translations/de.json";
import messages_en from "../translations/en71.json";
// import messages_zh from "./translations/zh.json";

export const messages = {
  // de: messages_de,
  en: messages_en,
  // zh: messages_zh,
};

export const language = navigator.language.split(/[-_]/)[0]; // language without region code
// console.log(language);
