import { Container, Row, Col } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const marks = {
  0: 0,
  1: 1,
  2: "You 2",
  3: 3,
  4: 4,
  5: 5,
};
const RCSlider = () => {
  return (
    <Container>
      {/* <Slider
        dots
        min={0}
        max={5}
        marks={marks}
        step={0.01}
        onChange={log}
        defaultValue={2}
      /> */}
      <Row className="mt-5">
        <Col>
          <Range
            min={0}
            max={5}
            step={0.01}
            value={[0, 1]}
            marks={marks}
            tipProps={{ visible: true }}
            count={1}
            defaultValue={[1, 3]}
            // trackStyle={[{ backgroundColor: "red" }, { backgroundColor: "green" }]}
            handleStyle={[
              {
                backgroundColor: "lightblue",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
              },
              { backgroundColor: "gray" },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default RCSlider;
