import { Bar } from "react-chartjs-2";
import React from "react";

const DynamicChart = React.memo(({ min,max, data }) => {
  let values = data?.datasets[0]?.data

  if(Array.isArray(values)){
    for(let i = 0; i<values.length; i++){
      if(values[i] === "missing"){
        let miss_index = parseInt(i)
        if(data?.labels[miss_index] && !data?.labels[miss_index].includes("miss")) data.labels[miss_index] = data.labels[miss_index]+ "(missing)"
      }
    }  
  }
  const options = {
    scales: {
      y: {
        min: min? min : 0,
        suggestedMax: max,
      },
    },
  };
  return (
    <>
      <Bar data={data} options={options} redraw={true} />
    </>
  );
});

export default DynamicChart;
