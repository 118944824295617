import { Button, Row, Col, Card, Container } from "react-bootstrap";

const ConfirmCancelAlert = ({ onClose, title, text, onConfirm }) => {
  return (
    <Card style={{ width: "25rem", height: "10rem" }}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Container>
          <Row>
            <Col xs={6} className="text-left">
              <Button
                variant="outline-success"
                className="w-100"
                onClick={async () => {
                  await onConfirm();
                  onClose();
                }}
              >
                OK
              </Button>
            </Col>
            <Col xs={6} className="text-left">
              <Button
                variant="outline-danger"
                className="w-100"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default ConfirmCancelAlert;
