import { Row, Col, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import PropertyCheckList from "./PropertyCheckList";

import "./ListAnimation.css";
import "./SurveyResult.css";

const PropertyPanel = ({ setSelectedProperty, selectedProperty, property }) => {
  if (selectedProperty.length === 0) {
    return (
      <Col sm={12}>
        <Row className="mt-1">
          <Col>
            <Dropdown>
              <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                <FormattedMessage
                  id="Panel.start"
                  defaultMessage="Start by picking a property"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {property.map((p, idx) => {
                  return (
                    <Dropdown.Item
                      key={idx}
                      onClick={() => {
                        if (!selectedProperty.includes(p)) {
                          setSelectedProperty([...selectedProperty, p]);
                        }
                      }}
                    >
                      {p.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    );
  }
  return (
    <>
      <Col lg={3} className="my-1 d-flex justify-content-center">
        <Dropdown>
          <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
            <FormattedMessage
              id="Panel.pick"
              defaultMessage="Pick a property"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {property.map((p) => {
              return (
                <Dropdown.Item
                  key={p.label}
                  onClick={() => {
                    if (!selectedProperty.includes(p)) {
                      p.target = [];

                      setSelectedProperty([...selectedProperty, p]);
                    }
                  }}
                >
                  {p.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col lg={9}>
        <PropertyCheckList
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Col>
    </>
  );
};

export default PropertyPanel;
