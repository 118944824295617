import React from "react";
import "./styles.css";
import { History } from "history";
import { FormattedMessage } from "react-intl";

import { Row, Col, Card, Container } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import morteza from "../../assets/images/morteza.jpg";
import mohammad from "../../assets/images/mohammad.jpg";
import sena from "../../assets/images/sena_park3_closeup.jpeg";
import jesse from "../../assets/images/Jesse-Graham_grey.jpg";
import john from "../../assets/images/John_Raidt.jpg";
import peter from "../../assets/images/Ditto-pic.jpg";

interface MemberCard {
  src: string;
  name: string;
  link: string;
}

const openProfile = (name: string, history: History) => {
  history.push({
    pathname: `/teamProfile/${name}`,
  });
};

const teamCards: MemberCard[] = [
  { src: morteza, name: "Morteza Dehghani", link: "Morteza" },
  { src: mohammad, name: "Mohammad Atari", link: "Mohammad" },
  { src: sena, name: "Sena Koleva", link: "Sena" },
  { src: jesse, name: "Jesse Graham", link: "Jesse" },
];

const advCards: MemberCard[] = [
  { src: john, name: "Jonathan Haidt", link: "Jonathan" },
  { src: peter, name: "Peter Ditto", link: "Peter" },
];

const getCards = (cards: MemberCard[], history: History) =>
  cards.map((card) => {
    return (
      <Col
        lg={3}
        sm={6}
        className="teamCol d-flex justify-content-center"
        onClick={() => openProfile(card.link, history)}
      >
        <Card className="team-card">
          <Card.Img variant="top" src={card.src} className="team-img" />
          <Card.Body>
            <Card.Title>{card.name}</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    );
  });

// Display the about page
const About: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  return (
    <>
      <Container fluid className="mx-2">
        <Row>
          <Col>
            <p className="h2 text-center">
              <FormattedMessage id="AboutUs.title" defaultMessage="About Us" />
            </p>
            <p>
              <FormattedMessage
                id="AboutUs.content1"
                defaultMessage="This website is a collaboration among social psychologists and
              cognitive scientists who study morality and politics. Our goal was
              to create a site that would be useful and interesting to users,
              particularly ethics classes and seminars, and that would also
              allow us to test a variety of theories about moral psychology. One
              of our main goals is to foster understanding across cultures and
              political spectrums. Almost everyone cares about morality, and we
              want to understand, and to help others understand, the many
              different ways that people care."
              />
            </p>
            <p>
              <FormattedMessage
                id="AboutUs.content2"
                defaultMessage="To learn more about moral psychology, you can find articles we
              have written on our web pages, or you can follow the links that we
              offer on the feedback pages for each of our studies."
              />
            </p>
            <p>
              <FormattedMessage
                id="AboutUs.content3"
                defaultMessage="The studies on this site have been approved by the Institutional
              Review Board at the University of Southern California. If you have
              questions or comments about this site, please contact our
              webmasters using cssl.at.usc@gmail.com. If you have questions or
              comments about moral psychology more generally, please contact any
              of co-directors."
              />
            </p>
            <p>
              <FormattedMessage
                id="AboutUs.content4"
                defaultMessage="We thank you for your interest and your contribution to
              psychological research."
              />
            </p>

            <br />
          </Col>
        </Row>

        <Row className="team">
          <Col>
            <hr className="divider mb-3"></hr>
          </Col>
          <Col md={12} sm={12} xs={12}>
            <p className="h2 text-center">
              <FormattedMessage
                id="AboutUs.theTeam"
                defaultMessage="The Team"
              />
            </p>
          </Col>
        </Row>
        <Row className="team">{getCards(teamCards, history)}</Row>
        <Row className="team">
          <Col>
            <p className="h2 text-center">
              <FormattedMessage
                id="AboutUs.board"
                defaultMessage="Advisory Board"
              />
            </p>
          </Col>
        </Row>

        <Row className="team">{getCards(advCards, history)}</Row>
        <Row>
          <Col>
            <hr className="divider mt-1"></hr>
          </Col>
        </Row>
        <Row>
          <div className="mission text-left">
            <p>
              <FormattedMessage
                id="AboutUs.missionTitle"
                defaultMessage="YourMorals Mission Statement"
              />
            </p>
            <p>
              <FormattedMessage
                id="AboutUs.missionContent1"
                defaultMessage="Our mission is to conduct scientifically rigorous research on
              moral and political psychology, and then to apply our findings to
              improve the functioning of institutions, relationships, and
              individual lives. Our research is characterized by these features:"
              />
            </p>
            <ul>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent2"
                  defaultMessage="Our theoretical approach begins with Moral Foundations Theory "
                />
                (
                <a href="https://link.springer.com/article/10.1007/s11211-007-0034-z" target="_blank" rel="noreferrer">
                  Haidt & Graham, 2007;
                </a>
                &nbsp;
                <a href="https://www.sciencedirect.com/science/article/pii/B9780124072367000024" target="_blank" rel="noreferrer">
                  Graham et al., 2013
                </a>
                ),
                <FormattedMessage
                  id="AboutUs.missionContent3"
                  defaultMessage=" but we draw from and test many theories in moral and political
                psychology."
                />
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent4"
                  defaultMessage="We strive to be non-partisan, even as we acknowledge (and study)
                the difficulty of escaping bias. We begin with the assumption
                that partisans of all types are guided by sincere moral
                convictions, and we try to understand those convictions within
                the context of people’s larger networks of meanings and values."
                />
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent5"
                  defaultMessage="We aim to promote a cross cultural understanding by of human
                values by trying reach diverse populations across the globe. We
                want to explore the moral mind across different cultures without
                imposing a specific way of thinking of right and wrong ("
                />
                <a href="https://www.sciencedirect.com/science/article/abs/pii/S1090513820300933" target="_blank" rel="noreferrer">Atari, Graham, & Dehghani, 2020
                </a>
                <FormattedMessage
                  id="AboutUs.missionContent5.1"
                  defaultMessage=")."
                />
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent6"
                  defaultMessage="We strive to be technologically and methodologically innovative,
                taking full advantage of the web and other technologies to
                improve our research. We are particularly interested in
                discovering what can be done with our large dataset which cannot
                be done with more conventional datasets."
                />
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent7"
                  defaultMessage="We strive to be ethical in our own research. We want to ensure
                that our research participants never feel exploited; we want
                them to gain as much from their participation as we do. We
                therefore try to create interesting, informative, and
                evidence-based feedback pages for each study. We take user
                comments seriously and use them to improve our research."
                />
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent8"
                  defaultMessage="We prize openness and collaboration in science. While logistics
                prevent us from entertaining all requests, we generally welcome
                joint projects with other investigators, and we sometimes make
                our data available to other scientists while guarding the
                privacy and anonymity of our participants."
                />
              </li>
            </ul>

            <p>
              <FormattedMessage
                id="AboutUs.missionContent9"
                defaultMessage="Our efforts to apply our research are characterized by these
              features:"
              />
            </p>
            <ul>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent10"
                  defaultMessage="We try to make moral psychology available to the general public
                and to teachers of classes on ethics and civics. See "
                />
                <a href="https://moralfoundations.org/" target="_blank" rel="noreferrer">
                  www.MoralFoundations.org
                </a>
                .
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent11"
                  defaultMessage="We hope to find research-based methods for improving political
                civility, both by fostering mutual understanding and by finding
                ways to change systemic variables that will end up, indirectly,
                reducing the common tendency to demonize political opponents."
                />
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent12"
                  defaultMessage="We aim to equip people with skills and a shared language to
                overcome their moral and political differences and work together
                to solve their collective problems. See"
                />
                <a href="https://constructivedialogue.org/" target="_blank" rel="noreferrer">
                  {" "}
                  www.constructivedialogue.org
                </a>
                .
              </li>
              <li>
                <FormattedMessage
                  id="AboutUs.missionContent13"
                  defaultMessage="We also aim to provide insight into the geographic distribution
                of moral values in the United States. See "
                />
                <a href="https://map.yourmorals.org/" target="_blank" rel="noreferrer">
                  www.map.YourMorals.org
                </a>
                .
              </li>
            </ul>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(About);
