import { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Collapse,
  Spinner,
  Alert,
} from "react-bootstrap";
import "./ListAnimation.css";
import "./SurveyResult.css";
import axiosInstance from "../../axiosInterceptor";
import DynamicChart from "./DynamicChart";
import PropertyPanel from "./PropertyPanel";
import SurveyAnalyticsExplain from "./SurveyAnalyticsExplain";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

const randomRGBA = () => {
  var o = Math.round,
    r = Math.random,
    s = 255;

  const rgb = "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ",";

  return [rgb + "0.2)", rgb + "1)"];
};

const [color, borderColor] = randomRGBA();

const ResultAnalytics = ({ max,min, response, id, domRef, exportToPDF }) => {
  const [prev, setPrev] = useState([]);
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [checkedAnalytics, setCheckAnalytics] = useState(
    Object.keys(response).map((k) => ({
      name: k,
      value: true,
    }))
  );
  const intl = useIntl();
  const messages = defineMessage({
    resultLabel: {
      id: "ResultAnalytics.label",
      defaultMessage: "My Result",
    },
    average: {
      id: "ResultAnalytics.average",
      defaultMessage: "overall average",
    },
    differnet: {
      id: "ResultAnalytics.differnet",
      defaultMessage: "Please select differnet properties(at least one)",
    },
    noData: {
      id: "ResultAnalytics.noData",
      defaultMessage: "Not enough data",
    },
    hide: {
      id: "ResultAnalytics.hide",
      defaultMessage: "Hide Compare your scores",
    },
    show: {
      id: "ResultAnalytics.show",
      defaultMessage: "Compare your scores",
    },
  });

  const [data, setData] = useState({
    labels: Object.keys(response),
    datasets: [
      {
        label: intl.formatMessage(messages.resultLabel),
        data: Object.values(response),
        backgroundColor: Object.values(response).map((e) => color),
        borderColor: Object.values(response).map((e) => borderColor),
        borderWidth: 1,
      },
    ],
  });

  //get all comparable properties, like Age, Sex.....
  useEffect(() => {
    const getProperty = async () => {
      const res = await axiosInstance().get("analytics/analyticsProperties");
      setProperty(res.data);
    };
    getProperty();
  }, []);

  const addDataSet = (title, average) => {
    const newData = { ...data };
    const [color, borderColor] = randomRGBA();
    newData.datasets.push({
      label: title,
      data: Object.values(average),
      backgroundColor: Object.values(average).map((_) => color),
      borderColor: Object.values(average).map((_) => borderColor),
      borderWidth: 1,
    });
    // console.log("addDataSet newdata:"+JSON.stringify(newData))
    setData(newData);
  };

  const getTitle = (properties) => {
    let str = intl.formatMessage(messages.average);
    if (properties.length > 0) str = "";
    properties.forEach((p, idx) => {
      str += p.label + ": " + p.target;

      if (idx < properties.length - 1) str += " & ";
    });
    return str;
  };

  const getAnalytics = async () => {
    setLoading(true);
    setErrorMsg(null);
    if (selectedProperty && _.isEqual(selectedProperty, prev)) {
      setLoading(false);
      setErrorMsg(intl.formatMessage(messages.differnet));
      return;
    }

    if (selectedProperty)
      try {
        const res = await axiosInstance().post("/analytics/average1", {
          surveyId: id,
          properties: selectedProperty,
        });
        setPrev(_.cloneDeep(selectedProperty));
        const data = res.data;
        const title = getTitle(selectedProperty);

        addDataSet(title, data);
      } catch (err) {
        console.log(err);
        setErrorMsg(intl.formatMessage(messages.noData));
      }
    setLoading(false);
  };

  const reset = () => {
    setSelectedProperty([]);
    setPrev(null);

    const newData = { ...data };
    newData.datasets = [newData.datasets[0]];
    setData(newData);
  };

  const onCheckBoxChanged = (a, event) => {
    let index = checkedAnalytics.findIndex((c) => c.name === a);
    const arr = [...checkedAnalytics];
    arr[index].value = event.target.checked;
    setCheckAnalytics(arr);
    if (event.target.checked) {
      let prev = { ...data };
      prev.labels.push(a);
      prev.datasets[0].data.push(response[a]);
      setData(prev);
    } else {
      let prev = { ...data };
      let idx = prev.labels.indexOf(a);
      if (idx === -1) {
        return prev;
      }
      prev.labels.splice(idx, 1);
      prev.datasets[0].data.splice(idx, 1);

      setData(prev);
    }
  };

  return (
    <Container fluid className="mt-3">
      <Row>
        {checkedAnalytics.map((a, idx) => (
          <Col key={idx}>
            <Form.Check
              label={a.name}
              type="checkbox"
              defaultChecked={a.value}
              onChange={(event) => onCheckBoxChanged(a.name, event)}
            />
          </Col>
        ))}
      </Row>
      <Row className="mt-3">
        <Col sm={12}>
          <Button
            variant="outline-info"
            className="w-100"
            onClick={() => setOpen(!open)}
          >
            {open
              ? intl.formatMessage(messages.hide)
              : intl.formatMessage(messages.show)}
          </Button>
        </Col>
      </Row>
      <Collapse in={open} className="my-3">
        <Container>
          <Row>
            <Col>
              <SurveyAnalyticsExplain />
            </Col>
          </Row>
          <Row className="propertyCard mt-3 p-2">
            <PropertyPanel
              setSelectedProperty={setSelectedProperty}
              selectedProperty={selectedProperty}
              property={property}
            />
          </Row>
          <Row className="mt-3">
            <Button
              className="w-100"
              variant="outline-primary"
              disabled={loading}
              onClick={getAnalytics}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              <FormattedMessage
                id="ResultAnalytics.action"
                defaultMessage="Show Analytics!"
              />
            </Button>
          </Row>
          {errorMsg && (
            <Row className="mt-2">
              <Col xs={{ span: 4, offset: 4 }}>
                <Alert variant="danger">{errorMsg}</Alert>
              </Col>
            </Row>
          )}
        </Container>
      </Collapse>
      <Row ref={domRef}>
        <Col sm={12}>
          <DynamicChart data={data} max={max} min={min} />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <Button variant="outline-danger" onClick={reset} className="mx-4 mb-2">
            <FormattedMessage
              id="ResultAnalytics.reset"
              defaultMessage="Reset Analytics"
            />
          </Button>
        {/* </Col>
      </Row>
      <Row className="mb-5">
        <Col> */}
          <Button variant="outline-secondary" onClick={exportToPDF} className="mx-4 mb-2">
            <FormattedMessage
              id="ResultAnalytics.export"
              defaultMessage="Export to pdf"
            />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ResultAnalytics;
