import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Modal } from "react-bootstrap";
import axiosInstance from "../../../axiosInterceptor";

const Modalcenter = ({ show, onHide, src }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <img src={src} alt="G1s" width="1080" className="img-fluid" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const ReportDetailModal = ({ onHide, id, show }) => {
  const [report, setReport] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (id === null) {
      return;
    }
    const fetchData = async () => {
      try {
        const report = await axiosInstance().get("report/" + id);
        // console.log("reports:"+JSON.stringify(report));
        // console.log("reports url:"+JSON.stringify(report.data));
        setReport(report.data.report);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const getContent = () => {
    if (loading) {
      return <Spinner></Spinner>;
    } else {
      return (
        <>
          <Row className="text-muted my-0">{`Submitted by ${report.email} at ${report.createdAt}`}</Row>
          <Row className="h4 my-3">Description</Row>
          <Row>{report.description}</Row>
          <Row className="h4 my-3">Images</Row>
          {report.urls.map((url) => (
            <Row className="my-2">
              <img
                src={url}
                className="img-fluid"
                alt="G1"
                width="200"
                onClick={() => setModalShow(true)}
              />

              <Modalcenter
                show={modalShow}
                onHide={() => setModalShow(false)}
                src={url}
              />
            </Row>
          ))}
        </>
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Bug Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">{getContent()}</Modal.Body>
    </Modal>
  );
};

export default ReportDetailModal;
