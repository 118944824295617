import React, { Component } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { MdLocationOn } from "react-icons/md";
import ReactSlider from "react-slider";
import ReactTooltip from "react-tooltip";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 15px;
`;

// width: 32px
const StyledThumb = styled.div`
  height: 79px;
  line-height: 15px;
  text-align: center;
  background-color: transparent;
  color: black;
  top: -31px;
  width: 20px;
  cursor: default;
`;

// width: 32px
const StyledThumbRightMark = styled.div`
  height: 1px;
  line-height: 17px;
  text-align: center;
  background-color: white;
  color: black;
  top: -2px;
  width: 20px;
  cursor: default;
`;

// width: 32px
const StyledThumbLeftMark = styled.div`
  height: 1px;
  line-height: 17px;
  text-align: center;
  background-color: white;
  color: black;
  top: -2px;
  width: 20px;
  left: -17px !important;
  cursor: default;
`;

const Thumb = (props, state) => {
  let you, mean;
  you = state.value[1];
  mean = state.value[2];
  if(state.value.length === 3){
     you = undefined
     mean = state.value[1];
  }

  if (state.valueNow === mean || state.valueNow === you)
    return (
      <StyledThumb {...props}>
        {state.valueNow === you && (
          <div style={{ marginLeft: "-14px" }}>
            {Math.abs(Math.round((you - mean + Number.EPSILON) * 100) / 100) >
              0.1 && (
              <p style={{ fontSize: "14px", margin: "0px" }}>
                {state.valueNow}
              </p>
            )}
            {Math.abs(Math.round((you - mean + Number.EPSILON) * 100) / 100) <=
              0.1 &&
              (you > mean ? (
                <p
                  style={{
                    fontSize: "13px",
                    margin: "0px",
                    textAlign: "right",
                  }}
                >
                  {state.valueNow}
                </p>
              ) : (
                <p style={{ fontSize: "13px", margin: "0px" }}>
                  {state.valueNow}
                </p>
              ))}
            <span data-tip={state.valueNow} data-for="you">
              <IconContext.Provider
                value={{
                  className: "harm",
                  size: "25px",
                  color: "#00cc00",
                  style: { verticalAlign: "middle" },
                }}
              >
                <MdLocationOn />
              </IconContext.Provider>
            </span>
            <ReactTooltip
              id="you"
              place="top"
              type="dark"
              effect="solid"
              className="toolTip"
              getContent={(dataTip)=>{
                return  <div> You: {dataTip}</div> 
              }}
            >
              {/* You: {state.valueNow} */}
            </ReactTooltip>

            {Math.abs(Math.round((you - mean + Number.EPSILON) * 100) / 100) >
              0.1 && (
              <p style={{ margin: "3px 0px 0px 0px", fontSize: "12px" }}>You</p>
            )}
          </div>
        )}
        {state.valueNow === mean && (
          <div style={{ marginLeft: "-14px" }}>
            {Math.abs(Math.round((you - mean + Number.EPSILON) * 100) / 100) >
              0.1 && (
              <p style={{ fontSize: "14px", margin: "0px" }}>
                {state.valueNow}
              </p>
            )}
            {Math.abs(Math.round((you - mean + Number.EPSILON) * 100) / 100) <=
              0.1 &&
              (mean > you ? (
                <p
                  style={{
                    fontSize: "13px",
                    margin: "0px",
                    textAlign: "right",
                  }}
                >
                  {state.valueNow}
                </p>
              ) : (
                <p style={{ fontSize: "13px", margin: "0px" }}>
                  {state.valueNow}
                </p>
              ))}
            <span data-tip={state.valueNow} data-for="mean">
              <IconContext.Provider
                value={{
                  className: "harm",
                  size: "25px",
                  color: "black",

                  style: { verticalAlign: "middle" },
                }}
              >
                <MdLocationOn />
              </IconContext.Provider>
            </span>
            <ReactTooltip
              id="mean"
              place="top"
              type="dark"
              effect="solid"
              className="toolTip"
              getContent={(dataTip)=>{
                return  <div> Avg: {dataTip}</div> 
              }}
            >
              {/* Avg: {state.valueNow} */}
            </ReactTooltip>
            {Math.abs(Math.round((you - mean + Number.EPSILON) * 100) / 100) >
              0.1 && (
              <p style={{ margin: "3px 0px 0px 0px", fontSize: "12px" }}>Avg</p>
            )}
          </div>
        )}
      </StyledThumb>
    );
  if (state.valueNow === 5)
    return (
      <StyledThumbRightMark {...props}>
        <div>
          <p
            style={{
              borderRadius: "12px",
              background: "white",
              border: "3px solid black",
              width: "24px",
              marginLeft: "10px",
            }}
          >
            {" "}
            {state.valueNow}
          </p>
        </div>
      </StyledThumbRightMark>
    );
  if (state.valueNow === 1)
    return (
      <StyledThumbLeftMark {...props}>
        <div>
          <p
            style={{
              borderRadius: "12px",
              background: "white",
              border: "3px solid black",
              width: "24px",
              marginLeft: "16px",
            }}
          >
            {" "}
            {state.valueNow}
          </p>
        </div>
      </StyledThumbLeftMark>
    );
  return <div />;
};

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${"#5B7A8A"};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      you:  Math.round((props.normal + Number.EPSILON) * 10) / 10,
      mean: Math.round((props.mean + Number.EPSILON) * 10) / 10,
    };
  }

  render() {
    return (
      <StyledSlider
        defaultValue= {this.state.you?[1, this.state.you, this.state.mean, 5]:[1,this.state.mean, 5]}
        renderTrack={Track}
        disabled
        min={1}
        step={0.1}
        max={5}
        marks={1}
        markClassName="customMark"
        renderThumb={Thumb}
        minDistance={1}
      />
    );
  }
}

export default Slider;
