import { Container, Col, Row, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { useState, useEffect, useCallback } from "react";
import PropertyPanel from "../../SurveyResult/PropertyPanel";

import "./styles.css";
import BasicInfo from "./BaiscInfo";
import axiosInstance from "../../../axiosInterceptor";
import Agreement from "./Agreements";
import HtmlSection from "./HtmlField/HtmlSection";
import SurveyCreatorComponentCustom from "./SurveyCreatorCustom";
import SurveyCreatorComponent2 from "./Question2";

import Analytics from "./Analytics/Analytics";

const EditSurvey = ({ create = false, role }) => {
  let history = useHistory();
  const type = history.location.state.type;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("enter /create edit Survey")
    console.log("role:" + role)
  }, []);
  const { id } = useParams();
  const [disable, setDisable] = useState(false);
  const [basic, setBasic] = useState({
    name: "",
    description: "",
    userAgreement: "",
    userAgreementDescription: "",
    totalParticipants: 0,
  });
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [debrief, setDebrief] = useState("");
  const [instructions, setInstruction] = useState("");
  const [userAgreement, setUserAgreement] = useState("");
  const [userAgreementDescription, setUserAgreementDescription] = useState("");
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [pages, setPages] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [property, setProperty] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [subSettingEnabled, setSubsettingEnabled] = useState(false)
  const [subSettingValue, setSubsettingValue] = useState(1)
  const [subSetStyling, setSubSetStyling] = useState('section')
  const [numQuestions, setNumQuestions] = useState(0)



  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Enabling this and specifiying a number will allow you to subset the questions in the survey. This is useful for large surveys where you want to show a random subset of questions to each participant.
    </Tooltip>
  );


  const createSurvey = async () => {
    // console.log(pages);
    // return;
    setDisable(true);
    const survey = {
      name,
      description,
      debrief,
      instructions,
      userAgreement,
      userAgreementDescription,
      totalParticipants,
      pages,
      analytics,
      subSettingValue,
      subSettingEnabled
    };
    if (type === "experiment") {
      survey.constraints = selectedProperty;
    }
    try {
      let url = "admin/create";
      if (role === "experimenter") {
        url = "admin/experimenter/create";
      }
      const resp = await axiosInstance().post(url, { survey, type: type });
      console.log("createSurvey(), resp:"+JSON.stringify(resp));
      history.push("/");
    } catch (err) {
      console.log(err);
    }
    setDisable(false);
  };

  const updateSurvey = async () => {
    setDisable(true);
    const survey = {
      id,
      name,
      description,
      debrief,
      instructions,
      userAgreement,
      userAgreementDescription,
      totalParticipants,
      pages,
      analytics,
      subSettingEnabled,
      subSettingValue
    };
    if (type === "experiment") {
      survey.constraints = selectedProperty;
    }
    try {
      let url = "admin/update/" + id;
      if (role === "experimenter") {
        url = "admin/experimenter/update/" + id;
      }
      const resp = await axiosInstance().post(url, { survey, type: type });
      console.log("updateSurvey resp:"+JSON.stringify(resp));
      history.push("/");
    } catch (err) {
      console.log(err);
    }
    setDisable(false);
  };

  const subsettingCheck = (e) => {
    setSubsettingValue(e.target.value)
    if (
      (e.target.value <= 0 || e.target.value >= numQuestions) &&
      e.target.value !== ''
    ) {
      setDisable(true)
      setSubSetStyling('section-err')
    } else if (e.target.value === '') {
      setDisable(true)
      setSubSetStyling('section')
    } else {
      setSubSetStyling('section')
      setDisable(false)
    }
  }

  const subsettingSwitchCheck = (e) => {
    if (e.target.checked) {
      setSubsettingEnabled(true)
    } else {
      setSubsettingEnabled(false)
      setSubSetStyling('section')
      setDisable(false)
      setSubsettingValue(1)
    }
  }

  const countQuestions = () => {
    let count = 0
    pages.map((page) => {
      page.elements.map((element) => {
        count++
      })
    })
    setNumQuestions(count)
  }
  useEffect(() => {
    console.log("pages changed:"+JSON.stringify(pages));
    countQuestions()
  }, [pages]);
  
  useEffect(() => {
    if (!role) {
      return;
    }
    const fetchProperties = async () => {
      try {
        const res = await axiosInstance().get("analytics/analyticsProperties");
        setProperty(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchData = async () => {
      try {
        let url = "admin/survey/" + id;
        if (role === "experimenter") {
          url = "admin/experimenter/survey/" + id;
        }
        const response = await axiosInstance().get(url);
        const survey = response.data.survey;

        const basic = {
          name: survey.name,
          description: survey.description,
          userAgreement: survey.userAgreement,
          userAgreementDescription: survey.userAgreementDescription,
          totalParticipants: 0,
        };
        setName(survey.name);
        setDescription(survey.description);
        setTotalParticipants(survey.totalParticipants);
        setUserAgreement(survey.userAgreement);
        setUserAgreementDescription(survey.userAgreementDescription);
        setDebrief(survey.debrief);
        setInstruction(survey.instructions);
        setAnalytics(survey.analytics);
        setPages(survey.pages);
        setBasic(basic)
        setSubsettingEnabled(survey.subSettingEnabled)
        if (survey.subSettingEnabled) {
          setSubsettingValue(survey.subSettingValue)
        }
        if (survey.constraints) {
          console.log("survey.constraints:"+survey.constraints);
          setSelectedProperty(survey.constraints);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (type === "experiment") {
      fetchProperties();
    }
    if (!create) {
      fetchData();
    }
  }, [role]);

  return (
    <Container>
      <BasicInfo
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        totalParticipants={totalParticipants}
        setTotalParticipants={setTotalParticipants}
      />
      <Agreement
        userAgreement={userAgreement}
        setUserAgreement={setUserAgreement}
        userAgreementDescription={userAgreementDescription}
        setUserAgreementDescription={setUserAgreementDescription}
      />
      <HtmlSection
        debrief={debrief}
        setDebrief={setDebrief}
        instruction={instructions}
        setInstruction={setInstruction}
      />
      {/* {(pages?.length > 0 || create) && ( */}
      <>
        <SurveyCreatorComponentCustom pages={pages} setPages={setPages} />
        {/* <SurveyCreatorComponent2/> */}
        <Container className={subSetStyling}>
          {subSetStyling === 'section-err' && (
            <div className="error-msg">
              Error: Please enter a number greater than 0 and less than {}
              {numQuestions}
            </div>
          )}
          <div className="subsetting-div">
            <Form.Check
              type="switch"
              id="default-switch"
              label="Enable Subsetting"
              checked={subSettingEnabled}
              onChange={(e) => subsettingSwitchCheck(e)}
            />

            {subSettingEnabled && (
              <Form.Control
                type="number"
                variant="default"
                className="w-50 subsetting-input"
                placeholder="Number of Questions to subset"
                onChange={(e) => subsettingCheck(e)}
                value={subSettingValue}
              />
            )}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Button variant="outline-primary">What is this?</Button>
            </OverlayTrigger>
          </div>
        </Container>
        <Analytics
          analytics={analytics}
          setAnalytics={setAnalytics}
          pages={pages}
        />
      </>
      {/* )} */}
      {type === "experiment" && (
        <Container className="section py-4 mb-4">
          <Row>
            <PropertyPanel
              setSelectedProperty={setSelectedProperty}
              selectedProperty={selectedProperty}
              property={property}
            />
          </Row>
        </Container>
      )}

      <Container>
        <Row className="section py-4 ">
          <Col xs={3} className="d-flex justify-content-start">
            <Button
              className="w-50"
              variant="outline-dark"
              onClick={() => history.push("/")}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={9} className="d-flex justify-content-end">
            <Button
              className="w-25"
              variant="outline-success"
              onClick={create ? createSurvey : updateSurvey}
              disabled={disable}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default EditSurvey;
