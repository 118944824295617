import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import styles from "../Admin.module.css";

const DownloadCard = ({
  name,
  surveyId,
  _id,
  description,
  getLink,
  createdAt,
  kind,
  getNew,
  downloading,
  type,//old/new
  compileLimit,//none admin users can't compile download link
  timeLeft
}) => {
  const [spin, setSpin] = useState(false);
  const [spinGetData, setSpinGetData] = useState(false);

  const getButtons = useCallback(() => {
    if (kind === "old") {
      return (
        <Col xs={12} md={4} className="pr-md-4">
          <Button
            variant="outline-success"
            className="w-md-100 w-75"
            size="sm"
            onClick={() => getLink(_id)}
          >
            Get Link
          </Button>
        </Col>
      );
    }
    if (_id) {
      return (
        <>
          <Col xs={12} md={2} className="mx-0 px-0">
            <Button
              variant="outline-success"
              className="w-100 p-0"
              size="sm"
              onClick={async () => {
                setSpinGetData(true);
                await getLink(_id, surveyId,type);
                setSpinGetData(false);
              }}
            >
              {spinGetData ? (
                <Spinner animation="border" size="sm"></Spinner>
              ) : (
                "Get Cached Data"
              )}
            </Button>
          </Col>
          {!compileLimit && (
            <Col xs={12} md={2} className="mx-0 px-1">
              <Button
                variant="outline-primary"
                className="w-100 p-0"
                size="sm"
                onClick={async () => {
                  setSpin(true);
                  await getNew(surveyId);
                  setSpin(false);
                }}
                disabled={downloading}
              >
                {spin ? (
                  <Spinner animation="border" size="sm"></Spinner>
                ) : (
                  "Recompile Data"
                )}
              </Button>
            </Col>
          )}
        </>
      );
    }
    return (
      <Col xs={12} md={6} className="pr-md-4">
        <Button
          variant="outline-primary"
          className="w-md-100 w-75"
          size="sm"
          onClick={async () => {
            setSpin(true);
            await getNew(surveyId, "new");
            setSpin(false);
          }}
          disabled={downloading}
        >
          {spin ? (
            <Spinner animation="border" size="sm"></Spinner>
          ) : (
            "Compile Data"
          )}
        </Button>
      </Col>
    );
  }, [kind, _id, spin,spinGetData, downloading]);

  return (
    <Container className={`${styles.surveylist} my-2`}>
      <Row className={"align-items-center"}>
        <Col md={3} xs={12} className="text">
          {name}
        </Col>
        <Col md={3} xs={12} className="text text-left text-muted px-md-0">
          {description}
        </Col>
        {!compileLimit && createdAt && (
          <Col md={2} xs={12} className="text my-2 p-0">
            <span className="px-0"> {createdAt}</span>
          </Col>
        )}
        {compileLimit && (
          <Col md={2} xs={12} className="text my-2 p-0">
            <span className="px-0"> Expire in {timeLeft} days</span>
          </Col>
        )}
        {getButtons()}
      </Row>
    </Container>
  );
};

export default DownloadCard;
