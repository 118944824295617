import { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import MultipleQuestions from './MultipleQuestions';
import RankingQuestion from './RankingQuestions';
import { useHistory } from 'react-router-dom';
import publicIp from 'react-public-ip';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import UserAgreement from '../DisplaySurvey/UserAgreement';
import axiosInterceptor from '../../axiosInterceptor';

const MediaDiet = () => {
    const [cards, setCards] = useState([
        {
            id: 1,
            text: 'TV/Movies',
            questions: [
                {
                    title: 'Please List Up to 3 different genres',
                    type: 'genre',
                    number: 3,
                    prompt: 'Please type a genre'
                },
                {
                    title: 'Please List Any Number of Titles of your Favorite TV shows or Movies',
                    type: 'movie_tv',
                    number: 0,
                    prompt: 'Please type a movie name'
                }
            ]
        },
        {
            id: 2,
            text: 'Books',
            questions: [
                {
                    title: 'Please List Your Favorite Authors',
                    type: 'book_author',
                    number: 3,
                    choices: ['Drama', 'Comedy', 'Action', 'Fantasy'],
                    prompt: 'Please type a author name'
                },
                {
                    title: 'Please List Your Favorite Book Series',
                    type: 'book_series',
                    number: 0,
                    prompt: 'Please type a book series name'
                },
                {
                    title: 'Please List Any Number of Titles of your Favorite Books',
                    type: 'books',
                    number: 0,
                    choices: [
                        'Game of Thrones',
                        'The Flash',
                        'Gothan',
                        'Breaking Bad'
                    ],
                    prompt: 'Please type a book title'
                }
            ]
        },
        {
            id: 3,
            text: 'News(Paper/Online)',
            questions: [
                {
                    title: 'Please List Your Favorite News Sources',
                    type: 'news_tv',
                    number: 3,
                    prompt: 'Please type a source name'
                },
                {
                    title: 'Please List Your Favorite Social Media News Account',
                    type: 'news_account',
                    number: 0,
                    prompt: 'Please type a Movie name'
                },
                {
                    title: 'Please List Journalists, Reporters & Writers',
                    type: 'news_person',
                    number: 0,
                    prompt: 'Please type a name'
                }
            ]
        },
        {
            id: 4,
            text: 'News(TV/Broadcasts)',
            questions: [
                {
                    title: 'Please List Your Favorite News Network',
                    type: 'news_tv',
                    number: 3,
                    prompt: 'Please type a news network'
                },
                {
                    title: 'Please List news anchors / programs',
                    type: 'anchors',
                    number: 0,
                    prompt: 'Please type a news anchors'
                }
            ]
        },
        {
            id: 5,
            text: 'Social Media',
            questions: [
                {
                    title: 'Please List Your Favorite Accounts',
                    type: 'social_media_websites',
                    number: 3,
                    prompt: 'Please type an account'
                }
            ]
        }
    ]);
    const [page, setPage] = useState(0);
    const [answers, setAnswer] = useState({});
    const [ip, setIp] = useState(null);
    const [startTime, setStartTime] = useState(Date.now());
    const [disabled, setDisabled] = useState(false);
    const history = useHistory();
    const [requiredCategory, setRequiredCategory] = useState({});
    const [surveyState, setSurveyState] = useState('needAgreement');

    const intl = useIntl();
    const messages = defineMessages({
        ranking: {
            id: 'MediaDiet.rankingInstruction',
            defaultMessage:
                'Please rank your favorite type of media By Drag and Drop each options'
        }
    });

    useEffect(() => {
        const setInitialStates = async () => {
            try {
                const ipv4 = (await publicIp.v4()) || '';
                setIp(ipv4);
            } catch (err) {}
            setStartTime(Date.now());
        };
        setInitialStates();
    }, []);

    useEffect(() => {
        const state = {};
        for (let card of cards) {
            for (let q of card.questions) {
                state[q.type] = false;
            }
        }
        setRequiredCategory(state);
    }, [cards]);

    //

    const getPage = useCallback(() => {
        if (page === 0) {
            return (
                <RankingQuestion
                    options={cards}
                    setOptions={setCards}
                    title={intl.formatMessage(messages.ranking)}
                />
            );
        } else {
            const allQuestions = [];
            for (let i = 0; i < 5; i++) {
                allQuestions.push(
                    <MultipleQuestions
                        key={i}
                        question={cards[i]}
                        setOptions={setAnswer}
                        answer={answers}
                        required={requiredCategory}
                        setRequired={setRequiredCategory}
                        setDisabled={setDisabled}
                    />
                );
            }
            return allQuestions;
        }
    }, [page, cards, requiredCategory]);

    const prevButton = useCallback(() => {
        if (page !== 0) {
            return (
                <Button
                    className="w-100"
                    variant="outline-success"
                    onClick={() => setPage(page - 1)}
                >
                    <FormattedMessage
                        id="MediaDiet.previous"
                        defaultMessage="Previous"
                    />
                </Button>
            );
        }
    }, [page]);

    const nextButton = useCallback(() => {
        if (page < 1) {
            return (
                <Button
                    className="w-100"
                    variant="outline-success"
                    onClick={() => setPage(page + 1)}
                >
                    <FormattedMessage
                        id="MediaDiet.next"
                        defaultMessage="Next"
                    />
                </Button>
            );
        } else {
            return (
                <Button
                    className="w-100"
                    disabled={disabled}
                    variant="outline-success"
                    onClick={async () => {
                        for (let type in answers) {
                            if (!answers[type] || answers[type].length === 0) {
                                setRequiredCategory((old) => {
                                    const newRequired = { ...old };
                                    newRequired[type] = true;
                                    return newRequired;
                                });
                                return;
                            }
                        }
                        const ranking = cards.map((card) => card.text);
                        await axiosInterceptor().post(
                            'survey/' + 'media_diet',
                            {
                                responses: {
                                    ranking: ranking,
                                    answers: answers
                                },
                                clientIp: ip,
                                startTimestamp: startTime,
                                endTimestamp: Date.now()
                            }
                        );
                        history.push('/');

                        setDisabled(true);
                    }}
                >
                    <FormattedMessage
                        id="MediaDiet.complete"
                        defaultMessage="Complete"
                    />
                </Button>
            );
        }
    }, [page, answers]);

    const startSurvey = () => {
        setSurveyState('started');
        setStartTime(Date.now());
    };

    const getDisplay = () => {
        if (surveyState === 'needAgreement') {
            return (
                <UserAgreement
                    surveyId={'media_diet'}
                    startSurvey={startSurvey}
                />
            );
        } else if (surveyState === 'started') {
            return (
                <>
                    <Row>
                        <Col>{getPage()}</Col>
                    </Row>
                    <Row className="mt-4 mb-5">
                        <Col xs={3} className="mr-auto">
                            {prevButton()}
                        </Col>
                        <Col xs={3} className="ml-auto">
                            {nextButton()}
                        </Col>
                    </Row>
                </>
            );
        }
    };

    return (
        <Container>
            <Row>
                <Col className="h3 my-5">Media Diet Survey</Col>
            </Row>
            <Row>
                <Col>{getDisplay()}</Col>
            </Row>
        </Container>
    );
};

export default MediaDiet;
