/* eslint-disable */

import React from "react";
import morteza from "./assets/images/morteza.jpg";
import mohammad from "./assets/images/mohammad.jpg";
import sena from "./assets/images/sena_park3_closeup.jpeg";
import jesse from "./assets/images/Jesse-Graham_grey.jpg";
import john from "./assets/images/John_Raidt.jpg";
import peter from "./assets/images/Ditto-pic.jpg";
import "./styles/about.css";
import { Button, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class TeamProfile extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const name = this.props.match.params.name;
    return (
      <Container fluid>
        <div className="teamProfile">
          {name == "Morteza" && (
            <div>
              <h1>
                <FormattedMessage id="Team.title" defaultMessage="Member" />
              </h1>
              <img className="teamImage" src={morteza} alt="Morteza" />
              <p className="teamP">Morteza Dehghani</p>
              <p>
                Morteza Dehghani is an Associate Professor of psychology,
                computer science and the Brain and Creativity Institute (BCI) at
                the University of Southern California. His research spans the
                boundary between psychology and artificial intelligence. He is
                specifically interested in the intersection of morality and
                language, and uses natural language processing to investigate
                how various moral concerns and behaviors manifest themselves in
                language. You can find his{" "}
                <a href="https://dornsife.usc.edu/labs/cssl/">homepage here</a>,
                and his email address is: mdehghan at usc.edu
              </p>
            </div>
          )}
          {name == "Mohammad" && (
            <div>
              <h1>Member</h1>
              <img className="teamImage" src={mohammad} alt="Morteza" />
              <p className="teamP">Mohammad Atari</p>
              <p>
                Mohammad Atari received his Ph.D. in social psychology from the
                University of Southern California. His research interests
                broadly include culture, morality, and language. Mohammad has
                extended Moral Foundations Theory. He has examined how morality
                is rooted in socio-ecological contexts and how moral values can
                unite people for both good and bad. He is interested in how
                cultures give rise to moral values, and how morality manifests
                itself in everyday life.
                <a href="https://mohammadatari.com/">homepage here</a>.
              </p>
            </div>
          )}
          {name == "Sena" && (
            <div>
              <h1>Member</h1>
              <img className="teamImage" src={sena} alt="Morteza" />

              <p className="teamP">Sena Koleva</p>
              <p>
                Sena Koleva received a Ph.D. in Social and Personality
                Psychology from the University of California Irvine in 2011. She
                is a behavioral scientist and consultant with expertise in
                social psychology, personality theory and measurement, judgment
                and decision-making, people analytics, sustainability, close
                relationships, ideology, gender, and wellness and positive
                psychology. Sena is broadly interested in tech, health and
                wellness, sustainability, culture and gender, public policy, and
                education, and is currently the Head of User Research at
                Academia.edu. Her homepage is{" "}
                <a href="http://www.senakoleva.com/">here</a> and her email
                address is: skoleva at uci.edu
              </p>
            </div>
          )}
          {name == "Jonathan" && (
            <div>
              <h1>Advisory Board</h1>
              <img className="teamImage" src={john} alt="Morteza" />
              <p className="teamP">Jonathan Haidt</p>
              <p>
                Jonathan Haidt is a professor of business ethics at New York
                University's Stern School of Business. He is the author of{" "}
                <i>
                  The Happiness Hypothesis: Finding Modern Truth in Ancient
                  Wisdom
                </i>{" "}
                and{" "}
                <i>
                  The Righteous Mind: Why Good People are Divided by Politics
                  and Religion
                </i>
                , and he is co-author (with Greg Lukianoff) of{" "}
                <i>The Coddling of the American Mind</i>. He studies the
                psychological foundations of morality and is working on several
                projects to help people transcend moralistic divisions and
                understand each other. His{" "}
                <a href="https://jonathanhaidt.com/">homepage is here</a>.
              </p>
            </div>
          )}
          {name == "Peter" && (
            <div>
              <h1>Advisory Board</h1>
              <img className="teamImage" src={peter} alt="Morteza" />
              <p className="teamP">Peter Ditto</p>
              <p>
                Peter Ditto is a professor in the Department of Psychology and
                Social Behavior at the University of California, Irvine. His
                research focuses on "hot cognition": ­ how motivation and
                emotion shape (and often bias) social, moral, political,
                medical, and legal reasoning. His{" "}
                <a href="https://faculty.sites.uci.edu/phditto/">
                  homepage is here
                </a>
                .
              </p>
            </div>
          )}
          {name == "Jesse" && (
            <div>
              <h1>Team</h1>
              <img className="teamImage" src={jesse} alt="Morteza" />
              <p className="teamP">Jesse Graham</p>
              <p>
                Jesse Graham is the George S. Eccles Chair in Business Ethics
                and Associate Professor of Management in the Eccles School of
                Business at the University of Utah. Jesse studies the moral,
                political, and religious convictions that cause so much conflict
                — within and between individuals and organizations — and yet
                provide so much meaning to people’s lives. His{" "}
                <a href="https://eccles.utah.edu/team/jesse-graham/">
                  homepage is here
                </a>
                , and his email address is: Jesse.Graham at Eccles.Utah.edu
              </p>
            </div>
          )}
        </div>
        <br />
        <Button
          className="teamProfileButton"
          onClick={() =>
            this.props.history.push({
              pathname: "/about",
            })
          }
        >
          Back to About Page
        </Button>
      </Container>
    );
  }
}

export default withRouter(TeamProfile);
