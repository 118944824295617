import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

const SingleQuestion = ({
  prompt,
  setAnswer,
  type,
  answer,
  required,
  setRequired,
  setDisabled,
}) => {
  const [options, setOptions] = useState([{ id: 0, text: "" }]);

  useEffect(() => {
    setAnswer((oldAnswer) => {
      const newAnswer = { ...oldAnswer };
      newAnswer[type] = options.filter((o) => o.text !== "");
      return newAnswer;
    });
  }, [options]);

  useEffect(() => {
    if (answer[type]) {
      setOptions(answer[type]);
    } else {
      setOptions([{ id: 0, text: "" }]);
    }
  }, [prompt, type]);

  return (
    <>
      {options.map((option) => (
        <Row key={option.id}>
          <Col>
            <Form.Group>
              <Form.Control
                isInvalid={required[type]}
                placeholder={prompt}
                onChange={(e) => {
                  setRequired((old) => {
                    const newReq = { ...old };
                    newReq[type] = false;
                    return newReq;
                  });
                  setDisabled(false);
                  setOptions((old) => {
                    const val = e.target.value;
                    let new_state = [...old];
                    for (let i = 0; i < new_state.length; i++) {
                      if (new_state[i].id === option.id) {
                        new_state[i].text = val;
                      }
                    }
                    return new_state;
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Button
              className="w-100"
              variant="outline-success"
              onClick={() => {
                if (options.length <= 1) {
                  return;
                }
                setOptions(options.filter((o) => o.id !== option.id));
              }}
            >
              <FormattedMessage id="MediaDiet.remove" defaultMessage="Remove" />
            </Button>
          </Col>
        </Row>
      ))}
      <Row className="mb-3">
        <Col>
          <Button
            size="sm"
            className="w-25"
            variant="outline-warning"
            onClick={() => {
              setOptions([...options, { id: options.length, text: "" }]);
            }}
          >
            <FormattedMessage id="MediaDiet.add" defaultMessage="Add" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

const MultipleQuestions = ({
  question,
  setOptions,
  answer,
  required,
  setRequired,
  setDisabled,
}) => {
  return (
    <Container className="mt-3 mb-5">
      <Row>
        <Col className="h5">{question.text}</Col>
      </Row>

      {question.questions.map((q) => (
        <div key={q.title}>
          <Row className="my-2 text-left">
            <Col>{q.title}</Col>
          </Row>
          <Row
            className="my-2 text-left text-muted h6"
            style={{ fontSize: "0.75rem" }}
          >
            <Col>
              <FormattedMessage
                id="MediaDiet.atLeastOne"
                defaultMessage="Please Enter at Least 1"
              />
            </Col>
          </Row>
          <SingleQuestion
            type={q.type}
            choices={q.choices}
            prompt={q.prompt}
            setAnswer={setOptions}
            answer={answer}
            required={required}
            setRequired={setRequired}
            setDisabled={setDisabled}
          />
        </div>
      ))}
    </Container>
  );
};

export default MultipleQuestions;
