import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  withRouter,
  NavLink,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { Navbar, Nav, Container, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import './styles/navbar.css'
import About from './pages/About'
import Home from './Home'
import AdminPage from './pages/Admin/Admin'
import Experimentor from './pages/Admin/Experimenter'
import TeamProfile from './TeamProfile'
import MyProfile from './pages/Profile/MyProfile'
import EditSurvey from './pages/Admin/EditSurvey/EditSurvey'
import PrivacyPolicy from './pages/PrivacyPolicy'
import SurveyResult from './pages/SurveyResult/SurveyResult'
import StartSurvey from './pages/DisplaySurvey/StartSurvey'
import SurveyPage from './pages/SurveyPage/SurveyPage'
import axiosInstance from './axiosInterceptor'
import BugReportPage from './pages/BugReport/BugReportPage'
import VerifyEmailPage from './pages/Auth/VerifyEmail'
import ResetPasswordPage from './pages/Auth/ResetPassword'
import Links from './Links'
import RCSlider from './pages/Participant/rcSlider'
import INCASPage from './pages/INCAS'
import MediaDiet from './pages/MediaDiet/MediaDiet'
import { DataRequestPage } from './pages/DataRequest/DataRequestPage'
import Register from './components/Auth/Register'
import HomeNlpPage from './pages/NLP/HomeNlpPage'

const NavigationBar = () => {
  const location = useLocation()

  const [loggedOut, setLoggedOut] = useState(true)
  const [dataReqPermit, setDataReqPermit] = useState(true)
  const [role, setRole] = useState(null)
  const [email, setEmail] = useState(null)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token) {
      setLoggedOut(false)
      setRole(user.role)
      setEmail(user.email)
    }
    // const fetchData = async () => {
    //   try {
    //     if (!user?.email) {
    //       return
    //     }
    //     const resp = await axiosInstance().get('user/checkDataRequest')
    //     setDataReqPermit(resp.data.requestAllow)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }
    // fetchData()
  }, [location])

  const getState = () => {
    const whichUser = JSON.parse(localStorage.getItem('user'))
    if (!role) {
      setRole(whichUser.role)
    }
    if (role === 'participant') {
      return (
        <SurveyPage
          email={email}
          role="participant"
          dataReqPermit={dataReqPermit}
        />
      )
    } else if (role === 'experimenter') {
      return <Experimentor email={email} role="experimenter" />
    } else if (role === 'admin') {
      return <AdminPage email={email} role="admin" />
    } else {
      return (
        <div style={{ marginTop: '100px' }}>
          <FormattedMessage
            id="Navigation.tryAgain"
            defaultMessage="Please try again after logging in"
          />
        </div>
      )
    }
  }

  // Logout Functionality
  const logout = async () => {
    localStorage.removeItem('user')
    let refreshToken = localStorage.getItem('refreshToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('access_token')
    localStorage.removeItem('oauth_token')
    localStorage.removeItem('portal')

    await axiosInstance().delete('auth/logout', {
      refreshToken,
    })
    setLoggedOut(true)
  }

  return (
    <Container fluid className="text-center">
      <Row style={{ height: '60px' }} className="mb-3">
        <Navbar
          bg="dark"
          expanded={expanded}
          expand="md"
          variant="dark"
          fixed="top"
        >
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ml-auto"
            onClick={() =>
              setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))
            }
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" onClick={() => setExpanded(false)}>
              {email !== '' && !loggedOut && dataReqPermit ? (
                <>
                  <NavLink
                    to="/datarequest"
                    className="nav-link-class"
                    style={{ textDecoration: 'none ' }}
                    exact
                  >
                    <FormattedMessage
                      id="Navigation.datarequest"
                      defaultMessage="Data Request"
                    />
                  </NavLink>
                </>
              ) : (
                <span></span>
              )}
              {loggedOut && location.pathname != '/addEmail' && (
                <NavLink
                  to="/"
                  className="nav-link-class"
                  style={{ textDecoration: 'none ' }}
                  exact
                >
                  <FormattedMessage
                    id="Navigation.home"
                    defaultMessage="Home"
                  />
                </NavLink>
              )}
              {!loggedOut && role === 'participant' && (
                <NavLink
                  to="/"
                  className="nav-link-class"
                  style={{ textDecoration: 'none ' }}
                  exact
                >
                  <FormattedMessage
                    id="Navigation.surveys"
                    defaultMessage="Surveys"
                  />
                </NavLink>
              )}
              {!loggedOut && role !== 'participant' && (
                <NavLink
                  to="/"
                  className="nav-link-class"
                  style={{ textDecoration: 'none ' }}
                  exact
                >
                  <FormattedMessage
                    id="Navigation.surveys"
                    defaultMessage="Surveys"
                  />
                </NavLink>
              )}
              {location.pathname != '/addEmail' && (
                <NavLink
                  to="/about"
                  className="nav-link-class"
                  style={{ textDecoration: 'none ' }}
                  exact
                >
                  <FormattedMessage
                    id="Navigation.aboutus"
                    defaultMessage="About Us"
                  />
                </NavLink>
              )}
              {email !== '' && !loggedOut && role === 'participant' && (
                <>
                  <NavLink
                    to="/myProfile"
                    className="nav-link-class"
                    style={{ textDecoration: 'none ' }}
                    exact
                  >
                    <FormattedMessage
                      id="Navigation.profile"
                      defaultMessage="My Profile"
                    />
                  </NavLink>
                  {/* <NavLink
                    to="/mygroup"
                    className="nav-link-class"
                    style={{ textDecoration: "none " }}
                    exact
                  >
                    My Groups
                  </NavLink> */}
                </>
              )}
            </Nav>
            <Nav className="changeFloat" onClick={() => setExpanded(false)}>
              <NavLink
                to="/bugreport"
                className="nav-link-class logoutClass"
                style={{ textDecoration: 'none ' }}
                exact
              >
                <FormattedMessage
                  id="Navigation.reportbug"
                  defaultMessage="Report Bug"
                />
              </NavLink>
            </Nav>
            {!loggedOut ? (
              <Nav className="changeFloat" onClick={() => setExpanded(false)}>
                <NavLink
                  to="/"
                  onClick={() => logout()}
                  className="nav-link-class logoutClass"
                  style={{ textDecoration: 'none ' }}
                  exact
                >
                  <FormattedMessage
                    id="Navigation.logout"
                    defaultMessage="Logout"
                  />
                </NavLink>
              </Nav>
            ) : (
              <span></span>
            )}
          </Navbar.Collapse>
        </Navbar>
      </Row>
      <Row style={{ minHeight: '90vh' }}>
        <Switch>
          <Route exact path="/">
            {loggedOut && (
              <Home
                loggedOut={loggedOut}
                setTab={(email, role) => {
                  setEmail(email)
                  setRole(role)
                  setLoggedOut(false)
                }}
              />
            )}
            {!loggedOut && getState()}
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/myProfile">
            <MyProfile role={role} email={email} logout={logout} />
          </Route>
          <Route path="/auth/reset">
            <ResetPasswordPage />
          </Route>
          <Route path="/auth/verify">
            <VerifyEmailPage />
          </Route>
          <Route
            path="/teamProfile/:name"
            render={(props) => <TeamProfile {...props} />}
          />
          {/* <Route path="/mygroup" render={(props) => <ManageGroups />} />
                    <Route path="/join-group/:id">
                        <JoinGroupPage />
                    </Route>
                    <Route path="/group/:id">
                        <ViewGroupPage />
                    </Route> */}

          <Route
            path="/privacy"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <Route path="/result/:id">
            <SurveyResult />
          </Route>
          <Route path="/start/media_diet">
            <MediaDiet />
          </Route>
          <Route path="/start/:id/:type?">
            <StartSurvey />
          </Route>
          <Route path="/try">
            <RCSlider />
          </Route>
          <Route
            path="/mapyourmorals"
            component={() => {
              window.location.href = 'https://map.yourmorals.org'
              return null
            }}
          />
          <Route
            path="/explore.php"
            component={() => {
              window.location.href = 'https://yourmorals.org'
              return null
            }}
          />
          <Route path="/datarequest">
            <DataRequestPage />
          </Route>
          <Route path="/bugreport">
            <BugReportPage />
          </Route>
          <Route path="/create">
            <EditSurvey create={true} role={role} />
          </Route>
          <Route path="/edit/:id">
            <EditSurvey role={role} />
          </Route>
          <Route path="/requestdata/register">
            <Container fluid className="text-center">
              <Register hide={false} isDataReqUser={true} />
            </Container>
          </Route>
          <Route path="/nlp">
            <HomeNlpPage />
          </Route>
        </Switch>
      </Row>
      <Row className="mt-4">
        <Links />
      </Row>
    </Container>
  )
}

export default withRouter(NavigationBar)
