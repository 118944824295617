import { useState, useEffect } from "react";
import { useParams, withRouter, useHistory } from "react-router-dom";
import publicIp from "react-public-ip";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DOMPurify from "dompurify";
import axiosInstance from "../../axiosInterceptor";
import UserAgreement from "./UserAgreement";
import * as Survey from "survey-react";

import {
  IntlProvider,
  FormattedMessage,
  useIntl,
  defineMessage,
} from "react-intl";

import "survey-react/modern.css";
import "../../styles/survey.css";
import "./DisplaySurvey.css";

const language = navigator.language.split(/[-_]/)[0];

Survey.StylesManager.applyTheme("modern");

const StartSurvey = () => {
  const history = useHistory();

  const { id,type } = useParams();//undefined, reset
  const [survey, setSurvey] = useState(null);
  const [ip, setIp] = useState(null);
  const [broswerLocation, setBroswerLocation] = useState("not supported");
  const [startTime, setStartTime] = useState(Date.now());
  const [questions, setQuestions] = useState([]);
  const [surveyModel, setSurveyModel] = useState(null);
  const [savedData, setSurveySavedData] = useState(null);
  const [surveyState, setSurveyState] = useState("needAgreement");
  const [missingCount, setMissingCount] = useState(0);

  const intl = useIntl();
  const messages = defineMessage({
    plzAnswer: {
      id: "Start.plzAnswer",
      defaultMessage: "Required: Please answer this question.",
    },
    plzAnswerOptional: {
      id: "Start.plzAnswerOptional",
      defaultMessage: "Please answer this question (optional).",
    },
    requiredMissing: {
      id: "Start.missingPrompt",
      defaultMessage: "Required questions missing: ",
    },
    optionalMissing: {
      id: "Start.missingPromptOptional",
      defaultMessage: "Optional questions missing: ",
    },
    clickOk: {
      id: "Start.clickOk",
      defaultMessage: "\nClick OK to answer the questions.",
    },
    clickCancel: {
      id: "Start.clickCancel",
      defaultMessage: "\nClick Cancel to answer the questions.",
    },
    clickOkMissing: {
      id: "Start.clickOkMissing",
      defaultMessage: "\nClick OK to submit with missing responses.",
    },
    urMissing: {
      id: "Start.urMissing",
      defaultMessage: "You are missing: ",
    },
    urMissingScore: {
      id: "Start.urMissingScore",
      defaultMessage:
        "\n\nYou must answer all questions to receive a score.\nSelect OK to exit the survey without receiving a score.\nClick Cancel to go back and complete these questions.",
    },
  });

  const sample = (array, size = 1) => {
    const { floor, random } = Math
    let sampleSet = new Set()
    for (let i = 0; i < size; i++) {
      let index
      do {
        index = floor(random() * array.length)
      } while (sampleSet.has(index))
      sampleSet.add(index)
    }
    return [...sampleSet].map((i) => array[i])
  }

  const askLocation = (params) => {
    if (params[0]) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          setBroswerLocation(pos);
          console.log(pos);
        });
      } else {
        setBroswerLocation("not supported");
      }
    } else {
      setBroswerLocation("denied");
    }
  };

  Survey.FunctionFactory.Instance.register("askLocation", askLocation);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance().get("survey/" + id);
        
        const q = [];
        response.data.survey.data.pages.forEach((p) => {
          p.elements.forEach((e) => {
            if (e.type !== "html") {
              q.push(e.name);
            }
          });
        });
        let newPages = []
        if(!response.data.survey.subSettingEnabled) {
          setQuestions(q)
          setSurvey(response.data.survey)
          newPages = response.data.survey.data
        }
        else {
          let subsetCount = response.data.survey.subSettingValue
          setMissingCount(q.length - subsetCount)
          let allQuestions = []
          for (let i = 0; i < response.data.survey.data.pages.length; i++) {
            let page = response.data.survey.data.pages[i]
            allQuestions = allQuestions.concat(page.elements)
          }
          let randomSample = sample(allQuestions, subsetCount)
          let questionNames = randomSample.map((q) => q.name)
          let pageFormat = {
            name: 'page1',
            elements: randomSample,
          }
          let sampledResponse = JSON.parse(JSON.stringify(response))
          setQuestions(questionNames)
          sampledResponse.data.survey.data.pages = [pageFormat]
          setSurvey(sampledResponse.data.survey)
          newPages = sampledResponse.data.survey.data
        }
        let pages = newPages
        if(type == "reset"){
          let savedPage =  await axiosInstance().get("auth/getSavedMdq");
          setSurveySavedData(savedPage.data.data)
          console.log("savedPage:"+JSON.stringify(savedPage.data.data))
        }
        // console.log("newPages:"+JSON.stringify(pages))
        if (id === "MDQ1") {
          pages["triggers"] = [
            {
              type: "runexpression",
              expression: "{location} notempty",
              runExpression: "askLocation({location})",
            },
          ];
        }
        setSurveyModel(new Survey.Model(pages));
      } catch (err) {
        console.log("fetchData:"+err)
      }
      try {
        const ipv4 = (await publicIp.v4()) || "";
        setIp(ipv4);
      } catch (err) {
        console.log("setIp:"+err)
      }
    };
    fetchData();
  }, [id]);

  const sendDataToServer = async (survey) => {
    const responses = { ...survey.data };
    console.log("sendDataToServer responses:"+JSON.stringify(responses))

    try {
      if (id === "MDQ1") {
        if (!survey.data.birthmonth) {
          responses["birthdate"] = new Date(survey.data.birthyear, 0);
        } else {
          responses["birthdate"] = new Date(
            new Date(survey.data.birthyear, survey.data.birthmonth, 1)
          );
        }
        function cloneAsObject(obj) {
          if (obj === null || !(obj instanceof Object)) {
            return obj;
          }
          var temp = obj instanceof Array ? [] : {};
          // ReSharper disable once MissingHasOwnPropertyInForeach
          for (var key in obj) {
            temp[key] = cloneAsObject(obj[key]);
          }
          return temp;
        }
        responses["location"] = cloneAsObject(broswerLocation);
      }
      await axiosInstance().post("survey/" + id, {
        responses: responses,
        clientIp: ip,
        startTimestamp: startTime,
        endTimestamp: Date.now(),
      });

      if (id === "MDQ1" || id === "mfq" || id === "LazyTemp") {
        history.push("/");
      } else {
        history.push("/result/" + id);
      }
    } catch (err) {
      console.log("sendDataToServer:"+err);
    }
  };

  const onCurrentPageChanged = (survey) => {
    window.scrollTo(0, 0);
  };

  const surveyValidateQuestion = async (survey, options) => {
    if (!survey.isLastPage) {
      options.complete();
      return;
    }
    // return;
    /* fetch names of questions that the participant did not respond to */
    try {
      const res = await axiosInstance().post("survey/confirm/" + id, {
        responses: survey.data, // use survey.data instead of options.data
      });
      let missing = res.data.missing;
      if (missing) {
        // submit responses if there are no missing questions
        if (missing.length === 0 || missing.length === missingCount) {
          // followng logic is to implement the subsetting feature - fix the results bug
          if (missing.length > 0) {
            for (let i = 0; i < missing.length; i++) {
              survey.valuesHash[missing[i]] = null
            }
          }
          sendDataToServer(survey);
          options.complete();
          return;
        }

        if (id === "MDQ1") {
          let missingBirthyear = missing.includes("birthyear");
          let missingBirthmonth = missing.includes("birthmonth");
          let birthmonthRequired = survey.data.birthyear >= 2003;

          let optionalMissing = [];

          let requiredMissing = [];
          let j = 0;
          if (missing.includes("birthCountry")) missing.push("birthCountry");
          if (missing.includes("birthState")) missing.push("birthState");
          if (missing.includes("currentCountry"))
            missing.push("currentCountry");
          if (missing.includes("currentState")) missing.push("currentState");

          for (; j < missing.length; j++) {
            if (
              missing[j] !== "birthyear" &&
              missing[j] !== "birthmonth" &&
              missing[j] != null
            ) {
              optionalMissing.push(missing[j]);
            } else {
              if (missing[j] === "birthmonth" && birthmonthRequired)
                requiredMissing.push(missing[j]);
            }
          }

          if (!missingBirthyear && optionalMissing.length === 0) {
            if (!missingBirthmonth || !birthmonthRequired) {
              sendDataToServer(survey);
              options.complete();
              return;
            }
          }

          const MDQprompts = constructMDQPrompt(
            requiredMissing,
            optionalMissing,
            birthmonthRequired
          );

          if (requiredMissing.length !== 0) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <IntlProvider locale={language}>
                    <Card style={{ width: "25rem" }} className="confirmCard">
                      <Card.Body>
                        <Card.Title>
                          <FormattedMessage
                            id="Start.missing"
                            defaultMessage="Missing Question"
                          />
                        </Card.Title>
                        <Card.Text>
                          {MDQprompts.map((p) => (
                            <strong>{p}</strong>
                          ))}
                        </Card.Text>
                        <Container fluid>
                          <Row>
                            <Col xs={6} className="text-right">
                              <Button
                                variant="outline-success"
                                className="w-100"
                                onClick={() => {
                                  if (missingBirthyear) {
                                    options.errors["birthyear"] =
                                      intl.formatMessage(messages.plzAnswer);
                                  }
                                  if (missingBirthmonth && birthmonthRequired) {
                                    options.errors["birthmonth"] =
                                      intl.formatMessage(messages.plzAnswer);
                                  }
                                  if (optionalMissing.length !== 0) {
                                    let i = 0;
                                    for (; i < optionalMissing.length; i++) {
                                      options.errors[optionalMissing[i]] =
                                        intl.formatMessage(
                                          messages.plzAnswerOptional
                                        );
                                    }
                                  }

                                  options.complete();
                                  onClose();
                                }}
                              >
                                <FormattedMessage
                                  id="Start.ok"
                                  defaultMessage="OK"
                                />
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </IntlProvider>
                );
              },
            });
          } else {
            confirmAlert({
              onClickOutside: () => {
                if (missingBirthyear) {
                  options.errors["birthyear"] =
                    intl.formatMessage(messages.plzAnswer);
                }
                if (missingBirthmonth && birthmonthRequired) {
                  options.errors["birthmonth"] =
                    intl.formatMessage(messages.plzAnswer);
                }
                if (optionalMissing.length !== 0) {
                  let i = 0;
                  for (; i < optionalMissing.length; i++) {
                    options.errors[optionalMissing[i]] =
                      intl.formatMessage(
                        messages.plzAnswerOptional
                      );
                  }
                }
                options.complete();
              },
              customUI: ({ onClose }) => {
                return (
                  <IntlProvider locale={language}>
                    <Card style={{ width: "25rem" }} className="confirmCard">
                      <Card.Body>
                        <Card.Title>
                          <FormattedMessage
                            id="Start.missing"
                            defaultMessage="Missing Question"
                          />
                        </Card.Title>
                        <Card.Text>
                          {MDQprompts.map((p,index) => (
                            <strong>{p}</strong>
                          ))}
                        </Card.Text>
                        <Container fluid>
                          <Row>
                            <Col xs={6} className="text-left">
                              <Button
                                className="ml-auto w-100"
                                variant="outline-danger"
                                onClick={() => {
                                  options.complete();
                                  sendDataToServer(survey);

                                  onClose();
                                }}
                              >
                                <FormattedMessage
                                  id="Start.ok"
                                  defaultMessage="OK"
                                />
                              </Button>
                            </Col>
                            <Col xs={6} className="text-right">
                              <Button
                                variant="outline-success"
                                className="w-100"
                                onClick={() => {
                                  if (missingBirthyear) {
                                    options.errors["birthyear"] =
                                      intl.formatMessage(messages.plzAnswer);
                                  }
                                  if (missingBirthmonth && birthmonthRequired) {
                                    options.errors["birthmonth"] =
                                      intl.formatMessage(messages.plzAnswer);
                                  }
                                  if (optionalMissing.length !== 0) {
                                    let i = 0;
                                    for (; i < optionalMissing.length; i++) {
                                      options.errors[optionalMissing[i]] =
                                        intl.formatMessage(
                                          messages.plzAnswerOptional
                                        );
                                    }
                                  }

                                  options.complete();
                                  onClose();
                                }}
                              >
                                <FormattedMessage
                                  id="Start.cancel"
                                  defaultMessage="Cancel"
                                />
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </IntlProvider>
                );
              },
            });
          }
        } else {
          const prompt = constructPrompt(missing);
          confirmAlert({
            onClickOutside: () => {
              let i = 0;
              for (; i < missing.length; i++) {
                options.errors[missing[i]] =
                  "Please answer the question.";
              }
              console.log("You pressed Cancel outside!");
              options.complete();
            },
            customUI: ({ onClose }) => {
              return (
                <IntlProvider locale={language}>
                  <Card style={{ width: "25rem" }} className="confirmCard">
                    <Card.Body>
                      <Card.Title>
                        <FormattedMessage
                          id="Start.missing"
                          defaultMessage="Missing Question"
                        />
                      </Card.Title>
                      <Card.Text>{prompt}</Card.Text>
                      <Container fluid>
                        <Row>
                          <Col xs={6} className="text-left">
                            <Button
                              className="ml-auto w-100"
                              variant="outline-danger"
                              onClick={() => {
                                options.complete();
                                sendDataToServer(survey);
                                onClose();
                              }}
                            >
                              <FormattedMessage
                                id="Start.ok"
                                defaultMessage="OK"
                              />
                            </Button>
                          </Col>
                          <Col xs={6} className="text-right">
                            <Button
                              variant="outline-success"
                              className="w-100"
                              onClick={() => {
                                let i = 0;
                                for (; i < missing.length; i++) {
                                  options.errors[missing[i]] =
                                    "Please answer the question.";
                                }
                                console.log("You pressed Cancel!");
                                options.complete();
                                onClose();
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                </IntlProvider>
              );
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const constructMDQPrompt = (
    requiredMissing,
    optionalMissing,
    birthMonthRequired
  ) => {
    const prompts = [];
    let prompt = "";

    if (requiredMissing.length !== 0) {
      prompt += intl.formatMessage(messages.requiredMissing);

      let i = 0;
      const indexes = [];

      for (; i < questions.length; i++) {
        if (requiredMissing.includes(questions[i])) {
          indexes.push(i + 1);
        }
      }
      prompt += indexes.join(", ");
    }

    if (optionalMissing.length !== 0) {
      if (requiredMissing.length !== 0) {
        prompt += "\n";
      }

      prompt += intl.formatMessage(messages.optionalMissing);

      let i = 4;
      const indexes = [];
      if (optionalMissing.includes("birthCountry")) indexes.push(1);
      if (optionalMissing.includes("birthState")) indexes.push(2);
      if (optionalMissing.includes("currentCountry")) indexes.push(3);
      if (optionalMissing.includes("currentState")) indexes.push(4);
      for (; i < questions.length; i++) {
        if (optionalMissing.includes(questions[i])) {
          if (birthMonthRequired) {
            indexes.push(i + 3);
          } else {
            indexes.push(i + 2);
          }
        }
      }
      prompt += indexes.join(", ");
    }
    prompts.push(prompt);
    prompt = "";
    if (requiredMissing.length > 0) {
      prompts.push(intl.formatMessage(messages.clickOk));
    } else if (requiredMissing.length === 0 && optionalMissing.length > 0) {
      prompts.push(intl.formatMessage(messages.clickCancel));
      prompts.push(intl.formatMessage(messages.clickOkMissing));
    }

    return prompts;
  };

  const constructPrompt = (missing) => {
    let prompt = intl.formatMessage(messages.urMissing);

    let i = 0;
    const indexs = [];

    for (; i < questions.length; i++) {
      if (missing.includes(questions[i])) {
        indexs.push(i + 1);
      }
    }
    prompt += indexs.join(", ");
    prompt += intl.formatMessage(messages.urMissingScore);
    return prompt;
  };

  const startSurvey = () => {
    setSurveyState("started");
    setStartTime(Date.now());
  };

  const getDisplay = () => {
    if (surveyState === "needAgreement") {
      return <UserAgreement surveyId={id} startSurvey={startSurvey} />;
    } else if (surveyState === "started") {
      if(type == "reset") surveyModel.data = savedData
      return (
        <div className="text-left">
          <Survey.Survey
            model={surveyModel}
            onServerValidateQuestions={surveyValidateQuestion}
            onCurrentPageChanged={onCurrentPageChanged}
          />
        </div>
      );
    } else {
      return <div>default</div>;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {survey && (
            <div>
              <h2 className="mt-4 text-center">Survey: {survey.name}</h2>
              <br />
              <h3 className="text-center px-5">{survey.description}</h3>
              {surveyState === "started" && (
                <div
                  key="1"
                  style={{ textAlign: "left" }}
                  className="px-5 mt-3 text-muted"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(survey.instructions),
                  }}
                />
              )}
            </div>
          )}
          {getDisplay()}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(StartSurvey);
