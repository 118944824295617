
import { useState, useEffect } from "react";
import axiosInstance from "../../axiosInterceptor";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import styled, { createGlobalStyle, keyframes, css } from "styled-components";


const Wrapper = styled(Container)`
    background-color:#F8F3EF;
    font-family: Verdana;
    width: 100%;
    max-width: 1400px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    ${'' /* font-family: IBM Plex Mono,monospace; */}

    & h1{
        font-family: Verdana;
        font-size: 5vh;
        @media (min-width: 520px) {
            font-size: 4vh;
        }
    }
    .NLP_head{
        border: 4px solid black;
        width: fit-content;
        margin:1vh auto 2vh auto;
        @media (min-width: 520px) {
            margin:1vh 8vh 2vh auto;
        }
        padding:5px 2vh
    }
`

const MolaButton = styled.button`
    font-size: 1.2rem;
    background-color:transparent;
    border: 3px solid black;
    margin-top:15px;
    padding:"0.5rem";
    &:hover{
        ${'' /* opacity: 1.2; */}
        transform: scale(1.01);
        background-color:white;
        
    }
`

const LeftSection = styled.div`
    ${'' /* display: flex; */}
    ${'' /* text-align:left; */}
    border: 4px solid black;
    text-align: left;
    padding:2vh;
    margin:4vh auto 6vh auto;
    @media (min-width: 520px) {
        margin:4vh 8vh 6vh 8vh;
    }
    .description{
        font-size:1.2rem;
        overflow: hidden;
    }
`

const CardWrapper = styled.div`
    border: 4px solid black;
    ${'' /* border: 3px solid green; */}
    text-align: left;
    padding:2vh;
    margin:4vh auto 6vh auto;
    @media (min-width: 520px) {
        margin:4vh 8vh 6vh 8vh;
    }
`

const NewCard = styled(Card)`
    min-width:"10vh";
    border: 4px solid black;
    font-color:black;
    ${'' /* display: none; */}
    @media (max-width: 820px) {
            display: none;
    }
    
    &.notLast{
        display: block;
    }
    &.notLast:hover {
        background-color: #ec8755;
        -webkit-transform: scale(1.01);
        transform: scale(1.01);
    }
 
`

const NewImage = styled(Card.Img)`
   height: "10vh";
   max-width:"120px" ;
   ${'' /*maxWidth:"100px"; */}
   object-fit:"cover";
`

const HomeNlpPage =()=>{
    const otherProj = [
        {title:"MFRC",des:"Moral foundation - reddit comments",desUrl:"https://huggingface.co/datasets/USC-MOLA-Lab/MFRC",imgUrl:"https://www.shareicon.net/data/256x256/2016/07/13/606926_reddit_4096x4096.png"},
        {title:"MFTC",des:"Moral foundation - twitter posts",desUrl:"https://huggingface.co/datasets/USC-MOLA-Lab/MFRC",imgUrl:"https://www.shareicon.net/data/256x256/2017/05/26/886465_logo_512x512.png"},
        {title:"Jigsaw",des:"Comments VO CV HD",desUrl:"https://huggingface.co/datasets/USC-MOLA-Lab/MFRC",imgUrl:"https://www.shareicon.net/data/256x256/2016/07/13/606404_google_512x512.png"},
        {title:"Building..",des:".......",desUrl:"/",imgUrl:"https://www.shareicon.net/data/256x256/2016/12/21/865508_it_512x512.png"},

]
    return (        
        <Wrapper >
        <div className="NLP_head">
            <h1>
                NLP
            </h1>
            <h1 >
                MOLA
            </h1>
        </div>

        {/* <div className="NLP_SOTA text-left mt-4" style={{display: "flex","margin-x":"20px"}}> */}
        <LeftSection>
        {/* <div> */}
            <h2>
                SOTA MODEL
            </h2>
            <div className="description" >
                        This is a longer card with supporting text below as a natural
                        lead-in to additional content. This content is a little bit
                        longer.ddddddddddddddddddddssssssss
             </div>
             <MolaButton >Read more</MolaButton>
             {/* <a href="https://huggingface.co/" target="_blank" type="button" class="btn btn-outline-info">Read More</a>       */}
        {/* </div> */}
        </LeftSection>

        <CardWrapper>
            <h2>
                OUR PROJECTS
            </h2>
            <div className="text-truncate" style={{fontSize: "1.2rem"}} >
                        Explore current projects of MOLA!!!
             </div>
            <Row xs={2} md={4} >
            {otherProj.map((proj,index) => (
                <Col className="mt-2">
                    <NewCard className={index==otherProj.length-1 ?"last" : "notLast" }>
                    <a href={proj.desUrl} target={"_blank"} text-decoration-none >
                        <NewImage  variant="top" src={proj.imgUrl} />
                    </a>
                        <Card.Body>
                        <Card.Title style={{fontSize: "1.5rem",width: "fit-content"}}>  {proj.title} </Card.Title>
                        <div className="text-truncate" style={{fontSize: "1rem"}}  >
                        {proj.des}
                        </div>
                        </Card.Body>
                        {/* <a href="#" class="btn btn-primary stretched-link">Read More</a> */}

                    </NewCard>
                </Col>
                
            ))}
            </Row>
        </CardWrapper>
        </Wrapper>
      );
}

export default HomeNlpPage;