import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Collapse } from "react-bootstrap";

import axiosInstance from "../../../axiosInterceptor";
import FileTable from "./FileTable";
import styles from "../Admin.module.css";

const DownloadData = ({ allSurveys }) => {
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  // const [disabled, setDisabled] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [oldDownload, setOldDownload] = useState(false);
  const [newDownload, setNewDownload] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axiosInstance().get("admin/list/new");
      // console.log(result.data.downloads);
    };
    fetchData();
  }, []);

  if (allSurveys.length === 0) {
    return <Container></Container>;
  }

  const select = (selected) => {
    selected = JSON.parse(selected);
    console.log(selected);
    if (selected.id === "all") {
      setSelectedSurvey([{ name: "All Surveys", id: "all" }]);
      return;
    }
    setSelectedSurvey([...selectedSurvey, selected]);
  };

  const remove = (id) => {
    setSelectedSurvey(selectedSurvey.filter((s) => s.id !== id));
  };

  const download = async () => {
    setAlertMsg(null);
    if (selectedSurvey.length < 1) {
      return;
    }
    const ids = selectedSurvey.map((s) => s.id);

    try {
      let surveysRequired = ids;
      if (ids[0].id == "all") {
        surveysRequired = "all";
      }
      await axiosInstance().post("admin/downloadable", {
        surveysRequired: surveysRequired,
      });
      setAlertMsg("Success, we will send you an Email with the download link");
    } catch (err) {}
  };
  return (
    <Container className={styles.backgroundSection + " py-2"}>
      <Row className="my-4">
        <Col>
          <Button
            variant="outline-info"
            className="w-75"
            onClick={() => setNewDownload(!newDownload)}
          >
            View Download Data
          </Button>
        </Col>
      </Row>
      <Collapse in={newDownload}>
        <Row>
          <Col>
            <FileTable showing={newDownload} type={"new"} />
          </Col>
        </Row>
      </Collapse>
      <Row className="my-4">
        <Col>
          <Button
            variant="outline-info"
            className="w-75"
            onClick={() => setOldDownload(!oldDownload)}
          >
            View Archived Download Data
          </Button>
        </Col>
      </Row>
      <Collapse in={oldDownload}>
        <Row>
          <Col>
            <FileTable showing={oldDownload} type={"old"} />
          </Col>
        </Row>
      </Collapse>

      {/* <Row>
        <Col>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select Surveys to Download</Form.Label>
            <Form.Control as="select" onChange={(e) => select(e.target.value)}>
              <option key="-1"></option>
              <option
                key="all"
                value={JSON.stringify({ id: "all", name: "All surveys" })}
              >
                ALL
              </option>

              {allSurveys.map((s, idx) => (
                <option
                  key={idx}
                  value={JSON.stringify({ id: s.id, name: s.name })}
                >
                  {s.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="d-grid mb-2">
          <Button
            variant="outline-success"
            size="lg"
            disabled={disabled}
            onClick={download}
          >
            Get Survey
          </Button>
        </Col>
      </Row>
      {alertMsg && (
        <Row>
          <Col>
            <Alert variant="success">{alertMsg}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ListGroup>
            {selectedSurvey.map((s) => (
              <ListGroupItem>
                {s.name}
                <AiOutlineClose className="ml-5" onClick={() => remove(s.id)} />
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row> */}
    </Container>
  );
};

export default DownloadData;
