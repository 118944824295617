import { useCallback, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import axiosInterceptor from "../../../axiosInterceptor";
import styles from "../Admin.module.css";

const ReportEntry = ({
  id,
  email,
  time,
  setId,
  setModalShow,
  resolved,
  fetchReportData,
}) => {
  const [resolved_, setResolved] = useState(resolved);
  const resolveCall = useCallback(
    async (value) => {
      try {
        const resp = await axiosInterceptor().post(`report/resolve/${id}`, {
          value: value,
        });
        // fetchReportData();
      } catch (err) {
        console.log(err);
      }
    },
    [id]
  );
  time = time.toLocaleString("en-US");
  return (
    <Container className={`${styles.surveylist} my-2`}>
      <Row className={`py-2 px-4 ${styles.rowHeight} align-items-center`}>
        <Col xs={4} className="email">
          {email}
        </Col>
        <Col xs={4} className="d-none d-sm-block">
          {time.toString()}
        </Col>
        <Col className={`${styles.text} ${styles.rowHeight} text-muted`}>
          <Button
            variant="outline-warning"
            onClick={() => {
              setId(id);
              setModalShow(true);
            }}
          >
            Detail
          </Button>
        </Col>
        <Col>
          <Form.Check
            type={"checkbox"}
            label={"Resolved"}
            id={`disabled`}
            checked={resolved_}
            onChange={(e) => {
              setResolved(e.target.checked);
              resolveCall(e.target.checked);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ReportEntry;
