import React, { useCallback, useEffect, useState,useMemo } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import BarChart from './BarChart';
import axiosInterceptor from '../../../axiosInterceptor';
import { confirmAlert } from 'react-confirm-alert'; // Import
import CustomConfirm from '../../../components/ConfirmAlert/CustomConfirm';
import ConfirmCancelAlert from '../../../components/ConfirmAlert/ConfirmCancelAlert';

import styles from '../Admin.module.css';
import DetailChart from './DetailChart';
import HoriBarChaer from './HoriBarChart';

const INCASChart = () => {
    const [results, setResults] = useState(null);
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: '# of Incas Surveys',
                data: [],
                fill: true,
                backgroundColor: 'rgb(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132)'
            }
        ]
    });
    const [link, setLink] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numberGiftCard, setNumberGiftCard] = useState(null);
    const [policyObj, setPolicyObj] = useState({
        faceVals: [{}], //types of face value
        countOpt: null, //remain opt cards
        countRequired: null, //remain required cards
        optCardVal: null, //face value of opt card
        reqCardVal: null, //face value of required card
        optCardCopy: null, //number of opt card to send
        reqCardCopy: null, //number of required card to send
        isEnoughCards: false,
    });
    const [incasUsers, setIncasUsers] = useState({
        numConsentAccept:0,
        users:[]
    });
    const [typeCardPolicy, setTypePolicy] = useState("required");
    const [valCardPolicy, setValPolicy] = useState(0);
    const [numCardPolicy, setNumPolicy] = useState(0);
    const fetchData = async () => {
        try {
            const responseP = await axiosInterceptor().get(
                "admin/incas_giftcardPolicy"
            );
            if (!responseP){
                return;
            }
            console.log("fetchData yes responseP");
            setPolicyObj({
                faceVals: responseP.data.faceVals,
                countOpt: responseP.data.stat.countOpt,
                countRequired: responseP.data.stat.countRequired,
                optCardVal: responseP.data.stat.valueOpt,
                reqCardVal: responseP.data.stat.valueRequired,
                optCardCopy: responseP.data.stat.numberOfCopiesOpt,
                reqCardCopy: responseP.data.stat.numberOfCopiesRequired,
                isEnoughCards: responseP.data.stat.isEnoughCards,
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!policyObj){
            return
        }
        // console.log("!!!!!!!!!!! faceVals," + policyObj.faceVals[0].value);
        // console.log("!!!!!!!!!!! optCardVal," + policyObj.optCardVal);
        setValPolicy(policyObj.faceVals[0].value);
    }, [policyObj]);

    const confirmNumber = async () => {
        try {
            if (numberGiftCard < 1 || numberGiftCard > 5) {
                return;
            }
            // console.log(numberGiftCard);
            // const resp = await axiosInterceptor().post(
            //     'admin/change_giftcard_number',
            //     { copies: numberGiftCard }
            // );
            // console.log(resp);
            fetchData();
        } catch (err) {
            console.log(err);
        }
    };

    const editPlicy = async () => {
        try {
            console.log("--------------")
            console.log("typeCardPolicy:" + typeCardPolicy);
            console.log("valCardPolicy:" + valCardPolicy);
            console.log("numCardPolicy:" + numCardPolicy);
            console.log("--------------")
            const resp = await axiosInterceptor().post("admin/editPolicy", {
                newType: typeCardPolicy,
                newFaceVal: valCardPolicy,
                newCopies: numCardPolicy,
            });
            fetchData();//update info in the page
        } catch (err) {
            console.log(err);
        }
    };

    const editAction = async () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <ConfirmCancelAlert
                        title={`Warning! Giftcard Policy will Change`}
                        onClose={() => {
                            onClose();
                        }}
                        onConfirm={() => editPlicy()}
                        text="Click Ok to confirm"
                    />
                );
            },
        });
    };
    const getDownloadLink = useCallback(async (type) => {
        try {
            setDownloading(true);
            const response = await axiosInterceptor().get(
                'admin/download_incas?researchType=' + type
            );
            downloadAlert(response.data.url);
        } catch (err) {
            console.log(err);
        } finally {
            setDownloading(false);
        }
    }, []);

    const downloadAlert = useCallback((url) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomConfirm
                        title="Download Link"
                        onClose={onClose}
                        text="Please click to start downloading."
                    >
                        <Container fluid className="p-0">
                            <Row>
                                <Col xs={12}>
                                    <a
                                        href={url}
                                        // className="download-button text-dark"
                                        style={{ textDecoration: 'none' }}
                                        target="_blank"
                                    >
                                        <Button
                                            variant="outline-success"
                                            className="w-100"
                                            block
                                            onClick={() => {
                                                onClose();
                                                console.log(url);
                                            }}
                                        >
                                            Click Here to Download
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </CustomConfirm>
                );
            },
        });
    }, []);

    const getResults = useCallback(async () => {
        try {
            const resp = await axiosInterceptor().get('admin/incas_status');
            const results = resp.data.results;
            const labels = [];
            const vals = [];
            for (const [key, value] of Object.entries(results)) {
                labels.push(key);
                vals.push(value);
            }
            setData({
                labels: labels,
                datasets: [
                    {
                        label: '# of Incas Surveys',
                        data: vals,
                        fill: true,
                        backgroundColor: 'rgb(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132)'
                    }
                ]
            });
            
            const responseIncasUsers = await axiosInterceptor().get(
                "incas/getincasusers"
            );
            console.log("incas numConsentAccept:"+responseIncasUsers.data.numConsentAccept);

            setIncasUsers({
                numConsentAccept:responseIncasUsers.data.responseIncasUsers,
                users:responseIncasUsers.data.users
            })
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    },[]);

    useEffect(() => {
        setLoading(true);
        getResults();
    }, []);

    return (
        <>
            <Container className={styles.chart + " p-2"}>
                <Row>
                    <Col>
                        <BarChart data={data}/>
                    </Col>
                </Row>
            </Container>
            <Container className={styles.chart + " my-5 p-3"}>
                {" "}
                <Row>
                    <Col>
                        <Button
                            variant="outline-primary"
                            disabled={downloading}
                            onClick={() => {
                                getDownloadLink('incas');
                            }}
                        >
                            Get INCAS Survey Download
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            variant="outline-primary"
                            disabled={downloading}
                            onClick={() => {
                                getDownloadLink("incas-en");
                            }}
                        >
                            Get INCAS General Survey Download
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container className={styles.chart + " my-5 p-3"}>
                <Row>
                    <Col>
                        <HoriBarChaer data={incasUsers.users}/>
                    </Col>
                </Row>
            </Container>
            <Container className={styles.chart + ' p-2'}>
                <Row>
                    <Col>
                        <DetailChart />
                    </Col>
                </Row>
            </Container>
            
            {/* <Container className={styles.chart + ' my-5 p-3'}>
                <Row>
                    <Col xs={12} md={12}>
                        <Form>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>
                                    Set Number of Gift Cards to give
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={numberGiftCard || 2}
                                    onChange={(e) =>
                                        setNumberGiftCard(e.target.value)
                                    }
                                >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={12} md={12}>
                        <Button onClick={confirmNumber}>Set Number</Button> 
                    </Col>
                </Row>
            </Container> */}

            <Container className={styles.chart + " my-5 p-3"}>
            <div>
                <Row>
                    {policyObj.faceVals.map((faceVal, index) => {
                        return (
                            <Col  md={6}  key={faceVal.value}  >
                                 <span key={faceVal.value} className="badge badge-info">Remaining {faceVal.value}$ GC: {faceVal.num}</span>
                            </Col>
                        );
                    })}                 
                </Row>

                <Row>
                    <Col md={6}  >
                        <span className="badge badge-primary ">Required GC Rule: {policyObj.reqCardCopy} x {policyObj.reqCardVal}$</span>         
                    </Col>
                    <Col  md={6} className="inline">
                        <span className="badge badge-primary ">Optional GC Rule: {policyObj.optCardCopy} x {policyObj.optCardVal}$</span>
                    </Col>
                </Row>
                
                <Row>
                    <Col >
                        {policyObj.isEnoughCards ? 
                        <span  className="badge badge-success mx-2">incas.yourmorals status: running</span> :
                        <span  className="badge badge-warning mx-2">incas.yourmorals status: not enough card</span> }
                    </Col>
                </Row>
                </div>
                <div className="dropdown-divider"></div>
 {/*
                <Form>
                   <Row>
                        <Col xs={12} md={4}>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>Gift Card Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={typeCardPolicy}
                                    onChange={(e) => setTypePolicy(e.target.value)}
                                >
                                    <option value={"required"}>required</option>
                                    <option value={"optional"}>optional</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>Value of Each Gift Card</Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={valCardPolicy}
                                    onChange={(e) => setValPolicy(e.target.value)}
                                    // onChange={(e) => setPolicyObj()}
                                >
                                    {policyObj.faceVals.map((faceVal) => {
                                        return <option key={faceVal.value}
                                                       value={faceVal.value}>{faceVal.value}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>Number to Give</Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    value={numCardPolicy}
                                    onChange={(e) => setNumPolicy(e.target.value)}
                                >
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={12}>
                            <Button onClick={editAction} disabled={true}>Change GC Rule</Button>
                        </Col>
                    </Row>
                </Form>*/}
            </Container>
        </>
    );
};

export default INCASChart;
