import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Container, Row, Col } from "react-bootstrap";
import DndList from "../../components/DndList";
const RankingQuestion = ({ options, setOptions, title }) => {
  return (
    <Container>
      <Row>
        <Col className="h6">{title}</Col>
      </Row>
      <Row>
        <Col>
          <DndProvider backend={HTML5Backend}>
            <DndList cards={options} setCards={setOptions} />
          </DndProvider>
        </Col>
      </Row>
    </Container>
  );
};

export default RankingQuestion;
