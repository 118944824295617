import { Button, Row, Col, Card, Container } from "react-bootstrap";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const RedirectAlert = ({ onClose, title, text }) => {
  return (
    <Card style={{ width: "25rem" }} className="confirmCard">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Container fluid>
          <Row>
            <Col xs={6} className="text-left">
              <Button
                variant="outline-success"
                className="w-100"
                onClick={() => {
                  onClose();
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default RedirectAlert;
