import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import axiosInterceptor from "../../../axiosInterceptor";
import CustomConfirm from "../../../components/ConfirmAlert/CustomConfirm";
import DownloadCard from "./DownloadCard";
import { confirmAlert } from "react-confirm-alert"; // Import

const FileTable = ({ showing, type }) => {
  const [downloads, setDownloads] = useState([]);
  const [link, setLink] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const getDownloadLink = useCallback(async (_id) => {
    try {
      const response = await axiosInterceptor().get(
        "admin/download-link/" + _id
      );
      console.log("getDownloadLink _id:"+_id)
      // console.log("getDownloadLink:"+JSON.stringify(response.data))
      downloadAlert(response.data.url);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getNewDownloadLink = useCallback(async (surveyId, type) => {
    console.log(surveyId, type);
    try {
      setDownloading(true);
      const response = await axiosInterceptor().get(
        "admin/download/" + surveyId
      );
      console.log("getNewDownloadLink:"+response.data.url);
      if (type === "new") {
        getDownload();
      }
      downloadAlert(response.data.url);
    } catch (err) {
      console.log(err.response.status);
      if (err.response && err.response.status === 422) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <CustomConfirm
                title="Download too frequently,"
                onClose={onClose}
                text={
                  err.response.data.message
                    ? err.response.data.message
                    : "Please download the current file"
                }
              >
                <Container>
                  <Row className="mb-2">
                    <Col xs={12} className="mb-2">
                      <a
                        // className="download-button text-dark"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        <Button
                          variant="outline-success"
                          className="w-100"
                          block
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Click Here to Close
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </Container>
                ;
              </CustomConfirm>
            );
          },
        });
      }
    }
    setDownloading(false);
  }, []);

  const downloadAlert = useCallback((url) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomConfirm
            title="Download Link"
            onClose={onClose}
            text="Please click to start downloading."
          >
            <Container fluid className="p-0">
              <Row>
                <Col xs={12}>
                  <a
                    href={url}
                    // className="download-button text-dark"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Button
                      variant="outline-success"
                      className="w-100"
                      block
                      onClick={() => {
                        onClose();
                        console.log(url);
                      }}
                    >
                      Click Here to Download
                    </Button>
                  </a>
                </Col>
              </Row>
            </Container>
            ;
          </CustomConfirm>
        );
      },
    });
  }, []);

  const getDownload = useCallback(async () => {
    setLoading(true);
    try {
      const api = `admin/list/${type}`;
      const response = await axiosInterceptor().get(api);
      // console.log("getDownload:"+type+","+JSON.stringify(response.data))
      setDownloads(response.data.downloads);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [type]);

  useEffect(() => {
    if (showing) {
      getDownload();
    }
  }, [showing]);

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      {downloads.map((d) => (
        <DownloadCard
          key = {d.surveyId}
          name={d.name}
          description={d.description}
          createdAt={d.createdAt}
          surveyId={d.surveyId}
          type={d.type}
          _id={d._id}
          getLink={getDownloadLink}
          kind={type}
          getNew={getNewDownloadLink}
          downloading={downloading}
        />
      ))}
    </Container>
  );
};

export default FileTable;
