import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Container, Spinner } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { IconContext } from "react-icons";
import { MdLocationOn } from "react-icons/md";
import "jspdf-autotable";
import "../../styles/participant.css";
import Slider from "./slider";
import purityImg from "../../styles/purity.png";
import careImg from "../../styles/care.png";
import equalityImg from "../../styles/equality2.png";
import proportionalityImg from "../../styles/proportionality.png";
import ingroupImg from "../../styles/ingroupImg.png";
import newAuthority from "../../styles/authority.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./participant.css";
import axiosInterceptor from "../../axiosInterceptor";
import { FormattedMessage, useIntl, defineMessage } from "react-intl";

const PersonalityResults = ({ participantResult, participantMean, email }) => {
  const [loading, setLoading] = useState(false);
  const [home, setHome] = useState(true);
  const ref = useRef(null);
  const intl = useIntl();
  const messages = defineMessage({
    title: {
      id: "Personality.title",
      defaultMessage: "Your personality results",
    },
    personality: {
      id: "Personality.personality",
      defaultMessage: "Personality",
    },

    percentile: {
      id: "Personality.percentile",
      defaultMessage: "Percentile",
    },
    defintions: {
      id: "Personality.defintions",
      defaultMessage: "Definitions of the moral values",
    },
    care: {
      id: "Personality.care",
      defaultMessage: "Care",
    },
    equality: {
      id: "Personality.equality",
      defaultMessage: "Equality",
    },
    loyalty: {
      id: "Personality.loyalty",
      defaultMessage: "Loyalty",
    },
    authority: {
      id: "Personality.authority",
      defaultMessage: "Authority",
    },
    purity: {
      id: "Personality.purity",
      defaultMessage: "Purity",
    },
    proportionality: {
      id: "Personality.proportionality",
      defaultMessage: "Proportionality",
    },
    careText: {
      id: "Personality.careText",
      defaultMessage:
        "Concerns regarding care and protecting individuals from harm",
    },
    equalityText: {
      id: "Personality.equalityText",
      defaultMessage:
        "Equality is a psychological motive for balanced reciprocity, equal treatment, equal say, and equal outcome.",
    },
    loyaltyText: {
      id: "Personality.loyaltyText",
      defaultMessage:
        "Concerns regarding loyalty to others, self-sacrifice, and patriotism",
    },
    authorityText: {
      id: "Personality.authorityText",
      defaultMessage:
        "Concerns regarding respect to authority and rejection of insubordination",
    },
    purityText: {
      id: "Personality.purityText",
      defaultMessage: "Concerns regarding maintaining purity and preventing degradation",
    },
    proportionalityText: {
      id: "Personality.proportionalityText",
      defaultMessage:
        "Proportionality is a psychological motive for rewards and punishments to be proportionate to merit and \ndeservingness and benefits to be calibrated to the amount of contribution.",
    },
  });

  const getPercentile = async () => {
    const empty = {
      Authority: 0,
      Care: 0,
      Equality: 0,
      Loyalty: 0,
      Proportionality: 0,
      Purity: 0,
    };
    try {
      const resp = await axiosInterceptor().get("/survey/percentile/mfq");
      if (resp.data.percentile) {
        return resp.data.percentile;
      }
      return empty;
    } catch (err) {
      console.log(err);
      return empty;
    }
  };

  const getDateTimeArray = () => {
    const date = new Date();
    const minutes =
      date.getMinutes().toString().length === 1
        ? "0" + date.getMinutes()
        : date.getMinutes();
    const hours =
      date.getHours().toString().length === 1
        ? `0${date.getHours()}`
        : date.getHours();
    const ampm = date.getHours() >= 12 ? "pm" : "am";
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // eslint-disable-next-line prefer-template
    return [
      "Date: " +
        days[date.getDay()] +
        ", " +
        months[date.getMonth()] +
        " " +
        date.getDate() +
        ", " +
        date.getFullYear(),
      "Time: " + hours + ":" + minutes + ampm,
    ];
  };

  const exportToPDF = async () => {
    setLoading(true);
    const dateTimeArray = getDateTimeArray();
    const percentile = await getPercentile();

    const input = ref.current;
    html2canvas(input, { scrollY: -window.scrollY }).then((canvas) => {
      const imgWidth = 150;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const title = intl.formatMessage(messages.title);
      const headers = [
        [
          intl.formatMessage(messages.personality),
          intl.formatMessage(messages.percentile),
        ],
      ];
      const tableData = [
        {
          personality: intl.formatMessage(messages.care),
          percentile: percentile["Care"],
        },
        {
          personality: intl.formatMessage(messages.equality),
          percentile: percentile["Equality"],
        },
        {
          personality: intl.formatMessage(messages.loyalty),
          percentile: percentile["Loyalty"],
        },
        {
          personality: intl.formatMessage(messages.authority),
          percentile: percentile["Authority"],
        },
        {
          personality: intl.formatMessage(messages.purity),
          percentile: percentile["Purity"],
        },
        {
          personality: intl.formatMessage(messages.proportionality),
          percentile: percentile["Proportionality"],
        },
      ];
      const data = tableData.map((elt) => [elt.personality, elt.percentile]);
      const content = {
        startY: 120,
        pageBreak: "avoid",
        head: headers,
        body: data,
      };
      console.log(data, percentile);

      const care = intl.formatMessage(messages.careText);
      const equality = intl.formatMessage(messages.equalityText);
      const loyalty = intl.formatMessage(messages.loyaltyText);

      const authority = intl.formatMessage(messages.authorityText);
      const purity = intl.formatMessage(messages.purityText);
      const proportionality = intl.formatMessage(messages.proportionalityText);
      const pdf = new jsPDF();
      pdf.setFontSize(10);
      pdf.text("User id: " + email, 200, 10, "right");
      pdf.text(dateTimeArray[0], 200, 15, "right");
      pdf.text(dateTimeArray[1], 200, 20, "right");

      pdf.setFontSize(14);
      pdf.setFont(undefined, "bold");
      pdf.setFont(undefined, "normal");
      pdf.text(intl.formatMessage(messages.defintions), 100, 30, "center");

      // Care
      pdf.setFontSize(12);
      pdf.text(20, 35, intl.formatMessage(messages.care), "left");
      pdf.setFontSize(10);
      pdf.text(20, 40, care, "left");

      // Equality
      pdf.setFontSize(12);
      pdf.text(20, 50, intl.formatMessage(messages.equality), "left");
      pdf.setFontSize(10);
      pdf.text(20, 55, equality, "left");

      // loyalty
      pdf.setFontSize(12);
      pdf.text(20, 65, intl.formatMessage(messages.loyalty), "left");
      pdf.setFontSize(10);
      pdf.text(20, 70, loyalty, "left");

      // authoriy
      pdf.setFontSize(12);
      pdf.text(20, 80, intl.formatMessage(messages.authority), "left");
      pdf.setFontSize(10);
      pdf.text(20, 85, authority, "left");

      // purity
      pdf.setFontSize(12);
      pdf.text(20, 95, intl.formatMessage(messages.purity), "left");
      pdf.setFontSize(10);
      pdf.text(20, 100, purity, "left");

      // prop
      pdf.setFontSize(12);
      pdf.text(20, 110, intl.formatMessage(messages.proportionality), "left");
      pdf.setFontSize(10);
      pdf.text(20, 115, proportionality, "left");

      pdf.setFontSize(14);
      pdf.text(title, 100, 15, "center");
      pdf.setFontSize(10);
      pdf.autoTable(content);
      pdf.addPage();

      pdf.addImage(imgData, "PNG", 30, 10, imgWidth, imgHeight, "center");

      pdf.save("PersonalityResults.pdf");
      setLoading(false);
    });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }} xs={12}>
            {home && (
              <div>
                <h4 className="welcomeHeading">
                  <FormattedMessage
                    id="Personality.welcomeTitle"
                    defaultMessage="Welcome: "
                  />
                  {email}
                </h4>
                <br />
                <Row>
                  <h5 className="welcomeHeading">
                    <FormattedMessage
                      id="Personality.welcomeContent"
                      defaultMessage="Have a look at your personality results compared with the
                    average!"
                    />
                  </h5>
                  <div>*Your score will be unavailable if there are unanswered questions."</div>
                </Row>
                <div ref={ref}>
                  <Row>
                    <IconContext.Provider
                      value={{
                        className: "harm",
                        size: "25px",
                        color: "#00cc00",
                        style: { verticalAlign: "middle" },
                      }}
                    >
                      <MdLocationOn />
                    </IconContext.Provider>
                    <FormattedMessage
                      id="Personality.you"
                      defaultMessage="You"
                    />
                    {/* "#663300", */}
                    <IconContext.Provider
                      value={{
                        className: "harm",
                        size: "25px",
                        color: "black",
                        style: { verticalAlign: "middle" },
                      }}
                    >
                      <MdLocationOn />
                    </IconContext.Provider>
                    <FormattedMessage
                      id="Personality.average"
                      defaultMessage="Average"
                    />
                  </Row>
                  <Row>
                    <Col md={2} />
                    <Col md={9}>
                      <h5 className="moralValueName">
                        {intl.formatMessage(messages.care)}
                      </h5>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "baseline" }}>
                    <Col md={2} xs={1} sm={1} className="moralImage col-xs-1">
                      <img src={careImg} className="mfq-img" alt="care" />
                    </Col>
                    <Col
                      md={10}
                      xs={11}
                      sm={11}
                      className="col-xs-11"
                      style={{ top: "13px" }}
                    >
                      <Slider
                        normal={participantResult.Care}
                        mean={participantMean.Care}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2} />
                    <Col md={9}>
                      <h5 className="moralValueName">
                        {intl.formatMessage(messages.equality)}
                      </h5>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "baseline" }}>
                    <Col md={2} xs={1} sm={1} className="moralImage col-xs-1">
                      <img
                        src={equalityImg}
                        className="mfq-img"
                        alt="equality"
                      />
                    </Col>
                    <Col
                      md={10}
                      xs={11}
                      sm={11}
                      className="col-xs-11"
                      style={{ top: "13px" }}
                    >
                      <Slider
                        normal={participantResult.Equality}
                        mean={participantMean.Equality}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2} />
                    <Col md={9}>
                      <h5 className="moralValueName">
                        {" "}
                        {intl.formatMessage(messages.loyalty)}
                      </h5>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "baseline" }}>
                    <Col md={2} xs={1} sm={1} className="moralImage col-xs-1">
                      <img src={ingroupImg} className="mfq-img" alt="ingroup" />
                    </Col>
                    <Col
                      md={10}
                      xs={11}
                      sm={11}
                      className="col-xs-11"
                      style={{ top: "18px" }}
                    >
                      <Slider
                        normal={participantResult.Loyalty}
                        mean={participantMean.Loyalty}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col md={2} />
                    <Col md={9}>
                      <h5 className="moralValueName">
                        {" "}
                        {intl.formatMessage(messages.authority)}
                      </h5>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "baseline" }}>
                    <Col md={2} xs={1} sm={1} className="moralImage col-xs-1">
                      <img
                        src={newAuthority}
                        className="mfq-img"
                        alt="authority"
                      />
                    </Col>
                    <Col
                      md={10}
                      xs={11}
                      sm={11}
                      className="col-xs-11"
                      style={{ top: "13px" }}
                    >
                      <Slider
                        normal={participantResult.Authority}
                        mean={participantMean.Authority}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2} />
                    <Col md={9}>
                      <h5 className="moralValueName">
                        {" "}
                        {intl.formatMessage(messages.purity)}
                      </h5>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "baseline" }}>
                    <Col md={2} xs={1} sm={1} className="moralImage col-xs-1">
                      <img src={purityImg} className="mfq-img" alt="purity" />
                    </Col>
                    <Col
                      md={10}
                      xs={11}
                      sm={11}
                      className="col-xs-11"
                      style={{ top: "13px" }}
                    >
                      <Slider
                        normal={participantResult.Purity}
                        mean={participantMean.Purity}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={2} />
                    <Col md={9}>
                      <h5 className="moralValueName">
                        {" "}
                        {intl.formatMessage(messages.proportionality)}
                      </h5>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "baseline" }}>
                    <Col md={2} xs={1} sm={1} className="moralImage col-xs-1">
                      <img
                        src={proportionalityImg}
                        className="mfq-img"
                        alt="ingroup"
                      />
                    </Col>
                    <Col
                      md={10}
                      xs={11}
                      sm={11}
                      className="col-xs-11"
                      style={{ top: "18px" }}
                    >
                      <Slider
                        normal={participantResult.Proportionality}
                        mean={participantMean.Proportionality}
                      />
                    </Col>
                  </Row>
                </div>
                <Row className="my-5">
                  <Col>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={exportToPDF}
                      disabled={loading}
                    >
                      {loading && (
                        <Spinner
                          className="mr-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      <FormattedMessage
                        id="Personality.get"
                        defaultMessage="Get a PDF of my results"
                      />
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(PersonalityResults);
