import { useState, useEffect } from "react";
import axiosInstance from "../../axiosInterceptor";
import { useHistory } from 'react-router-dom';

import { Table, Spinner, Container, Col, Row, Form, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { confirmAlert } from "react-confirm-alert";
import {StartSurvey} from "../../pages/DisplaySurvey/StartSurvey"
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const ProfileTable = ({  email }) => {
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  

  useEffect(() => {
    async function getData() {
      try {
        const res = await axiosInstance().get("auth/profile");
        // console.log("profile:"+JSON.stringify(res))
        const { profile } = res.data;
        const profileArr = [];
        profileArr.push(["Email: ", email]);
        for (let key in profile) {
          profileArr.push([key, profile[key]]);
        }
        setProfile(profileArr);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setProfile([]);
      }
      finally{
        setLoading(false);
      }
    }
    getData();
  }, [email]);

  const handleClick = () => {
    confirmAlert({
      title: 'Confirm to reset',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: handleReset
        },
        {
          label: 'No',
          onClick: () => {
            return
          }
        }
      ]
    });
    // setLoading(true);
  }

  const handleReset = () => {
    history.push(`/start/MDQ1/reset`);
  }

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner animation="border" size="lg" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Loading</h5>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      {profile.length > 0 ? (
        <div>
          <h4 className="my-4">
            <FormattedMessage
              id="Profile.title"
              defaultMessage="Your Profile"
            />
          </h4>
          <Table striped bordered hover className="text-left">
            <tbody>
              {profile
                ? profile.map((p, index) => (
                    <tr key={index}>
                      <td>{p[0]}</td>
                      <td>{p[1]}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ display: "flex" }}
              disabled={loading}
              onClick={!loading ? handleClick : null}
            >
              Reset My Profile
            </Button>
          </div>
        </div>
      ) : (
        <div className="my-5">
          <h4>
            <FormattedMessage
              id="Profile.noDemoData"
              defaultMessage="You haven't fill out the demographic survey."
            />
          </h4>
        </div>
      )}
    </>
  );
};

export default ProfileTable;
