import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import SurveyListItem from "../SurveyTable/SurveyListItem";
import "./SuveyTableSection.css";

const SurveyTableSection = ({ title, surveys }) => {
  const completed = title === "Completed Surveys" ? true : false;
  return (
    <Container fluid>
      <Row>
        <Col className="h2">{title}</Col>
      </Row>
      <Row>
        <Col >*Please turn off browser translation before clicking any button</Col>
      </Row>
      <Row className="mt-3">
        <Col xs={1}></Col>
        <Col xs={10} className="background-section px-3 py-2">
          {surveys.map((s) => {
            return (
              <SurveyListItem
                key={s.name}
                name={s.name}
                description={s.description}
                id={s.id}
                completed={completed}
              />
            );
          })}
        </Col>
        <Col xs={1}></Col>
      </Row>
    </Container>
  );
};

export default SurveyTableSection;
